import L from 'leaflet'
import React from 'react'
import { GeoJSON, Tooltip } from 'react-leaflet'
import styled from 'styled-components'

const StyledTooltip = (props) => {
  const { children, current, noFuture, line, data } = props
  const { term, label, "p+r": pr } = data.properties
  const color = (current && !noFuture) || line.type === 3 ? "#888" : (label && !term) || pr ? "white" : "#" + line.color

  const TooltipWrapper = styled(Tooltip)`
    background: ${color};
    border-color: ${color};
    color: ${(term || (!label && !pr)) && "white"};
    padding: ${label && !term ? "0 3px" : "3px"};
    font-size: ${label && !term ? "0.9em" : term && "1.1em"};
    font-weight: ${term && "bold"};
    z-index: ${term && "9999"};

    &.leaflet-tooltip-left {
      &:before {
        border-left-color: ${color};
      }
    }
    &.leaflet-tooltip-right {
      &:before {
        border-right-color: ${color};
      }
    } 
  `

  return <TooltipWrapper {...props} dangerouslySetInnerHTML={{__html: children}} />
}

export const Stop = props => {
  const { data, current, noFuture, line } = props
  const { term, anchor, name, label, "p+r": pr, places } = data.properties

  const pointToLayer = (_feature, latlng) => {
    return pr ? new L.marker(latlng, {
      icon: L.icon({
        iconUrl: "assets/images/p+r.svg",
        iconSize: [30, 30],
        iconAnchor: [15, 15]
      })
    }) : new L.circleMarker(latlng, (current && !noFuture) || line.type === 3
      ? {
        radius: 3,
        fillColor: "white",
        fillOpacity: 1,
        color: "#888",
        weight: 2
      } : {
        radius: 5,
        fillColor: "white",
        fillOpacity: 1,
        color: "#" + line.color,
        weight: 3
      })
  }

  return <GeoJSON {...props} pointToLayer={pointToLayer}>
    <StyledTooltip
      opacity={1}
      offset={[5 * (anchor === "left" || pr ? -1 : 1), 0]}
      permanent={term || label}
      direction={anchor || pr ? 'left' : 'right'}
      {...props}>
        {name + (pr ? ` (${places} places)` : '')}
      </StyledTooltip>
  </GeoJSON>
}

Stop.defaultProps = {
  // default react-leaflet + :
  line: null
}