import L from 'leaflet'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useLeafletMap } from 'use-leaflet'
import styled from 'styled-components'
import { useMedia } from '../../hooks/useMedia'

const LineLegend = styled.div`
  position: ${props => props.isDesktop && "absolute"};
  top: -25px;
  right: 45px;
  background: white;
  font-size: 1.250em;
  padding: 15px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  min-width: max-content;
  border: 2px solid rgba(0,0,0,0.2);
`

const Title = styled.div`
  font-weight: bold;
  margin: -5px 0 5px; 
`

const Line = styled.div`
  width: 65px;
  height: ${props => props.line ? "6px" : "3px"};
  background: ${props => props.line ? `#${props.line.color}` : "#888"};
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Stop = ({ r = 3, c = 6, sWidth = 1, stroke = "#888", fill = "white", size = 12 }) => (
  <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
    <ellipse ry={r} rx={r} cy={c} cx={c} strokeWidth={sWidth} stroke={stroke} fill={fill} />
  </svg>
)

const Current = styled.div`
  display: flex;
  align-items: center;
`

const Future = styled.div`
  display: flex;
  align-items: center;
`

const DivLegend = props => {
  if (props.line) {
    return <LineLegend isDesktop={props.isDesktop}>
      <Title>Légende</Title>
      <Future>
        <Line line={props.line}>
          <Stop r="5" c="7" sWidth="2" stroke={"#" + props.line.color} size="14" />
        </Line>
        tracé actuel
      </Future>
      <Current>
        <Line>
          <Stop />
        </Line>
        ancien tracé
        </Current>
    </LineLegend>
  } else {
    return null
  }
}

export function Legend(props) {
  const map = useLeafletMap()
  const isDesktop = useMedia()

  useEffect(() => {
    const legend = L.control({ position: isDesktop ? "bottomright" : "topleft" })
    const div = L.DomUtil.create('div', '')

    legend.onAdd = function () {
      ReactDOM.render(<DivLegend {...props} isDesktop={isDesktop} />, div)
      return div
    }

    legend.addTo(map)

    return () => {
      map.removeControl(legend)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop])

  return null
}