export const actionInputValueChange = value => ({
  type: 'ON_INPUT_VALUE_CHANGE',
  value
})

export const actionInputStartValueChange = value => ({
  type: 'ON_START_INPUT_VALUE_CHANGE',
  value
})

export const actionInputEndValueChange = value => ({
  type: 'ON_END_INPUT_VALUE_CHANGE',
  value
})

export const actionInputItemsChange = items => ({
  type: 'ON_INPUT_ITEMS_CHANGE',
  items
})

export const actionInputsSwap = (from, to) => ({
  type: 'ON_INPUTS_SWAP',
  from,
  to
})

export const actionInputStartItemsChange = items => ({
  type: 'ON_START_INPUT_ITEMS_CHANGE',
  items
})

export const actionInputEndItemsChange = items => ({
  type: 'ON_END_INPUT_ITEMS_CHANGE',
  items
})

export const actionSetThematicPlaces = thematicPlaces => ({
  type: 'SET_THEMATIC_PLACES',
  thematicPlaces
})

export const actionSetPlaceClicked = place => ({
  type: 'SET_PLACE_CLICKED',
  place
})

export const actionSetOpenedCollapse = collapse => ({
  type: 'SET_OPENED_COLLAPSE',
  collapse
})

export const actionSetFavoriteLine = line => ({
  type: 'SET_FAVORITE_LINE',
  line
})

export const actionSetFavoriteStop = stop => ({
  type: 'SET_FAVORITE_STOP',
  stop
})

export const actionSetFavoritePlace = place => ({
  type: 'SET_FAVORITE_PLACE',
  place
})

// TODO Later, add a way to put addresses in fav
export const actionSetFavoriteAddress = address => ({
  type: 'SET_FAVORITE_ADDRESS',
  address
})

export const actionSetDisruptions = disruptions => ({
  type: 'SET_DISRUPTIONS',
  disruptions
})

export const actionSetDisruptionInLine = disruptionsInLine => ({
  type: 'SET_DISRUPTION_IN_LINE',
  disruptionsInLine
})

export const actionSetGeolocationError = error => ({
  type: 'SET_GEOLOCATION_ERROR',
  error
})

export const actionSetLineInformation = info => ({
  type: 'SET_LINE_INFORMATION',
  info
})
// TODO rename logic ...
export const actionGoToValid = valid => ({
  type: 'SET_GO_TO_ROUTE_CALC',
  valid
})

export const actionResetStationIndex = () => ({ type: 'RESET_STATION_INDEX' })

export const actionIncrementStationIndex = direction => ({
  type: 'INCREMENT_STATION_INDEX',
  direction
})

export const actionDecrementStationIndex = direction => ({
  type: 'DECREMENT_STATION_INDEX',
  direction
})

export const actionSetTooManyFavorites = favorite_type => ({
  type: 'TOO_MANY_FAVORITES',
  favorite_type
})

export const actionSetRouteCalcDatesPanel = datesPanel => ({
  type: 'SET_ROUTE_CALC_DATES_PANEL',
  datesPanel
})

export const actionSetRouteCalcModesPanel = modesPanel => ({
  type: 'SET_ROUTE_CALC_MODES_PANEL',
  modesPanel
})
