import axios from 'axios'
import L from 'leaflet'
import { luminance } from 'luminance-js'
import React, { Component, lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { actionScrollEnd, actionSetComponent } from '../actions/app'
import { actionSetGeolocationError, actionSetLineInformation } from '../actions/board'
import { actionSetReduxMarkers } from '../actions/map'
import { actionBuildHeavyLines, actionBuildMapPlaces, actionBuildMarker, actionBuildschoolsJD, actionOnLineSelected } from '../actions/withRedux'
import history from '../history'
import exportedStyles from '../scss/app.scss'
import { addPolesExchanges, addResizeEvent, envVarToBool, getURLSearchParams, isJDApp, isVariant } from '../services/tools'
import { appStore } from '../store'
import { updateDataLayer } from '../tracking'
import { removeMapEvents, updateMapEvents, zoomOnTerritoryOutline } from '../utils/leaflet/map'
import { substringCoords, updateURLState } from '../utils/leaflet/tools'
import { buildEntranceMap } from '../services/map'

const NetworkMap = lazy(() => import('./Modules/NetworkMap'))
const NetworkTowns = lazy(() => import('./Modules/NetworkTowns'))
const Around = lazy(() => import('./Modules/Around'))
const Lines = lazy(() => import('./Modules/Lines'))
const RouteCalculation = lazy(() => import('./Modules/RouteCalculation'))
const Thematic = lazy(() => import('./Modules/Thematic'))
const TextBoard = lazy(() => import('./Modules/TextBoard'))
const Bike = lazy(() => import('./Modules/Bike'))

const { REACT_APP_TYPE, REACT_APP_GTM, REACT_APP_ENTRANCE_MAP, REACT_APP_NETWORK_RESTRICT, REACT_APP_ZOOM_TERRITORY_OUTLINE, REACT_APP_START_POINT, REACT_APP_ZOOM, REACT_APP_HEAVY_LINES, REACT_APP_AREAS_ZOOM_LEVEL, REACT_APP_POLES } = process.env

class Board extends Component {
  state = {}

  isScrolling = null

  renderModule = () => {
    const { module, map, languageFile, lines, linesModes, placesRef, stops, areas, places, towns, touchscreenSelected, hash, variant, stations, servicesStations } = this.props

    if (!module) {
      console.warn('An error has occured while loading module ...')
      return
    }

    const props = {
      lines,
      linesModes,
      placesRef,
      map,
      stops,
      areas,
      places,
      towns,
      stations,
      servicesStations,
      touchscreenSelected,
      hash,
      variant
    }

    const Fallback = () => <div className="fallback">{languageFile['loading']}</div>

    switch (module.id) {
      case 'network-map':
        return <Suspense fallback={<Fallback />}><NetworkMap {...props} /></Suspense>
      case 'network-towns':
        return <Suspense fallback={<Fallback />}><NetworkTowns {...props} /></Suspense>
      case 'around':
        return <Suspense fallback={<Fallback />}><Around radius={module.radius} {...props} /></Suspense>
      case 'lines':
        return <Suspense fallback={<Fallback />}><Lines {...props} /></Suspense>
      case 'route-calculation':
        return <Suspense fallback={<Fallback />}><RouteCalculation moduleData={module} {...props} /></Suspense>
      case 'thematic':
        return <Suspense fallback={<Fallback />}><Thematic moduleData={module} {...props} /></Suspense>
      case 'text-board':
        return <Suspense fallback={<Fallback />}><TextBoard moduleData={module} {...props} /></Suspense>
      case 'bike':
        return <Suspense fallback={<Fallback />}><Bike moduleData={module} {...props} /></Suspense>
      default:
        break
    }
  }

  resizeBoardContent = () => {
    const { top, isMobile, isMobileBoot } = this.props
    const { defaultBoardTop, boardTranslateY, headerHeightMobile } = exportedStyles
    
    const app = document.querySelector('#app')
    const board = document.querySelector('.board')
  
    if (isMobile || isMobileBoot) {
      app.style.flexDirection = 'column'
      board.style.transform = `translateY(${window.innerHeight - (parseInt(boardTranslateY) + parseInt(headerHeightMobile))}px)`
      
      app.scrollTop = top || parseInt(defaultBoardTop)

      app.addEventListener('scroll', () => {
        // Clear our timeout throughout the scroll
        clearTimeout(this.isScrolling)

        // Set a timeout to run after scrolling ends
        this.isScrolling = setTimeout(() => {
          // Run the callback
          appStore.dispatch(actionScrollEnd(app.scrollTop))
        }, 50)
      })
    }
  }

  // TODO optimize return with various like jd
  back = () => {
    const { variant } = this.props
    const { pathname, search } = history.location
    const params = getURLSearchParams(history.location)

    if (pathname.includes('/lines')) {
      // Remove any current line information message
      appStore.dispatch(actionSetLineInformation(null))

      if (params.current) {
        if (params.date) {
          history.push({ pathname, search: search.split('&date=')[0] })
        } else {
          history.push({
            pathname,
            search: '?' + (params.stop_area ? `stop_area=${params.stop_area}&` : '')
          })
        }
      } else if (params.stop_area) {
        history.push({ pathname })
      } else {
        history.push(variant ? variant + '/' : '/')

        // Dispatch the selected line action
        appStore.dispatch(actionOnLineSelected(null))
      }
    } else if (pathname.includes('/around')) {
      if (params.line) {
        if (params.from) {
          if (params.date) {
            history.push({ pathname, search: search.split('&date=')[0] })
          } else {
            history.push({ pathname, search: '?from=' + params.from })
          }
        } else {
          history.push({ pathname, search: params.date ? search.split('&date=')[0] : '' })
        }
      } else if ((params.from) && !params.line) {
        history.push(pathname)
      } else {
        history.push(variant ? variant + '/' : '/')

        // Dispatch the selected line action
        appStore.dispatch(actionOnLineSelected(null))
      }
    } else if (pathname.includes('/route-calculation')) {
      if (params.to && params.from) {
        if (params.journey) {
          history.push({
            pathname,
            search: '?from=' + params.from + '&to=' + params.to + '&date=' + params.date + '&modes=' + params.modes
          })
        } else if (params.date) {
          history.push({ pathname, search: '?from=' + params.from + '&to=' + params.to })
        } else {
          history.push(variant ? variant + '/' : '/')
        }
      } else {
        history.push(variant ? variant + '/' : '/')
      }
    } else if (pathname.includes('/towns')) {
      if (params.insee && params.line) {
        if (params.date) {
          history.push({ pathname, search: search.split('&date=')[0] })
        } else {
          history.push({ pathname, search: '?insee=' + params.insee })
        }
      } else if (params.insee && !params.line) {
        history.push(pathname)
      } else {
        history.push(variant ? variant + '/' : '/')

        // Dispatch the selected line action
        appStore.dispatch(actionOnLineSelected(null))
      }
    } else if (pathname.includes('/hiking-routes')) {
      let searchArgs = search.split('&')

      if (searchArgs.length > 1) {
        const toRemove = searchArgs[searchArgs.length - 1]
        history.push({ pathname, search: search.replace('&' + toRemove, '') })
      } else {
        if (search) {
          history.push(pathname)
        } else {
          history.push(variant ? variant + '/' : '/')
        }
      }
    } else if (pathname.includes('/mentions')) {
      history.push(variant ? variant + '/' : '/')
      this.setState({ mentions: false })
    } else if (pathname.includes('places-interest')) {
      let searchArgs = search.split('&')

      // TODO FIX FOR PLACES-INTEREST TO THINK BETTER
      if (searchArgs.length > 1) {
        let toRemove = searchArgs[searchArgs.length - 1]
        history.push({ pathname, search: search.replace('&' + toRemove, '') })
      } else {
        if (search) {
          history.push(pathname)
        } else {
          history.push(variant ? variant + '/' : '/')

          // Dispatch the selected line action
          appStore.dispatch(actionOnLineSelected(null))
        }
      }
    } else if (pathname.includes('/network-map')) {
      if (params.line && params.from) {
        history.push({
          pathname,
          search: `?line=${params.from}`
        })
      } else if (params.line) {
        history.push({
          pathname
        })
      } else {
        history.push(variant ? variant + '/' : '/')
      }
    } else if (pathname.includes('/network-towns')) {
      if (params.town && params.line && params.from) {
        history.push({
          pathname,
          search: `?town=${params.town}&line=${params.from}`
        })
      } else if (params.town && params.line) {
        history.push({
          pathname,
          search: `?town=${params.town}`
        })
      } else if (params.town) {
        history.push({
          pathname
        })
      } else {
        history.push(variant ? variant + '/' : '/')
      }
    } else {
      if (params.place && params.line) {
        history.push({
          pathname,
          search: '?place=' + params.place
        })
      } else if (params.place) {
        history.push({
          pathname
        })
      } else {
        history.push(variant ? variant + '/' : '/')
      }

      // Dispatch the selected line action
      appStore.dispatch(actionOnLineSelected(null))
    }
  }

  getPage = page => {
    const { languageFile } = this.props

    return <>
      <div className='board-header'>
        <div className='back' onClick={() => this.back()} title={languageFile['title-back']}>
          <img src='/assets/images/back.svg' alt={languageFile.back} />
        </div>
        <div className='board-title'>
          {page === 'mentions' ? 'mentions légales' : page}
        </div>
      </div>
      <div className='content page' dangerouslySetInnerHTML={{ __html: this.state.content }} />
    </>
  }

  onLineSelected = async (line, marker) => {
    this.props.history.push(`/lines?current=${line.id}_${line.direction_id}&stop=${marker.id}`)
  }

  onClickInfobox(marker, url) {
    const { history, map } = this.props
    const path = `/${url}?from=${substringCoords(marker.coord)}`

    history.push(path)

    map.setState({ infoboxs: [] })
  }

  onClickItemPage = page => {
    this.props.history.push('/' + page)
    this.setState({ [page]: true })
  }

  componentWillMount() {
    appStore.dispatch(actionSetComponent(this))

    // Remove previous geolocation errors
    appStore.dispatch(actionSetGeolocationError(null))
  }

  componentDidUpdate(prevProps) {
    !prevProps.isMobile && this.props.isMobile && this.resizeBoardContent()
  }

  async componentDidMount() {
    const { lines, map, history, places = [], stations: dataStations = [], variant, modules, touchscreenSelected, placesRef, isMobile, hash, isNetwork } = this.props

    this.resizeBoardContent()

    // No lines, no mount
    if (!lines.length) {
      return
    }

    // TODO USE TEXT-BOARD MODULE FOR MENTIONS LEGALES/...
    let content = null
    const pageModule = modules.find(m => !m.hide && m.id !== 'admin' && (touchscreenSelected ? m.touchscreen : m) && (variant ? m.variants.includes(variant) && m.id === 'page' : m && m.id === 'page'))

    pageModule && await axios.get('/api/file?folder=pages&ext=html&name=' + pageModule.file).then(response => {
      content = response.data
    }).catch((e) => {
      const error = e.response && e.response.data ? e.response.data.id : e
      console.warn(error)
    })
    this.setState({ content })

    // Retrieve places to display them in the map
    const data = places.concat(dataStations)
    const placesToDisplay = placesRef ? placesRef.find(p => p.name === 'map-background') : []
    const mapPlaces = data.filter(place => {
      return (placesToDisplay && placesToDisplay.places) ? placesToDisplay.places.includes(place.cat_id) : []
    })

    // Display places on background
    if (isJDApp(REACT_APP_TYPE, variant)) {
      // If is JD display only JD places
      const jdToDisplay = placesRef ? placesRef.find(p => p.name === 'jd-places') : []
      const schoolsJD = data.filter(place => {
        return jdToDisplay.places ? jdToDisplay.places.includes(place.cat_id) : []
      })
      appStore.dispatch(actionBuildschoolsJD(schoolsJD))
    } else {
      // Display all places cat defined in map-background
      appStore.dispatch(actionBuildMapPlaces(mapPlaces))
    }

    // Fix iOS touchmove problem (map and board moving without touching theme
    //document.addEventListener('touchmove', () => { })

    if (history.location.pathname === '/mentions') {
      this.setState({ mentions: true })
    }
    
    const network = REACT_APP_NETWORK_RESTRICT && isVariant() ? isVariant().substr(1) : null

    // Dispatch action to build entrance map
    if (envVarToBool(REACT_APP_ENTRANCE_MAP) && !isJDApp(REACT_APP_TYPE, isVariant())) {
      axios.get(`/assets/${network || 'geojson'}/entrance_map.geojson`).then(response => {
        buildEntranceMap(response.data, map)
      })
    }

    if (map && !isNetwork) {
      // Build, display heavy lines
      !isJDApp(REACT_APP_TYPE, variant) && appStore.dispatch(actionBuildHeavyLines())

      // Reset markers on board load
      appStore.dispatch(actionSetReduxMarkers([]))

      updateMapEvents(map, 'onMoveEnd', async e => {
        // TODO Do not display markers on Route Calculation
        if (
          history.location.pathname.includes("route-calculation") ||
          history.location.pathname.includes("bike") ||
          (history.location.pathname.includes("lines") && history.location.search.includes("current="))
        ) {
          return
        }

        const { reactAreas, reactStops, heavyIds } = this.props
        const url = updateURLState(history.location)
        const lineId = url.line && url.line.split('_')[0]

        const zoom = map.mapReference.current.leafletElement.getZoom()

        const bounds = e.sourceTarget.getBounds()
        const markers = []

        if (reactAreas) {
          for (const area of reactAreas) {
            // Retrieve the marker only if it's inside the map bounds
            if (bounds.contains(area.props.position)) {
              // Below REACT_APP_AREAS_ZOOM_LEVEL, display only heavy lines or cluster if there is no heavy lines
              if (zoom >= 14 && zoom < +REACT_APP_AREAS_ZOOM_LEVEL && REACT_APP_HEAVY_LINES.length) {
                for (const line of area.props.area.lines) {
                  if ((heavyIds.includes(line.id) || (lineId && line.id === lineId)) && markers.indexOf(area) < 0) {
                    markers.push(area)
                  }
                }
              } else if (zoom >= 17) { // 17 or above, display stops
                // Retrieve all physical stops for this stop area
                markers.push(...reactStops.filter(stop => {
                  return stop.props.stop.stop_area === area.props.area.id
                }))
              } else if (zoom >= +REACT_APP_AREAS_ZOOM_LEVEL && zoom < 17) { // Display areas between REACT_APP_AREAS_ZOOM_LEVEL & 17 §17 is stops points, no matter what)
                markers.push(area)
              }
            }
          }

          if (history.location.pathname.includes("around") && REACT_APP_POLES) {
            addPolesExchanges(reactAreas, markers, zoom)
          }
        }

        if (lineId) {
          const line = lines.find(l => l.id === lineId)
          const direction = url.line.split("_")[1] ? url.line.split("_")[1] : "f"

          if (!line.stops) {
            if (this.props.module.id !== "thematic" && this.props.module.type !== "searchOnly") {
              try {
                const response = await axios.get(
                  `/api/file?folder=stops&name=${encodeURIComponent(line.code)}_${line.network}_${direction}~${
                  hash
                  }`
                )
                line.stops = response.data
              } catch (e) {
                const error = e.response && e.response.data ? e.response.data.id : e
                console.warn(error)
              }
            }
          }

          const terminusLine = line.stops.filter(s => s.terminus)
          for (const terminus of terminusLine) {
            // ? Todo check if there is no regression on this
            if (!terminus.lines) {
              continue
            }

            markers.push(
              appStore.dispatch(
                actionBuildMarker(terminus, {
                  key: line.code + "_" + terminus.index,
                  icon: new L.DivIcon({
                    className: "circle-icon-marker",
                    iconSize: [10, 10],
                    tooltipAnchor: new L.Point(5, 0),
                    html: `<span style="border: 3px solid #${line.color}" />`
                  }),
                  stop: terminus,
                  zIndexOffset: 200,
                  terminus: true
                })
              )
            )
          }

          appStore.dispatch(actionSetReduxMarkers(markers))

          setTimeout(() => {
            // TODO find a better way :-)
            document.querySelectorAll(".tooltip-leaflet-terminus").forEach(div => {
              div.style.backgroundColor = "#" + line.color
              div.style.borderColor = "#" + line.color
              div.style.color = luminance(line.color) > 0.5 ? "#333" : "#fff"
            })
          })
        } else {
          // Dispatch the new created markers
          appStore.dispatch(
            actionSetReduxMarkers(
              markers.filter(m => {
                const type = Object.keys(m.props).includes("area") ? "area" : "stop"
                return m.props[type].lines.filter(l =>
                  isJDApp(REACT_APP_TYPE, variant)
                    ? l.mode === "commercial_mode:BusJD"
                    : l.mode !== "commercial_mode:BusJD"
                ).length
              })
            )
          )
        }
      })

      this.removeEventListener = addResizeEvent(isMobile);
    }

    if (envVarToBool(REACT_APP_ZOOM_TERRITORY_OUTLINE)) {
      zoomOnTerritoryOutline(map)
    } else if (isNetwork && !isNetwork) {
      const mapElement = map.mapReference.current.leafletElement
      mapElement && mapElement.setView(
        JSON.parse(REACT_APP_START_POINT)[isMobile ? 'mobile' : 'desktop'],
        JSON.parse(REACT_APP_ZOOM)[isMobile ? 'mobile' : 'desktop']
      )
    }
  }

  componentWillUnmount() {
    const { map, isNetwork } = this.props

    if (map && !isNetwork) {
      map.setState({
        polylines: [],
        markers: [],
        markersPlaces: [],
        clusters: null,
        status: null,
        infoboxs: [],
        selectedInfobox: null,
        pin: null,
        circle: null,
        terminus: false,
        infoboxsTerminus: []
      })
      removeMapEvents(map)
      this.removeEventListener && this.removeEventListener()

      if (map.mapReference.current && this.onStreetViewChanged) {
        this.onStreetViewChanged.remove()
      }
    }
  }

  render() {
    const { languageFile, lock, modules, module, isMobile, size, variant, language, touchscreenSelected, selectedTown } = this.props

    const regex = /(http[s]?:\/\/[^/\s]+)/g
    const matches = document.referrer.match(regex)
    const origin = matches && document.referrer.match(regex).shift()

    const modulesToRender = modules
      .filter(m => !m.hide && m.id !== 'admin' && (touchscreenSelected ? m.touchscreen : m) && (variant ? m.variants.includes(variant) : m))
      .sort((a, b) => +(a.position > b.position) || +(a.position === b.position) - 1)
      .reduce((accumulator, currentModule) => {
        const type = currentModule.id === 'pdf-download' ? 'pdf' : currentModule.id === 'page' ? 'page' : currentModule.submodule ? 'subs' : 'mains'
        if (currentModule.id === 'pdf-download') {
          switch (currentModule.type) {
            case 'local':
              currentModule.url = '/assets' + currentModule.link[language]
              break
            case 'extern':
              currentModule.url = origin + currentModule.link[language]
              break
            case 'local-variant':
              currentModule.url = '/assets' + variant + currentModule.link[language]
              break
            default:
              break
          }
        }
        accumulator[type].push(currentModule)

        return { ...accumulator }
      }, {
        mains: [],
        subs: [],
        page: [],
        pdf: []
      })

    return <section className={'board' + (!module ? ' no-module' : '') + ' ' + size}>
      {isMobile && <div className='evier-metal' />}
      {module && <div className='content no-scroll'>
        <div className='board-header'>
          {!lock && <button className='back' onClick={() => this.back()}>
            <img src='/assets/images/back.svg' alt={languageFile.back} />
          </button>}
          <h1 className={'board-title' + (lock ? ' board-title-lock' : '') + (selectedTown ? ' with-town' : '')}>
            {languageFile[module.title]}
            {selectedTown && <div className="board-title-town">
              {selectedTown.name}
            </div>}
          </h1>
        </div>
        {this.renderModule()}
      </div>}
      {!module && (
        this.state.mentions
          ? this.getPage('mentions')
          : <div className="scrolling">
            {modules.length
              ? <>
                <div className={'content main home'}>
                  {
                    Object.keys(modulesToRender).filter(m => m !== 'pdf').map(groupModule => {
                      if (groupModule === 'page' && modulesToRender[groupModule].length) {
                        return <div key={groupModule} onClick={() => this.onClickItemPage(modulesToRender[groupModule][0].file)} className='menu-item-page'>
                          {modulesToRender[groupModule][0].title}
                        </div>
                      } else {
                        return <div key={groupModule} className={groupModule === 'subs' ? 'sub-module' : ''}>
                          {modulesToRender[groupModule].filter(module => module.onlyOnVariant ? variant && module.variants.includes(variant) : module).map((module, index) => {
                            return <Link key={`${module.id}_${index}`} className='menu-item' to={(variant ? variant + '/' : '/') + (['thematic', 'text-board'].includes(module.id) ? module.data : module.id)}>
                              <img className='images' src={module.image} alt={languageFile[module.title]} />
                              <div className={!module.submodule ? 'menu-item-content' : ''}>
                                <div className='menu-title' dangerouslySetInnerHTML={{ __html: languageFile[!module.submodule ? module.title : module.text] }} />
                                {!this.props.isMobile && !module.submodule && <div className='menu-item-description' dangerouslySetInnerHTML={{ __html: variant ? languageFile[module.variantDescription] : languageFile[module.description] }} />}
                              </div>
                              {!module.submodule && <div className='menu-item-arrow' />}
                            </Link>
                          })}
                        </div>
                      }
                    })
                  }
                </div>
                {modulesToRender.pdf.filter(pdfModule => pdfModule.onlyOnVariant ? variant && pdfModule.variants.includes(variant) : pdfModule)
                  .map((pdfModule, index) => {
                    return <div key={`${pdfModule.id}_${index}`} className='sub-content main'>
                      <a className='menu-item' href={pdfModule.url}
                        target='_blank' rel="noopener noreferrer" onClick={() => REACT_APP_GTM && updateDataLayer({
                          event: 'map-consultationPDFPlans',
                          variant: variant ? variant.substr(1) : null
                        })}>
                        <img className='images' src={'/assets/images/menu/pdf.svg'} alt={languageFile[pdfModule.text]} />
                        <div className='menu-item-content'>
                          <div className='menu-title'>{languageFile[pdfModule.text]}</div>
                        </div>
                        <div className='menu-item-arrow' />
                      </a>
                    </div>
                  })
                }
              </>
              : <div className='loading'>
                <img src='/assets/images/loading.gif' width={30} alt={languageFile.loading} />
              </div>
            }
          </div>
      )}
    </section>
  }
}

const mapStateToProps = state => {
  return {
    top: state.app.top,
    isMobile: state.app.isMobile,
    isNetwork: state.app.isNetwork,
    modules: state.app.modules,
    map: state.app.map,
    lines: state.app.lines,
    linesModes: state.app.linesModes,
    stops: state.app.stops,
    areas: state.app.areas,
    places: state.app.places,
    placesRef: state.app.placesRef,
    towns: state.app.towns,
    stations: state.app.stations,
    servicesStations: state.app.servicesStations,
    heavyIds: state.app.heavyIds,
    language: state.app.language,
    languageFile: state.app.languageFile,
    lock: state.app.lock,
    size: state.app.size,
    variant: state.app.variant,
    touchscreenSelected: state.app.touchscreenSelected,
    hash: state.app.hash,
    reactAreas: state.map.reactAreas,
    reactStops: state.map.reactStops,
    selectedTown: state.network.town
  }
}

export default withRouter(connect(mapStateToProps)(Board))
