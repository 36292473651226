import {
  actionMarkerClick, actionSetJDSchools,
  actionSetLineSelected, actionSetMapPlaces,
  actionSetPublicPlaces,
  actionSetTransportPlaces,
  actionSetLineSelectedPath
} from '../actions/map'
import {
  buildHeavyLines,
  buildMarker,
  buildPlaces,
  onLineSelected,
  onMarkerMouseOut,
  onMarkerMouseOver,
  onOpenMarker,
  buildLinePath,
  buildBikePaths,
  buildMapBikes
} from '../services/map'
import { schedules, fitBounds } from '../utils/leaflet/map'
import history from '../history'
import { isJDApp } from '../services/tools'

const { REACT_APP_TYPE, REACT_APP_NEXT_SCHEDULES_RESULTS } = process.env

export default () => {
  return ({ dispatch, getState }) => next => action => {
    const { component } = getState().app

    const { pathname, search } = history.location

    switch (action.type) {
      case 'BUILD_MARKER':
        return buildMarker(getState(), action.data, action.options)

      case 'OVER_MARKER':
        onMarkerMouseOver(getState(), action.data)
        break

      case 'OUT_MARKER':
        onMarkerMouseOut(getState(), action.data)
        break

      case 'OPEN_MARKER':
        const { isMobile, variant } = getState().app
        const { openedMarker, lineSelected } = getState().map

        const map = getState().app.map.mapReference.current.leafletElement

        // Close current opened marker
        openedMarker && openedMarker.ref && openedMarker.ref.leafletElement.closePopup()
        if (!action.data) {
          dispatch(actionMarkerClick(null))
        } else if (isJDApp(REACT_APP_TYPE, variant) && action.data && action.data.cat_id) {
          const pathnameLast = pathname.charAt(pathname.length - 1)

          if (pathname.includes('/lines')) {
            history.push({
              pathname: 'around',
              search: '?from=' + action.data.id
            })
          } else {
            const isJDPlaces = component.props.placesRef.find(p => p.name === 'jd-places')
            if (isJDPlaces.places && isJDPlaces.places.includes(action.data.cat_id)) {
              history.push({
                pathname: pathname.includes('/around') ? pathname : pathname + (pathnameLast !== '/' ? '/' : '') + 'around',
                search: '?from=' + action.data.id
              })
            }
          }
        } else {
          // If we have a line selected in the state, open the marker's schedules
          if (REACT_APP_NEXT_SCHEDULES_RESULTS > 0 && lineSelected && action.data.lines && !isJDApp(REACT_APP_TYPE, variant) && !action.data.severity) {
            schedules(component, action.data, lineSelected)
          } else {
            const { stopsList } = component.state
            
            if (stopsList) {
              for (const s of stopsList) {
                s.opened = false
                if (action.data.id.includes('stop_area')) {
                  s.opened = s.stop_area === action.data.id
                } else {
                  s.opened = s.id === action.data.id
                }
              }
              component.setState({ stopsList })
            }
          }

          // TODO Mobile, close popup if drag pin ?
          const popup = document.querySelector('.leaflet-popup')
          const offset = isMobile ? [
            popup ? -popup.offsetWidth / 2 : 0,
            -(document.querySelector('#app').scrollTop + 200) / 2] : [440 / 2, 0]
          
          if (!pathname.includes("around") || !search.includes("stop")) {
            const targetPoint = map.project([action.data.coord.lat, action.data.coord.lon], map.getZoom())
            .subtract(offset)
            const targetLatLng = map.unproject(targetPoint, map.getZoom())
            map.setView(targetLatLng, map.getZoom())
          }

          dispatch(actionMarkerClick(action.data))
          onOpenMarker(getState(), action.data)
        }

        if (action.data && action.data.id && action.data.stop_area && !search.includes('&stop=' + action.data.id) && !search.includes('&stop=' + action.data.stop_area)) {
          history.push({ pathname, search: search.split('&stop=')[0] + '&stop=' + action.data.id })
        }

        break

      // TODO ! recode utils/map.js functions...
      case 'ON_LINE_SELECTED':
        // TODO mutual "onLineSelected" func
        // component.onLineSelected(action.line, action.data, action.onLoad)
        dispatch(actionSetLineSelected(action.line))
        // Display line path
        action.line ? buildLinePath(action.line, component.props.hash).then(polyline => {
          if (polyline && polyline.props.data.features && action.line.tad && action.line.tad.zone) {
            dispatch(actionSetLineSelectedPath(polyline))
            
            const stopsToZoom = []
            if (action.line.stops) {
              for (const lineStop of action.line.stops) {
                stopsToZoom.push([lineStop.coord.lat, lineStop.coord.lon])
              }
              fitBounds(component.props.map, stopsToZoom)
            }
          } else if (polyline && polyline.props.data.features) {
            dispatch(actionSetLineSelectedPath(polyline))
            if (!action.data || !action.data.name) {
              fitBounds(component.props.map, [polyline])
            }
          } else {
            const stopsToZoom = []
            if (action.line.stops) {
              for (const lineStop of action.line.stops) {
                stopsToZoom.push([lineStop.coord.lat, lineStop.coord.lon])
              }
              fitBounds(component.props.map, stopsToZoom)
            }
          }
        }) : dispatch(actionSetLineSelectedPath(null))

        // Dispatch service's onLineSelected only if we have some data
        action.line && onLineSelected(getState(), action.line, action.data, action.openInfobox)
        break

      case 'BUILD_HEAVY_LINES':
        buildHeavyLines(getState())
        break

      case 'BUILD_TRANSPORT_PLACES':
        dispatch(actionSetTransportPlaces(buildPlaces(getState(), action.places)))
        break

      case 'BUILD_MAP_PLACES':
        dispatch(actionSetMapPlaces(buildPlaces(getState(), action.places)))
        break

      case 'BUILD_PUBLIC_PLACES':
        dispatch(actionSetPublicPlaces(buildPlaces(getState(), action.places)))
        break

      case 'BUILD_JD_SCHOOLS':
        dispatch(actionSetJDSchools(buildPlaces(getState(), action.schoolsJD)))
        break

      case 'BUILD_BIKE_PATHS':
        buildBikePaths(action.files)
        break

      case 'BUILD_MAP_BIKES':
        buildMapBikes(getState(), action.bikes)
        break

      default:
        break
    }

    return next(action)
  }
}
