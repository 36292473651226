import L from 'leaflet'
import 'leaflet-polylineoffset-lc'
import { useEffect } from 'react'
import { useLeafletMap } from 'use-leaflet'

export function PolylineOffset({ data }) {
  const map = useLeafletMap()

  useEffect(() => {
    const polylines = []

    for (const coordinates of data.geometry.coordinates) {
      const polyline = L.polyline(coordinates.map(c => c.reverse()), {
        offset: 6,
        smoothFactor: 1,
        color: '#888',
        interactive: false
      })

      polyline.addTo(map).bringToBack()
      polylines.push(polyline)
    }

    return () => {
      for (const polyline of polylines) {
        map.removeLayer(polyline)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}