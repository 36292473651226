import axios from 'axios'
import { detect } from 'detect-browser'
import L from 'leaflet'
import React, { useEffect, useRef, useState } from 'react'
import { GeoJSON, Map, ScaleControl, TileLayer, ZoomControl } from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux'
import { actionSetNetworkMapHeavyLines, actionSetNetworkMapRef, actionSetNetwotkSelectedLine } from '../actions/network'
import { useMedia } from '../hooks/useMedia'
import { useNetworkLineSelected } from '../hooks/useNetworkLineSelected'
import { getBoardBoundingClientRect } from '../utils/tools'
import { Legend } from './map/Legend'
import { Line } from './map/Line'
import { useWindowSize } from '../hooks/useWindowSize'
import { envVarToBool } from '../services/tools'

const { REACT_APP_BASE_TILES_URL, REACT_APP_HEADER, REACT_APP_START_POINT, REACT_APP_HEAVY_LINES, REACT_APP_ZOOM, REACT_APP_ZOOM_MIN, REACT_APP_ZOOM_MAX } = process.env
const browser = detect()

const LeafletNetwork = props => {
  const mapRef = useRef(null)
  const dispatch = useDispatch()
  const isDesktop = useMedia()
  const { lines, hash } = useSelector(state => state.app)
  const { entranceMapMarkers } = useSelector(state => state.map)
  const { line, town, heavyLines } = useSelector(state => state.network)
  const defaultCenter = JSON.parse(REACT_APP_START_POINT)[isDesktop ? 'desktop' : 'mobile']
  const zoom = JSON.parse(REACT_APP_ZOOM)[isDesktop ? 'desktop' : 'mobile']
  const selectedLine = useNetworkLineSelected()
  const [center, setCenter] = useState(defaultCenter)
  const size = useWindowSize()

  // Detect Safari or Edge 17 browsers to fallback tiles on PNG
  const ext = (browser.name === 'safari' || browser.os === 'iOS' || (browser.name === 'edge' && browser.version.startsWith('17.'))) ? 'png' : 'webp'

  /**
   * Boot effect : 
   *  - Setup the map ref 
   *  - Attribution
   *  - Heavy lines
   * Cleanup effect : Destroy all network map reference on the store
   */
  useEffect(() => {
    const drawHeavyLines = async () => {
      const polylines = []

      for (const heavy of JSON.parse(REACT_APP_HEAVY_LINES).reverse()) {
        const line = lines.find(l => l.id === heavy)
        const folder = +line.type !== 4 ? 'routes/future/lines' : 'routes/current/lines'
        const response = await axios.get(`/api/file?folder=${folder}&ext=geojson&name=${line.code}~${hash}`)

        for (const [key, feature] of Object.entries(response.data.features)) {
          polylines.push(<GeoJSON key={line.code + key} data={feature} style={{
            color: "#" + line.color,
            weight: 6,
            interactive: false,
            //smoothFactor: 0.85,
            // No more dashed for now
            // weight: feature.properties.dashed ? 4 : 6,
            // dashArray: feature.properties.dashed && '8, 5',
            // dashOffset: feature.properties.dashed && '0',
            // lineCap: feature.properties.dashed ? 'miter-clip' : 'round',
            // lineJoin: feature.properties.dashed ? 'miter-clip' : 'round'
          }} onEachFeature={(_, layer) => setTimeout(() => layer.bringToBack())} />)
        }
      }

      dispatch(actionSetNetworkMapHeavyLines(polylines))
    }

    // Push the current network map into redux store
    dispatch(actionSetNetworkMapRef(mapRef))

    // Custom the default attribution to add target blank
    mapRef.current.leafletElement.attributionControl.setPrefix('<a href="https://leafletjs.com" target="_blank" rel="noopener">Leaflet</a>')

    drawHeavyLines()

    return () => {
      // Clear the redux store
      dispatch(actionSetNetworkMapRef(null))
      dispatch(actionSetNetworkMapHeavyLines(null))
      dispatch(actionSetNetwotkSelectedLine(null))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, lines])

  /**
   * Effect used to update center and recalculate it's position on mobile or desktop
   */
  useEffect(() => {
    const board = getBoardBoundingClientRect()
    const latlng = L.latLng(defaultCenter)
    const point = mapRef.current.leafletElement.latLngToContainerPoint(latlng)
    const offsetPoint = isDesktop ? L.point([point.x - (board.width / 2), point.y]) : L.point([point.x, point.y - (board.y - size.height) / 2])

    setCenter(mapRef.current.leafletElement.containerPointToLatLng(offsetPoint))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop])

  return (
    <Map ref={mapRef} center={center} className={'mapContainer' + (envVarToBool(REACT_APP_HEADER) ? ' with-header' : '')} zoom={zoom} minZoom={+REACT_APP_ZOOM_MIN} maxZoom={+REACT_APP_ZOOM_MAX} zoomControl={false}>
      <TileLayer
        url={REACT_APP_BASE_TILES_URL + '/' + ext + '/{z}/{x}/{y}.' + ext}
        attribution='&amp;copy <a href="https://latitude-cartagene.com" target="_blank" rel="noopener">Latitude-Cartagène</a> | &amp;copy les contributeurs d’<a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener">OpenStreetMap</a>'
      />
      {isDesktop && <>
        <ScaleControl position={'bottomright'} imperial={false} />
        <ZoomControl position={'bottomright'} />
      </>}
      {selectedLine && selectedLine.type === 2 && <Legend line={selectedLine} />}
      {town && <Line centerOnDraw returnToOriginalBoundsOnDestroy data={town.geometry} style={{
        color: "#999",
        stroke: false,
        opacity: 0.3,
        interactive: false
      }} />}

      {heavyLines} {entranceMapMarkers}
      {line}
    </Map>
  )
}

export default LeafletNetwork
