import L from 'leaflet'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { appStore } from '../store'
import {
  actionBuildMarker,
  actionSetAreas,
  actionSetStops
} from '../actions/withRedux'
import { actionHandleLegendState } from '../actions/map'
import { getLine, isJDApp } from '../services/tools'

import Leaflet from './Leaflet'
import LeafletNetwork from './LeafletNetwork'

const { REACT_APP_TYPE, REACT_APP_NETWORK_RESTRICT } = process.env

class Map extends Component {
  componentDidUpdate() {
    const { isNetwork } = this.props

    // Do not run this lifecycle on network map
    if (isNetwork) {
      return
    }

    let { areas, stops } = this.props
    const { lines, reactAreas, variant } = this.props

    areas = areas.filter(m => {
      return m.lines.filter(l =>
        isJDApp(REACT_APP_TYPE, variant)
          ? l.mode === "commercial_mode:BusJD"
          : l.mode !== "commercial_mode:BusJD"
      ).length
    })

    stops = stops.filter(m => {
      return m.lines.filter(l =>
        isJDApp(REACT_APP_TYPE, variant)
          ? l.mode === "commercial_mode:BusJD"
          : l.mode !== "commercial_mode:BusJD"
      ).length
    })

    if (!reactAreas && areas.length > 0) {
      const reactAreas = []
      const reactStops = []

      for (const area of areas) {
        const mode = retrieveMostImportantMode(lines, area, variant)

        reactAreas.push(appStore.dispatch(actionBuildMarker(area, {
          icon: L.icon({
            iconUrl: '/assets/images/stops/' + mode + '.svg',
            iconSize: [26, 26],
            iconAnchor: [13, 26]
          }),
          area,
          zIndexOffset: 50
        })))
      }

      for (const stop of stops) {
        const mode = retrieveMostImportantMode(lines, stop, variant)

        reactStops.push(appStore.dispatch(actionBuildMarker(stop, {
          icon: L.icon({
            iconUrl: '/assets/images/stops/' + mode + '.svg',
            iconSize: [26, 26],
            iconAnchor: [13, 26]
          }),
          stop,
          zIndexOffset: 50
        })))
      }

      // Dispatch markers in the store
      appStore.dispatch(actionSetAreas(reactAreas))
      appStore.dispatch(actionSetStops(reactStops))
    }
  }

  render() {
    const { isNetwork } = this.props

    // Run a separate map for network map
    if (isNetwork) {
      return <LeafletNetwork />
    } else {
      const { isLegendOpen, variant } = this.props
      let loaded = true
      let legendImg = '/assets/images/legend-img.svg'

      if (REACT_APP_NETWORK_RESTRICT && variant) {
        legendImg = `/assets${variant}/legend-img.svg`
        loaded = true
      } else if (REACT_APP_NETWORK_RESTRICT) {
        loaded = false
      }

      return <>
        {isLegendOpen && <div className={'legend-panel'} onClick={() => {
          appStore.dispatch(actionHandleLegendState(isLegendOpen))
        }}>
          <span className={'legend-close'}>╳</span>
          {!loaded && <div className='loading'>
            <img src="/assets/images/loading.gif" alt="loading" />
          </div>}
          {loaded && <div className='legend-img' style={{
            backgroundImage: `url(${legendImg})`
          }} alt='legend'></div>}
        </div>
        }
        <Leaflet {...this.props} />
      </>
    }
  }
}

const retrieveMostImportantMode = (lines, stop, variant) => {
  const info = getLine(lines, stop.lines[0])

  let mode = 'bus'
  if (info.mode === 'commercial_mode:Métro') {
    mode = 'subway'
  } else if (info.mode === 'commercial_mode:Funiculaire') {
    mode = 'funicular'
  } else if (info.mode === 'commercial_mode:Tramway') {
    mode = 'tramway'
  } else if (info.mode === 'commercial_mode:BusC') {
    mode = 'c-lines'
  }

  if (isJDApp(REACT_APP_TYPE, variant)) {
    mode = 'bus'
  }

  return mode
}

const mapStateToProps = state => {
  return {
    isMobile: state.app.isMobile,
    isNetwork: state.app.isNetwork,
    areas: state.app.areas,
    stops: state.app.stops,
    lines: state.app.lines,
    variant: state.app.variant,
    isLegendOpen: state.map.isLegendOpen,
    reactAreas: state.map.reactAreas,
    reactStops: state.map.reactStops,
    reduxMarkers: state.map.reduxMarkers,
    selectedLine: state.map.selectedLine,
    heavyLines: state.map.heavyLines,
    schoolsJD: state.map.schoolsJD,
    transportPlaces: state.map.transportPlaces,
    mapPlaces: state.map.mapPlaces,
    places: state.map.places,
    territoryOutline: state.map.territoryOutline,
    entranceMapMarkers: state.map.entranceMapMarkers,
    entrancePopup: state.map.entrancePopup,
    bikePaths: state.map.bikePaths,
    bikes: state.map.bikes
  }
}

export default connect(mapStateToProps)(Map)
