import Tippy from '@tippy.js/react'
import axios from 'axios'
import L from 'leaflet'
import { luminance } from 'luminance-js'
import React from 'react'
import { GeoJSON, Marker, Popup, Tooltip } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import { actionSetFavoritePlace, actionSetOpenedCollapse } from '../actions/board'
import { actionMarkerClick, actionSetBikePaths, actionSetCluster, actionSetEntranceMapMarkers, actionSetEntrancePopup, actionSetHeavyLines, actionSetMapBikes } from '../actions/map'
import { actionBuildMapPlaces, actionBuildTransportPlaces, actionOnLineSelected, actionOpenMarker, actionOutMarker, actionOverMarker } from '../actions/withRedux'
import history from '../history'
import BikeInterface from '../interfaces/BikeInterface'
import { appStore } from '../store'
import { groupLinesByMode } from '../utils/leaflet/tools'
import { buildPlaceIconSize, clickOnPlaceInList, envVarToBool, flattenObject, getLine, getRef, getURLSearchParams, goToRouteCalculation, isJDApp, isNotToClusterised, isThematics, mostImportantGroup, sortAlphabetic, unique, updatePopupPosition } from './tools'

const { REACT_APP_TYPE, REACT_APP_LINES_MAIN_TYPE, REACT_APP_LINES_TYPE_EXCEPTIONS, REACT_APP_SHOW_PMR, REACT_APP_SHOW_ADDITIONAL_STOP_TOOL, REACT_APP_DONT_DISPLAY_STOP_AT_LINE_SELECTION, REACT_APP_CONNECTIONS_TEXT } = process.env

export const buildBikePaths = async files => {
  const requests = []

  for (const file of files) {
    const options = {
      color: file.style.color,
      opacity: 1,
      weight: file.style.size,
      dashArray: file.style.dashArray,
      lineJoin: 'round'
    }

    requests.push(axios.get(`/assets/geojson/bike/${file.id}.geojson`).then(response => {
      return <GeoJSON interactive={false} key={file.id} data={response.data} style={options} />
    }))
  }

  Promise.all(requests).then(paths => {
    appStore.dispatch(actionSetBikePaths(paths))
  })
}

export const buildEntranceMap = (geojson, map) => {
  if (!geojson) {
    return
  }
  
  const markers = []

  if (map.mapReference.current) {
    map = map.mapReference.current.leafletElement
  }

  for (const feature of geojson.features) {
    const isTerminus = feature.properties.type === "terminus"

    // TEMPORARY UNTIL TERMINUS ARE FINISHED !
    if (isTerminus) {
       continue
    }
    // END TEMPORARY

    if (feature.geometry.type.includes('String')) {
      markers.push(
        <GeoJSON
          key={Math.random()}
          data={feature}
          style={{
            opacity: 0,
            weight: 18
          }}
          onMouseMove={e => {
            const storedPopup = appStore.getState().map.entrancePopup
            const popup = e.target.getPopup()
            if (!storedPopup || storedPopup._leaflet_id !== popup._leaflet_id) {
              popup.setLatLng(e.latlng).openOn(map)
            }
          }}
          onMouseOut={e => {
            const storedPopup = appStore.getState().map.entrancePopup
            const popup = e.target.getPopup()

            if (!storedPopup || storedPopup._leaflet_id !== popup._leaflet_id) {
              e.target.closePopup()
            }
          }}
          onClick={e => {
            map.eachLayer(layer => layer.closePopup())
            
            const popup = e.target.getPopup()
            appStore.dispatch(actionSetEntrancePopup(popup))
            popup.setLatLng(e.latlng).openOn(map)
          }}
        >
          <Popup className={"popup-leaflet"} closeButton={false} autoClose={false} autoPan={false} onClose={() => appStore.dispatch(actionSetEntrancePopup(null))}>
            {buildPopup(
              appStore.getState(),
              {
                lines: feature.properties.desserte
                  .split(";")
                  .map(line => ({ code: line.split("_")[0], network: line.split("_")[1] }))
              },
              true
            )}
          </Popup>
        </GeoJSON>
      )
      markers.push(<GeoJSON key={Math.random()} style={{
        color: '#888'
      }} data={feature} interactive={false} />)
    } else {
      markers.push(<Marker
        key={(isTerminus ? 'terminus-' : 'jalon-') + Math.random()}
        interactive={false}
        position={[feature.geometry.coordinates[1], feature.geometry.coordinates[0]]}
        icon={L.icon({
          iconUrl: '/assets/images/lines/entrance/' + feature.properties.image + '.svg',
          iconSize: feature.properties.size,
          iconAnchor: feature.properties.anchor
        })}
        zIndexOffset={isTerminus ? 100 : 50}
        zoom={feature.properties.zoom}
      />)
    }
  }

  setTimeout(() => appStore.dispatch(actionSetEntranceMapMarkers(markers)))
}

/**
 * Build & render heavy lines
 * @param state
 */
export const buildHeavyLines = state => {
  if (state.map.heavyLines) {
    return
  }

  const { heavyIds, lines, hash } = state.app
  const { pathname } = history.location

  if (!pathname.includes('route-calculation')) {
    const requests = []

    for (const id of heavyIds) {
      const data = getLine(lines, {
        id,
        direction_id: 'f'
      })

      if (data.code) {
        // TODO recode displayLinePath
        requests.push(buildLinePath(data, hash))
      }
    }

    Promise.all(requests).then(polylines => {
      appStore.dispatch(actionSetHeavyLines(polylines))
    }) 
  }
}

export const buildLinePath = (data, hash) => {
  // Force direction_id to 'f' by default if not exists
  if (!data.direction_id) {
    data.direction_id = 'f'
  }

  const folder = data.type ? +data.type !== 4 ? 'routes/future/lines' : 'routes/current/lines' : 'routes'
  const name = data.type ? `${data.code}~${hash}` : `${encodeURIComponent(data.code)}_${data.network}_${data.direction_id}~${hash}`

  return axios.get(`/api/file?folder=${folder}&ext=geojson&name=${name}`).then(response => {
    const geojson = response.data
    
    const options = {
      color: '#' + data.color,
      opacity: 1,
      weight: 6,
      zIndex: 7
    }
    
    if (data.tad && data.tad.zone) {
      // Navitia can't integrate Polygon type... so MultiLineString became a Polygon :D
      geojson.features[0].geometry.type = 'Polygon'
      options.weight = 2
      options.fillColor = '#' + data.color
      options.fillOpacity = 0.2
    }

    if (geojson) {
      return <GeoJSON interactive={false} key={`${data.code}_${data.network}_${data.direction_id}`} data={geojson} style={options} />
    }
  }).catch(e => {
    const error = e.response && e.response.data ? e.response.data.id : e
    console.warn(error)
  })
}

export const buildMapBikes = (state, bikes) => {
  const markers = []
  
  for (const bike of bikes) {
    const size = buildPlaceIconSize(bike)

    markers.push(buildMarker(state, bike, {
      icon: L.icon({
        iconUrl: '/assets/images/menu/velo.svg',
        ...size
      }),
      bike,
      zIndexOffset: 200
    }))
  }

  appStore.dispatch(actionSetMapBikes(markers))
}

/**
 * Build a marker component from the given data
 * @param state
 * @param data
 * @param options
 * @returns Marker
 */
export const buildMarker = (state, data, options) => {
  return <Marker
    key={data.id}
    ref={ref => {
      data.ref = ref
      // appStore.dispatch(actionAddReduxRef(ref))
    }}
    name={data.name}
    onMouseOver={() => appStore.dispatch(actionOverMarker(data))}
    onMouseOut={(e) => {
      const target = e.originalEvent.target

      if (!target.classList.contains('leaflet-tooltip')) {
        setTimeout(() => appStore.dispatch(actionOutMarker(data)))
      }
    }}
    onClick={() => appStore.dispatch(actionOpenMarker(data))}
    position={[
      +data.coord.lat,
      +data.coord.lon
    ]}
    {...options}
  >
    {
      options.terminus && data.terminus &&
      <Tooltip key={'terminus_' + data.id} direction={'right'} onClick={() => appStore.dispatch(actionOpenMarker(data, true))}
        className={'tooltip-leaflet-terminus'} opacity={1} interactive
        permanent>{data.name}</Tooltip>
    }
    <Popup className={'popup-leaflet'} closeButton={false} autoClose={false} autoPan={false} onClose={() => {
      if (data.stand && !isThematics() && data.cat_id !== "poi_type:amenity:park_ride") { // ! Avoid loop on "covoiturage" for Artis. data.stand must be dynamic
        appStore.dispatch(actionBuildTransportPlaces(state.app.component.state.pois))
      }
    }}>
      {buildPopup(state, data)}
    </Popup>
  </Marker>
}

/**
 * Build all places markers
 * @param state
 * @param places
 * @returns {Array}
 */
export const buildPlaces = (state, places) => {
  if (!places) {
    return
  }

  const flattenPlaces = Array.isArray(places) ? places : flattenObject(places)

  const markers = []
  for (const place of flattenPlaces) {
    const size = buildPlaceIconSize(place.cat_id)

    if (place.coord) {
      markers.push(buildMarker(state, place, {
        icon: L.icon({
          iconUrl: place.code ? '/assets/images/places/' + place.code + '.svg' : '/assets/images/stop_point.svg',
          ...size
        }),
        place,
        zIndexOffset: isThematics() ? 200 : 40
      }))
    }
  }

  return markers
}

/**
 * Triggered when a line is selected.
 * The behavior can be different on each modules
 * @param state
 * @param line
 * @param data
 */
export const onLineSelected = (state, line, data) => {
  const { pathname } = history.location
  const { variant } = state.app
  const params = getURLSearchParams(history.location)


  if (pathname.includes('around')) {
    const part = `line=${line.id}_${line.direction_id || 'f'}` + (data ? `&stop=${data.id}` : '') + (params.date ? `&date=${params.date}` : '')

    // TODO ! Remove insee from URL and use from
    if (params && (params.from || params.insee || params.place)) {
      history.push({
        pathname,
        search: (params.from ? '?from=' + params.from : params.place ? '?place=' + params.place : '?insee=' + params.insee) + '&' + part
      })
    } else {
      history.push({
        pathname,
        search: '?' + part
      })
    }
  } else {
    if ( !data || !data.id) {
      // ! Do not comment this : needed to switch direction on Lines component
      history.push({
        pathname: variant ? variant + '/lines' : '/lines',
        search: `?current=${line.id}_${line.direction_id || 'f'}`
      })
    } else {
      if (params.date) {
        history.push({
          pathname: variant ? variant + '/lines' : '/lines',
          search: `?current=${line.id}_${line.direction_id || 'f'}&stop=${data.id}&date=${params.date}`
        })
      } else {
        history.push({
          pathname: variant ? variant + '/lines' : '/lines',
          search: `?current=${line.id}_${line.direction_id || 'f'}&stop=${data.id}`
        })
      }
    }
  }
}

/**
 * Triggered when user mouse leaves a marker
 * Retrieve the current openedMarker & test if its not equal to the given one.
 * If false, close the popup of the given marker
 * @param state
 * @param data
 */
export const onMarkerMouseOut = (state, data) => {
  const { openedMarker, reduxMarkers } = state.map

  if (!openedMarker || (openedMarker && openedMarker.id !== data.id)) {
    const ref = getRef(data, reduxMarkers)

    if (ref) {
      // Force close popup
      ref.leafletElement.closePopup()
    }
  }
}

/**
 * Triggered when user mouse enter on a marker
 * Retrieve the current openedMarker & test if its not equal to the given one or, if openedMarker is defined, if its popup is closed.
 * If one is true, open the popup of the given marker
 * @param state
 * @param data
 */
export const onMarkerMouseOver = (state, data) => {
  const { openedMarker, reduxMarkers } = state.map

  if (openedMarker !== data ||
    (openedMarker && openedMarker.ref && !openedMarker.ref.leafletElement.isPopupOpen())) {
    const ref = getRef(data, reduxMarkers)

    if (ref) {
      // Open popup (delays it a bit to avoid position problem)
      const element = ref.leafletElement

      setTimeout(() => {
        element.openPopup()
        updatePopupPosition(element)
      })
    }
  }
}

/**
 * Open the popup of the given marker
 * @param state
 * @param data
 */
export const onOpenMarker = (state, data) => {
  const { pathname, search } = history.location
  const { token, component, linesModes } = state.app
  const { openedCollapse, favoritePlace, thematicPlaces } = state.board
  const { cluster, mapPlaces } = state.map
  const { pois, groups, tab } = component.state
  const params = getURLSearchParams(history.location)

  !pathname.includes('/lines') && data.cat_id && openedCollapse !== data.cat_id && appStore.dispatch(actionSetOpenedCollapse(data.cat_id))

  if (data.cat_id) {
    if (favoritePlace && favoritePlace.id !== data.id) {
      appStore.dispatch(actionSetFavoritePlace(null))
    }

    if (state.board.thematicPlaces) {
      history.push({
        pathname,
        search: '?place=' + data.id
      })

      appStore.dispatch(actionMarkerClick(data))
    } else if (!pois || pois.length === 0) {
      if (data instanceof BikeInterface && pathname.includes('/bike')) {
        if (search !== "?id=" + data.id) {
          history.push({
            ...history.location,
            search: "?id=" + data.id
          })
        }
      } else {
        const needRequest = [
          'poi_type:amenity:bicycle_rental',
          'poi_type:amenity:bicycle_parking',
          'poi_type:amenity:parking'
        ]
  
        const places = mapPlaces.map(place => place.props.place)
        const place = places.find(place => place.id === data.id)
  
        if (place && needRequest.includes(place.cat_id)) {
          const type = place.cat_id.includes('bicycle_rental') ? 'bss' : place.cat_id.includes('bicycle_parking') ? 'bike_parking' : 'parking'
          axios.get(
            `/api/availability?type=${type}&id=${place.id}`)
            .then(result => {
              place.stand = result.data
              appStore.dispatch(actionBuildMapPlaces(places))
            })
            .catch(e => {
              const error = e.response && e.response.data ? e.response.data.id : e
              console.warn(error)
            })
        } 
      }
    } else {
      clickOnPlaceInList(data, token, pois, thematicPlaces)
    }
  } else if (state.board.thematicPlaces) {
    history.push({
      pathname,
      search: '?place=' + data.id + (params.line ? '&line=' + params.line + (params.stop ? '&stop=' + params.stop : '') : '')
    })
    appStore.dispatch(actionMarkerClick(data))
  } 

  if (!data.ref) {
    data.ref = getRef(data, state.map.reduxMarkers)
  }

  // Open popup, even if it's in a cluster
  if (cluster && data.ref && cluster.hasLayer(data.ref.leafletElement)) {
    const element = data.ref.leafletElement
    cluster.zoomToShowLayer(element, () => {
      element.openPopup()
      updatePopupPosition(element)
    })
  } else if (data.ref) {
    const element = data.ref.leafletElement
    setTimeout(() => {
      element.openPopup()
      updatePopupPosition(element)
    })
  }

  if (data.lines && groups) {
    const mainGroup = mostImportantGroup(groups, linesModes)

    if (mainGroup !== openedCollapse && tab === 0) {
      appStore.dispatch(actionSetOpenedCollapse(mainGroup))
    }
  }
}

/**
 * Render all map places at a minimum zoom level of 16, such as TCL places, Vélo'v & SNCF stations
 * @param mapReference
 * @param places
 * @returns {*[]}
 */
export const renderMapPlaces = (mapReference, places) => {
  const map = mapReference && mapReference.current && mapReference.current.leafletElement

  if (!history.location.pathname.includes('route-calculation') && map && map.getZoom() > 15 && places) {
    return [<MarkerClusterGroup key='map-places' ref={ref => ref && appStore.dispatch(actionSetCluster(ref.leafletElement))} removeOutsideVisibleBounds showCoverageOnHover={false}
      iconCreateFunction={(cluster) => {
        return L.divIcon({ html: cluster.getChildCount(), className: 'cluster' })
      }}
    >
      {places.filter(place => !isNotToClusterised(place.props.place))}
    </MarkerClusterGroup>,
    places.filter(place => isNotToClusterised(place.props.place))
    ]
  }
}

/**
 * Build the popup content of a Marker
 * @param state
 * @param data
 * @returns HTMLElement
 */
const buildPopup = (state, data) => {
  const { lock, modules, variant, languageFile, servicesStations, lines } = state.app
  const params = getURLSearchParams(history.location)
  const servicesAtStation = []

  if (servicesStations) {
    const servicesList = servicesStations.find(s => s.id === data.id)
    if (servicesList && servicesList.services) {
      Object.keys(servicesList.services).map(serviceType => {
        return servicesAtStation.push({"id":servicesList.services[serviceType][0].code, "name": serviceType})
      })
    }
  }

  return (
    <div
      className={'infobox' + (data.id ? '' : ' no-arrow')}
      onMouseLeave={() => appStore.dispatch(actionOutMarker(data))}
      onClick={() => data.name && appStore.dispatch(actionOpenMarker(data))} // Avoid crash if there is no "real" data like sncf-ter entrance map popups
    >
      {data.name && (
        <div className="infobox-title">
          {data.name}
          <div className={"infobox-title-tools" + (data.pmr && REACT_APP_SHOW_PMR !== "none" ? " with-pmr" : "")}>
            {REACT_APP_SHOW_PMR !== "none" && data.pmr
              ? REACT_APP_SHOW_PMR === "pmr" && <div className="is-pmr" />
              : REACT_APP_SHOW_PMR === "no-pmr" && <div className="is-no-pmr" />}
            {REACT_APP_SHOW_ADDITIONAL_STOP_TOOL &&
              JSON.parse(REACT_APP_SHOW_ADDITIONAL_STOP_TOOL).map(tool => {
                if (data[tool] === true) {
                  return <div key={`${data.id}_${tool}`} className={`is-${tool}`} />
                } else {
                  return false
                }
              })}
            {modules.find(m => m.id === "route-calculation") && !isJDApp(REACT_APP_TYPE, variant) && !lock && (
              <Tippy theme={'latitude'} touch={['hold', 500]} placement={'right'} boundary="window" content={languageFile["title-go-to-route-calculation"]}>
                <div
                  className="tool-route-calculation toolSmall"
                  onClick={e => {
                    e.stopPropagation()
                    goToRouteCalculation(data)
                  }}
                />
              </Tippy>
            )}
          </div>
        </div>
      )}
      {data.severity && ["blocking", "delays"].includes(data.severity) && (
        <div className={"severity " + data.severity}>
          <div className="disruptionSeverity white">
            <div className="icon" />
          </div>
          {data.severity === "blocking"
            ? `${languageFile["severity-blocking-stop"]} ${getLine(lines, {id: params.current.split('_')[0]}).code}`
            : languageFile["severity-delays-stop"]}
        </div>
      )}
      {servicesAtStation.length > 0 && (
        <div className="infobox-services-station">
          <span>{languageFile["infobox-services-title"]}</span>
          <div className='services-list'>
            {servicesAtStation.map(service => {
              return (
                <Tippy key={service.id} theme={'latitude'} touch={['hold', 500]} delay={[15, 0]} placement={'right'} boundary="window" content={languageFile[service.name]}>
                  <img
                    src={"/assets/images/places/" + service.id + ".svg"}
                    alt={service.name}
                  />
                </Tippy>
              )
            })}
          </div>
        </div>
      )}
      <div className="infobox-content">
        {data.cat_id || data instanceof BikeInterface
          ? buildPopupContent(state, data)
          : buildLinesLabels(state, data, "infobox")}
      </div>
    </div>
  )
}

/**
 * Display popup content for places with a cat_id
 * @param state
 * @param data
 * @returns HTMLElement
 */
const buildPopupContent = (state, data) => {
  const { languageFile } = state.app

  return <div className='place'>
    {{
      'poi_type:amenity:bicycle_rental': (data.stand || data instanceof BikeInterface) && (
        <div className='bss'>
          <span className='bikes'>
            {data.stand ? data.stand.available_bikes : data.availablePlaces}
            <img src='/assets/images/modes/bss.svg' alt={languageFile['bss-bikes-available']} />
          </span>
          <span className='seats'>
            {data.stand ? data.stand.available_places : data.capacity}
            <img src='/assets/images/bss-seat.svg' alt={languageFile['available-places']} />
          </span>
        </div>
      ),
      'poi_type:amenity:parking': data.stand && (
        <div className='place-infos'>
          {data.stand.available && data.stand.available !== 0 ? <>
              <span className="realtime-seats">
                {data.stand.available} {languageFile["available-places"]}{" "}
                <Tippy theme={'latitude'} touch={['hold', 500]} placement={'right'} boundary="window" content={languageFile["realtime-gif-title"]}>
                  <img
                    src="/assets/images/realtime.gif"
                    alt={languageFile["realtime-gif-alt"]}
                  />
                </Tippy>
              </span>
            </> : data.stand.available === 0 ? 
              <span className="realtime-seats">{languageFile["no-available-places"]}</span> 
              : <span className="realtime-seats">{languageFile["no-informations-for-available-places"]}</span>
          }
        </div>
      ),
      'poi_type:amenity:park_ride': data.stand && (
        <div className="place-infos">
          <span className="parcs">
            {data.stand.total_places} {languageFile["total-places"]}{" "}
            {data.stand.occupied_PRM
              ? "- " +
                data.stand.occupied_PRM +
                " " +
                languageFile["disabled-spaces"]
              : ""}
          </span>
        </div>
      )
    }[data.cat_id]}
    {data.cat_id !== 'poi_type:TCL:BET' && (!data.info || data.cat_id !== 'poi_type:amenity:parking') && <div>{data.address} <br /> {data.cp} {data.city}</div>}
    {
      (data.cat_id === 'poi_type:TCL:AGE' || data.cat_id === 'poi_type:TCL:BET' || data.cat_id === 'poi_type:TCL:RIS' || data.cat_id === 'poi_type:amenity:parking') && <div className='info'>{data.info}</div>
    }
  </div>
}

/**
 * Build lines labels in infobox / board
 * TODO : recode board side
 * @param state
 * @param data
 * @param key
 * @returns HTMLElement
 */
export const buildLinesLabels = (state, data, key) => {
  let lines = data.lines
  const { lock, linesModes, size, variant, languageFile } = state.app
  
  // Avoid undefined lines...
  if (!lines) {
    // TODO Add custom info, like addresses for POI (https://latitude-cartagene.atlassian.net/browse/TCL-224)
    return null
  }

  // SNCF ??
  lines = lines.map(line => getLine(state.app.lines, line))

  let styleLine = (REACT_APP_TYPE === 'sncf-ter' && variant === '/normandie') ? 'modeWithDirection' : REACT_APP_LINES_MAIN_TYPE
  const div = lines => (
    <div
      key={key + Math.random()}
      className={
        (key === "infobox" ? "infobox-" : "") +
        "lines " + size + (styleLine.includes('WithDirection') ? " line-with-direction" : "")
      }
    >
      {Object.keys(lines).map(m =>
        unique(lines[m], "id").map(line => {
          // Retrieve the global line
          line = getLine(state.app.lines, line)

          if (JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS).length) {
            const exceptions = JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS)
            const foundExceptedLine = exceptions.find(e => e.lines.includes(line.id))
            
            if (foundExceptedLine) {
              styleLine = foundExceptedLine.type
            }
          }

          switch (styleLine) {
            case "modeWithDirection":
              const lineMode = linesModes.find(mode => mode.modes.includes(line.mode))
              return (
                <div
                  className="attribute-line"
                  key={line.id}
                  onClick={e => {
                    e.stopPropagation()
                    // ! TODO DON'T GO IN LINES TAB
                    !lock && appStore.dispatch(actionOnLineSelected(line, envVarToBool(REACT_APP_DONT_DISPLAY_STOP_AT_LINE_SELECTION) ? [] : data))
                  }}
                >
                  <div
                    className="line mode"
                    style={{ background: "#" + line.color, color: luminance("#" + line.color) > 0.5 ? "#333" : "#fff" }}
                  >
                    {lineMode.name}
                  </div>
                  <div className="name">{line.name}</div>
                </div>
              )
            case "codeWithDirection":
              return (
                <div
                  className="attribute-line"
                  key={line.id}
                  onClick={e => {
                    e.stopPropagation()
                    // ! TODO DON'T GO IN LINES TAB
                    !lock && appStore.dispatch(actionOnLineSelected(line, envVarToBool(REACT_APP_DONT_DISPLAY_STOP_AT_LINE_SELECTION) ? [] : data))
                  }}
                >
                  <div
                    className="line code"
                    style={{ background: "#" + line.color, color: luminance("#" + line.color) > 0.5 ? "#333" : "#fff" }}
                  >
                    {line.code}
                  </div>
                  <div className="name">{line.name}</div>
                </div>
              )
            case "image":
              return (
                <div
                  className="line"
                  key={line.id}
                  onClick={e => {
                    e.stopPropagation()
                    !lock && appStore.dispatch(actionOnLineSelected(line, envVarToBool(REACT_APP_DONT_DISPLAY_STOP_AT_LINE_SELECTION) ? [] : data))
                  }}
                >
                  <img src={"/assets/images/lines/" + line.code + ".svg"} alt={line.code} />
                </div>
              )
            case "color":
              return (
                <div
                  key={line.id}
                  className="line"
                  onClick={e => {
                    e.stopPropagation()
                    !lock && appStore.dispatch(actionOnLineSelected(line, envVarToBool(REACT_APP_DONT_DISPLAY_STOP_AT_LINE_SELECTION) ? [] : data))
                  }}
                >
                  <div className='tools-at-line'>
                    {REACT_APP_SHOW_ADDITIONAL_STOP_TOOL && JSON.parse(REACT_APP_SHOW_ADDITIONAL_STOP_TOOL).map(tool => {
                      if (data[tool] && data[tool].length && data[tool].includes(`${line.code}_${line.network}`)) {
                        return <div key={`${data.id}_${tool}`} className={`is-${tool}`}/>
                      } else {
                        return false
                      }
                    })}
                  </div>
                  <div className='line-code' style={{
                    background: '#' + line.color,
                    color: luminance(line.color) > 0.5 ? '#333' : '#fff'
                  }}>
                    {line.code}
                  </div>
                </div>
              )
            default:
              return ''
          }
        })
      )}
    </div>
  )

  // WTF Sorting ...
  if (REACT_APP_TYPE === "tcl") {
    // Sort only the bus
    if (lines[0] && lines[0].cat === "bus") {
      sortAlphabetic(lines, "code")
    }

    // Re sort only "C" lines
    lines.sort((a, b) => {
      a = getLine(state.app.lines, a)
      b = getLine(state.app.lines, b)

      if (a.code && b.code) {
        const codeA = parseInt(a.code.replace("C", ""), 10)
        const codeB = parseInt(b.code.replace("C", ""), 10)

        return codeA - codeB
      }

      return false
    })
  }

  return <>
    {envVarToBool(REACT_APP_CONNECTIONS_TEXT) && <div className="connections-at-stop">{languageFile["connections-at-stop"]}</div>}
    {div(groupLinesByMode(lines, linesModes, variant))}
  </>
}
