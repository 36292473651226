
export const actionSetNetworkMode = isNetwork => ({
    type: 'SET_NETWORK_MODE',
    isNetwork
})

export const actionSetNetworkMapRef = mapRef => ({
    type: 'SET_NETWORK_MAP_REF',
    mapRef
})

export const actionSetNetworkMapHeavyLines = lines => ({
    type: 'SET_NETWORK_MAP_HEAVY_LINES',
    lines
})

export const actionSetNetwotkSelectedLine = line => ({
    type: 'SET_NETWORK_SELECTED_LINE',
    line
})

export const actionSetNetworkSelectedTown = town => ({
    type: 'SET_NETWORK_SELECTED_TOWN',
    town
})