export const actionSetAreas = areas => ({
  type: 'SET_AREAS',
  areas
})

export const actionSetStops = stops => ({
  type: 'SET_STOPS',
  stops
})

export const actionBuildMarker = (data, options) => ({
  type: 'BUILD_MARKER',
  data,
  options
})

export const actionOverMarker = data => ({
  type: 'OVER_MARKER',
  data
})

export const actionOutMarker = data => ({
  type: 'OUT_MARKER',
  data
})

export const actionOpenMarker = data => ({
  type: 'OPEN_MARKER',
  data
})

export const actionOnLineSelected = (line, data) => ({
  type: 'ON_LINE_SELECTED',
  line,
  data
})

export const actionBuildHeavyLines = () => ({
  type: 'BUILD_HEAVY_LINES'
})

export const actionBuildTransportPlaces = places => ({
  type: 'BUILD_TRANSPORT_PLACES',
  places
})

export const actionBuildMapPlaces = places => ({
  type: 'BUILD_MAP_PLACES',
  places
})

export const actionBuildPublicPlaces = places => ({
  type: 'BUILD_PUBLIC_PLACES',
  places
})

export const actionBuildschoolsJD = schoolsJD => ({
  type: 'BUILD_JD_SCHOOLS',
  schoolsJD
})

export const actionBuildAutocomplete = (inputProps, isModal) => ({
  type: 'BUILD_AUTOCOMPLETE',
  inputProps,
  isModal
})

export const actionBuildPlacesByCatInList = places => ({
  type: 'BUILD_PLACES_BY_CAT_IN_LIST',
  places
})

export const actionBuildRouteCalcPreferences = () => ({
  type: 'BUILD_ROUTE_CALC_PREFERENCES'
})

export const actionBuildTabsPanel = () => ({
  type: 'BUILD_TABS_PANEL'
})

export const actionBuildBikePaths = files => ({
  type: 'BUILD_BIKE_PATHS',
  files
})

export const actionBuildMapBikes = bikes => ({
  type: 'BUILD_MAP_BIKES',
  bikes
})