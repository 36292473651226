import axios from 'axios'
import 'rc-time-picker/assets/index.css'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { appStore } from '../store'
import {
  actionInitApp,
  actionInitModes,
  actionInitDate,
  actionSetAppLanguage,
  actionSetAppLocker,
  actionSetAppPanelSize,
  actionSetUserToken,
  actionUpdateMediaQueries,
  actionSetHash
} from '../actions/app'
import { Route, Router, Switch } from 'react-router-dom'
import history from '../history'
import '../scss/app.scss'
import 'tippy.js/dist/tippy.css';
import moment from 'moment/moment'
import Map from './Map'
import Board from './Board'
import Modal from './Modal'
import { actionBuildTerritoryOutline, actionHandleLegendState } from '../actions/map'
import { getURLSearchParams } from '../services/tools'
import { initGTM } from '../tracking'
import { envVarToBool, isJDApp, isVariant } from '../services/tools'

const { REACT_APP_HEADER, REACT_APP_TYPE, REACT_APP_HEAVY_LINES, REACT_APP_GTM, REACT_APP_LEGEND_OPEN_LAUNCH, REACT_APP_NETWORK_RESTRICT } = process.env

class App extends Component {
  mediaQueries = null
  languageFile = null

  state = {
    header: null
  }

  handleMediaQueryChanged = event => {
    appStore.dispatch(actionUpdateMediaQueries(event.matches))
  }

  async componentWillMount () {
    const params = getURLSearchParams(history.location)

    // store the token in redux
    if (params.token) {
      appStore.dispatch(actionSetUserToken(params.token))
    }

    // set locker
    appStore.dispatch(actionSetAppLocker(params.lock))

    // set panel size
    appStore.dispatch(actionSetAppPanelSize(params.size))

    // set mediaqueries
    this.mediaQueries = window.matchMedia('(max-width: 600px)')

    // set legend
    if (REACT_APP_LEGEND_OPEN_LAUNCH === 'all' || (REACT_APP_LEGEND_OPEN_LAUNCH === 'web-only' && !this.mediaQueries.matches)) {
      appStore.dispatch(actionHandleLegendState(false))
    }

    // Dispatch action to build territor outline
    const network = REACT_APP_NETWORK_RESTRICT && isVariant() ? isVariant().substr(1) : null

    axios.get(`/assets/${network || 'geojson'}/territory_outline.geojson`).then(response => {
      appStore.dispatch(actionBuildTerritoryOutline(response.data))
    })

    try {
      const initParams = {params: {}}
      if (params.touchscreen) {
        initParams.params.touchscreen = params.touchscreen
      }
      if (params.lang) {
        initParams.params.lang = params.lang
      }

      if (envVarToBool(REACT_APP_NETWORK_RESTRICT)) {
        initParams.params.network = network
      }

      const requestInit = await axios.get('/api/init-application', initParams)
      const init = requestInit.data
      this.languageFile = init.languageFile

      // set hash
      appStore.dispatch(actionSetHash(init.hash))
      // set mobile
      init.isMobile = this.mediaQueries.matches
      // set heavy lines
      init.heavyIds = JSON.parse(REACT_APP_HEAVY_LINES).length
        ? !isJDApp(REACT_APP_TYPE, isVariant()) // on JD no entrance map
          ? JSON.parse(REACT_APP_HEAVY_LINES)
          : []
        : []

      init.variant = isVariant()
      // set modes route-calculation if module exist
      const routeCalculationModule = init.modules.find(m => m.id === 'route-calculation')
      if (routeCalculationModule) {
        const modes = {
        }

        for (const mode of routeCalculationModule.modes) {
          modes[mode.type] = mode.value
        }
        
        modes['pmr'] = history.location.search.includes('pmr')
        modes['avoid'] = ''
        appStore.dispatch(actionInitModes(modes))
        // Init Route Calc selected date
        appStore.dispatch(actionInitDate(moment()))
      }
      // set language
      appStore.dispatch(actionSetAppLanguage(init.lang))
      // update HTML lang attribute
      document.documentElement.lang = init.lang

      // set everything else
      appStore.dispatch(actionInitApp(init))
    } catch (e) {
      console.warn('Error : app won\'t init : ', e)
    }
    
    this.mediaQueries.addListener(this.handleMediaQueryChanged)

    // Load custom header if needed
    if (envVarToBool(REACT_APP_HEADER)) {
      const response = await axios(process.env.PUBLIC_URL + '/header.html')

      this.setState({
        header: response.data
      })
    }
  }

  componentDidMount () {
    // Google Tag Manager
    REACT_APP_GTM && initGTM()
  }

  componentWillUnmount () {
    this.mediaQueries && this.mediaQueries.removeListener(this.handleMediaQueryChanged)
  }

  render () {
    const { isMobile, show, modules, variant } = this.props

    return <>
      {envVarToBool(REACT_APP_HEADER) && <header className={'app-header' + (isMobile ? ' is-mobile' : '')} dangerouslySetInnerHTML={{__html: this.state.header}} />}
      <Router history={history}>
        <main className='container'>
          <Switch>
            {modules.map((module, index) =>
              <Route
                key={module.id + '_' + index}
                path={(variant ? variant + '/' : '/') + (['thematic', 'text-board'].includes(module.id) ? module.data : module.id)}
                component={() => <Board module={module} />} />
            )}
            <Route component={() => <Board isMobileBoot={this.mediaQueries.matches} />} />
          </Switch>
          <Map startOnMobile={this.mediaQueries.matches} />
          {
            show && <Modal />
          }
        </main>
      </Router>
    </>
  }
}

const mapStateToProps = state => {
  return {
    isMobile: state.app.isMobile,
    modules: state.app.modules,
    show: state.modal.show,
    variant: state.app.variant
  }
}

export default connect(mapStateToProps)(App)
