/**
 * Use this action to dispatch multiple actions in a row
 * @param actions
 * @returns {{type: string, actions: *[]}}
 */
export const batch = (...actions) => ({
  type: 'BATCH',
  actions
})

export const actionInitApp = data => ({
  type: 'INIT_APP',
  data
})

export const actionUpdateMediaQueries = isMobile => ({
  type: 'UPDATE_MEDIA_QUERIES',
  isMobile
})

export const actionSetMap = map => ({
  type: 'SET_MAP',
  map
})

export const actionSetComponent = component => ({
  type: 'SET_COMPONENT',
  component
})

export const actionScrollEnd = top => ({
  type: 'SCROLL_END',
  top
})

export const actionSetUserToken = token => ({
  type: 'SET_USER_TOKEN',
  token
})

export const actionSetAppLanguage = language => ({
  type: 'SET_APP_LANGUAGE',
  language
})

export const actionSetAppLocker = lock => ({
  type: 'SET_APP_LOCKER',
  lock
})

export const actionSetAppPanelSize = size => ({
  type: 'SET_APP_PANEL_SIZE',
  size
})

export const actionInitModes = modes => ({
  type: 'INIT_MODES',
  modes
})

export const actionHandleModesChanged = mode => ({
  type: 'HANDLE_MODES_CHANGED',
  mode
})

export const actionInitDate = date => ({
  type: 'INIT_DATE',
  date
})

export const actionHandleSelectedDateChanged = (date, now) => ({
  type: 'HANDLE_SELECTED_DATE_CHANGED',
  date,
  now
})

export const actionHandleSelectedTimeChanged = date => ({
  type: 'HANDLE_SELECTED_TIME_CHANGED',
  date
})

export const actionHandleRepresentsChanged = represent => ({
  type: 'HANDLE_REPRESENTS_CHANGED',
  represent
})

export const actionSetHash = hash => ({
  type: 'SET_APP_HASH',
  hash
})