import React from "react"
import moment from "moment"
import Autocomplete from "react-autocomplete"
import { luminance } from "luminance-js"
import history from "../history"
import { DateCalendar } from "react-picker-date-and-time"
import TimePicker from "rc-time-picker"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import {
  clickOnPlaceInList,
  focusInput,
  getCoordsFromUrlObject,
  getURLSearchParams,
  goToRouteCalculation,
  humanReadableOpeningHours,
  isJDApp,
  isSystemUS,
  onChangeAutocompleteInput,
  onSelectAutocompleteValue,
  unique,
  onTabSelected,
  resize
} from "./tools"
import { Collapse } from "react-collapse"
import { appStore } from "../store"
import {
  actionSetFavoriteAddress,
  actionSetFavoriteLine,
  actionSetFavoritePlace,
  actionSetFavoriteStop,
  actionSetOpenedCollapse,
  actionSetRouteCalcDatesPanel,
  actionSetRouteCalcModesPanel,
  actionDecrementStationIndex,
  actionIncrementStationIndex,
  actionSetTooManyFavorites
} from "../actions/board"
import {
  actionHandleModesChanged,
  actionHandleRepresentsChanged,
  actionHandleSelectedDateChanged,
  actionHandleSelectedTimeChanged
} from "../actions/app"
import * as axios from "axios"
import {
  actionOutMarker,
  actionOverMarker,
  actionOpenMarker,
  actionBuildPlacesByCatInList,
  actionBuildPublicPlaces,
  actionBuildTransportPlaces
} from "../actions/withRedux"
import { updateDataLayer } from "../tracking"
import colors from "../scss/app.scss"
import Tippy from "@tippy.js/react"
import { renderLinesGroup } from '../utils/leaflet/map'

const { REACT_APP_TYPE, REACT_APP_GTM, REACT_APP_LINES_MAIN_TYPE, REACT_APP_LINES_TYPE_EXCEPTIONS } = process.env

export const buildAutocomplete = (state, inputProps, isModal) => {
  const { pathname } = history.location
  const { languageFile, lock, size, touchscreenSelected, linesModes, variant, component } = state.app
  return (
    <form
      onSubmit={e => {
        e.preventDefault()
      }}
    >
      <Autocomplete
        inputProps={{ id: inputProps }}
        autoHighlight
        wrapperStyle={
          !isModal
            ? {
                display: "flex",
                flex: 1,
                position: "relative"
              }
            : {}
        }
        value={
          inputProps === "inputStart"
            ? state.board.inputStartValue
            : inputProps === "inputEnd"
            ? state.board.inputEndValue
            : state.board.inputValue
        }
        items={
          inputProps === "inputStart"
            ? state.board.inputStartItems
            : inputProps === "inputEnd"
            ? state.board.inputEndItems
            : state.board.inputItems
        }
        getItemValue={item => item.name}
        onSelect={(valueSelected, itemSelected) =>
          onSelectAutocompleteValue(valueSelected, itemSelected, inputProps, state, isModal)
        }
        onChange={event => onChangeAutocompleteInput(event, inputProps, state)}
        renderMenu={children => {
          const geoloc = children.filter(c => c.key === "geoloc")
          const historyItems = children.filter(c => c.key.includes("history-"))
          const favoritesItems = children.filter(c => c.key.includes("favorite-"))
          const isEmpty = children.filter(c => c.key === "no_result").length === 1
          if (isEmpty) {
            children = children.filter(c => c.key !== "no_result")
          }

          return (
            <ul className={"autocomplete " + size + (pathname.includes("route-calculation") ? " offsetRoute" : "")}>
              {geoloc}
              {historyItems.length >= 1 && !touchscreenSelected && (
                <li className="autocompleteHistory">
                  <div className="autocompleteHeader">{languageFile["autocomplete-historic"]}</div>
                  <div>{historyItems}</div>
                </li>
              )}
              {favoritesItems.length >= 1 && (
                <li className="autocompleteFavorites">
                  <div className="autocompleteHeader">{languageFile["autocomplete-favorites"]}</div>
                  <div>{unique(favoritesItems, "key")}</div>
                </li>
              )}
              {isEmpty && (
                <li className="item" key={"emptyResults"}>
                  {languageFile["autocomplete-no-result"]}
                </li>
              )}
              {historyItems.length === 0 && favoritesItems.length === 0 && children.filter(c => c.key !== "geoloc")}
            </ul>
          )
        }}
        renderInput={props => (
          <>
            <input
              className="input"
              {...props}
              onFocus={e => !isModal && focusInput(e, inputProps, state)}
              placeholder={
                inputProps === "inputSchedules"
                  ? languageFile["placeholder-inputSchedules"]
                  : pathname.includes("/route-calculation")
                  ? inputProps === "inputStart"
                    ? languageFile["departure"]
                    : languageFile["arrival"]
                  : inputProps.includes("inputThematic-") && pathname.includes("pt-vente")
                  ? languageFile["placeholder-inputPtVente"]
                  : inputProps.includes("inputThematic-") && pathname.includes("e-tecely")
                  ? languageFile["placeholder-inputeTecely"]
                  : inputProps.includes("inputThematic-") && pathname.includes("gab")
                  ? languageFile["placeholder-inputGAB"]
                  : inputProps.includes("inputThematic-") && pathname.includes("p+r")
                  ? languageFile["placeholder-inputPR"]
                  : inputProps.includes("inputThematic-") && pathname.includes("park-ride")
                  ? languageFile["placeholder-park-ride"]
                  : inputProps.includes("inputThematic-") && pathname.includes("agence")
                  ? languageFile["placeholder-inputHideAgence"]
                  : inputProps.includes("inputThematic-") && pathname.includes("pt-service")
                  ? languageFile["placeholder-inputHidePtService"]
                  : isJDApp(REACT_APP_TYPE, variant)
                  ? languageFile["placeholder-inputAroundJD"]
                  : languageFile["placeholder-inputAround"]
              }
            />

            {REACT_APP_TYPE !== "sncf-ter" &&
              !isModal &&
              !isJDApp(REACT_APP_TYPE, variant) &&
              !lock &&
              state.board.goToValid &&
              !pathname.includes("/route-calculation") && (
                <Tippy
                  theme={"latitude"}
                  touch={["hold", 500]}
                  placement={"right"}
                  boundary="window"
                  content={languageFile["title-go-to-route-calculation"]}
                >
                  <div
                    className="go-to-route around"
                    onClick={async () => {
                      const params = getURLSearchParams(history.location)

                      if (pathname.includes("/lines")) {
                        goToRouteCalculation(params.stop_area)
                      } else if (pathname.includes("/around") && !params.from.includes(";")) {
                        goToRouteCalculation(params.from)
                      } else if (params.place) {
                        goToRouteCalculation(params.place)
                      } else {
                        const coords = await getCoordsFromUrlObject(
                          getURLSearchParams(history.location).from,
                          null,
                          component
                        )
                        goToRouteCalculation({
                          address: {
                            lon: coords[1],
                            lat: coords[0]
                          }
                        })
                      }
                    }}
                  >
                    <img
                      src={"/assets/images/menu/route-calculation.svg"}
                      alt={languageFile["route-calculation-board-title"]}
                    />
                  </div>
                </Tippy>
              )}
          </>
        )}
        renderItem={(item, isHighlighted) => {
          const lineMode = linesModes && linesModes.find(mode => mode.modes.includes(item.mode))

          item.styleLine =
            REACT_APP_TYPE === "sncf-ter" && variant === "/normandie" ? "modeWithDirection" : REACT_APP_LINES_MAIN_TYPE
          if (JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS).length) {
            const exceptions = JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS)
            const foundExceptedLine = exceptions.find(e =>
              e.lines.includes(item.id.replace("history-", "").replace("favorite-", ""))
            )

            if (foundExceptedLine) {
              item.styleLine = foundExceptedLine.type
            }
          }

          return item.embedded_type ? (
            <li className={"item" + (isHighlighted ? " highlight" : "")} key={item.id}>
              <img
                width={30}
                src={"/assets/images/autocomplete/" + item.embedded_type + ".svg"}
                alt={item.embedded_type}
              />
              {item.name}
            </li>
          ) : item.id === "geoloc" ? (
            <li className={"item" + (isHighlighted ? " highlight" : "")} key={item.id}>
              <img
                width={30}
                src={"/assets/images/autocomplete/position.svg"}
                alt={languageFile["autocomplete-geoloc"]}
              />
              {item.name}
            </li>
          ) : item.id.includes("history-") && item.type && item.type !== "line" ? (
            <div className={"item" + (isHighlighted ? " highlight" : "")} key={item.id}>
              <img width={30} src={"/assets/images/autocomplete/" + item.type + ".svg"} alt={item.type} />
              {item.name}
            </div>
          ) : item.id.includes("favorite-") && item.type && item.type !== "line" && item.type !== "stop_point" ? (
            <div className={"item" + (isHighlighted ? " highlight" : "")} key={item.id}>
              <img width={30} src={"/assets/images/autocomplete/" + item.type + ".svg"} alt={item.type} />
              {item.name}
            </div>
          ) : item.id.includes("stop_") ? (
            <li className={"item" + (isHighlighted ? " highlight" : "")} key={item.id}>
              <img width={30} src={"/assets/images/autocomplete/stop_area.svg"} alt={languageFile["stop"]} />
              {item.name}
            </li>
          ) : state.board.thematicPlaces ? (
            <li className={"item" + (isHighlighted ? " highlight" : "")} key={item.id}>
              <img width={30} src={"/assets/images/places/" + item.code + ".svg"} alt={item.code} />
              {item.name}
            </li>
          ) : item.id.includes("line") ? (
            <div
              className={"autocomplete-line" + (isHighlighted ? " highlight" : "")}
              key={item.id}
              style={{ padding: 10 }}
            >
              {
                {
                  modeWithDirection: (
                    <div
                      key={item.id}
                      className="line line-with-direction mode"
                      style={{
                        background: "#" + item.color,
                        color: luminance(item.color) > 0.5 ? "#333" : "#fff"
                      }}
                    >
                      {lineMode ? lineMode.name : ""}
                    </div>
                  ),
                  codeWithDirection: (
                    <div
                      key={item.id}
                      className="line line-with-direction code"
                      style={{
                        background: "#" + item.color,
                        color: luminance(item.color) > 0.5 ? "#333" : "#fff"
                      }}
                    >
                      {item.code}
                    </div>
                  ),
                  image: (
                    <div className="line">
                      <img src={"/assets/images/lines/" + item.code + ".svg"} alt={item.code} />
                    </div>
                  ),
                  color: (
                    <div className="line">
                      <span
                        className={"line-code"}
                        style={{
                          background: "#" + item.color,
                          color: luminance(item.color) > 0.5 ? "#333" : "#fff"
                        }}
                      >
                        {item.code}
                      </span>
                    </div>
                  )
                }[item.styleLine]
              }
              <div
                style={{
                  flex: 5,
                  paddingLeft: 15
                }}
              >
                {item.code !== item.name ? (
                  <div className="autocompleteDirection" key={item.name}>
                    {item.name}
                  </div>
                ) : (
                  unique(item.routes, "name").map(route => (
                    <div className="autocompleteDirection" key={route.name}>
                      {route.name}
                    </div>
                  ))
                )}
              </div>
            </div>
          ) : (
            <div key="no_result" />
          )
        }}
      />
    </form>
  )
}

export const buildPlacesByCatInList = (state, places = []) => {
  const placesCatToRender = Object.keys(places)
  const { openedCollapse } = state.board
  const { component, languageFile, variant } = state.app

  if (placesCatToRender.length === 0) {
    return (
      <div className="empty">
        {isJDApp(REACT_APP_TYPE, variant) ? languageFile["no-places-jd-around"] : languageFile["no-places-around"]}
      </div>
    )
  }

  return placesCatToRender.map(catToRender => (
    <div key={catToRender} className="group" onClick={() => appStore.dispatch(actionSetOpenedCollapse(catToRender))}>
      <div className="group-name">
        <div className="category">
          <img width={20} src={"/assets/images/places/" + places[catToRender][0].code + ".svg"} alt={catToRender} />
          {languageFile[catToRender]}
        </div>
        {(state.board.thematicPlaces || (component.state.places && component.state.tab === 1)) && (
          <div className="arrow-group">
            <img
              className={openedCollapse !== catToRender ? "closed" : ""}
              src="/assets/images/v.svg"
              alt={languageFile["collapse-arrow"]}
            />
          </div>
        )}
      </div>
      {state.board.thematicPlaces || (component.state.places && component.state.tab === 1) ? (
        <Collapse
          isOpened={openedCollapse === catToRender}
          hasNestedCollapse
          springConfig={{ stiffness: 800, damping: 50 }}
        >
          {openedCollapse === catToRender && renderPlacesList(state, places[catToRender])}
        </Collapse>
      ) : (
        <div>{renderPlacesList(state, places[catToRender])}</div>
      )}
    </div>
  ))
}

export const buildRouteCalcPreferences = state => {
  const { app, map } = state
  const { calculateItineraryNow, isMobile, language, languageFile, modes, selectedDate, selectedTime } = state.app
  const { datesPanel, modesPanel } = state.board
  const departure = modes.represents === "departure"

  const modesChoice = []
  for (const mode of Object.keys(app.modes)) {
    if (typeof app.modes[mode] === "boolean") {
      modesChoice.push({
        id: mode,
        name: languageFile[`modes-${mode}`]
      })
    }
  }

  const pickerStyle = {
    calendar: {
      top: map && !isMobile ? "25px" : "",
      bottom: map && isMobile ? "28%" : "",
      left: map && isMobile ? "0" : "",
      right: map && isMobile ? "0" : "",
      boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.15)"
    },
    colon: {
      padding: "0 5px 0 0 !important"
    },
    control: {
      boxShadow: "none",
      cursor: "pointer"
    },
    first: "#005e86",
    menu: {
      marginLeft: -5,
      position: "fixed",
      bottom: map && isMobile ? "25%" : "",
      top: ""
    },
    weekDays: {
      padding: "5px 0"
    },
    monthSelected: {
      fontWeight: 600
    },
    calendarButtonStyle: {
      fontSize: "1em"
    },
    inputsHours: {
      fontSize: "1em"
    },
    today: {
      background: "#f4f4f4",
      color: "#333",
      fontWeight: "500"
    }
  }

  pickerStyle.first = colors.primarycolor
  pickerStyle.calendarButtonStyle = {
    ...pickerStyle.calendarButtonStyle,
    padding: "5px",
    marginRight: 10
  }
  pickerStyle.calendar = {
    ...pickerStyle.calendar,
    padding: 15,
    background: "#f1f5f5"
  }
  pickerStyle.week = {
    ...pickerStyle.week,
    background: "#fff"
  }

  const dates = (
    <>
      <div className="represents">
        <div className="radio">
          <label>
            <input
              type="radio"
              value="departure"
              checked={departure}
              onChange={() => appStore.dispatch(actionHandleRepresentsChanged("departure"))}
            />
            {languageFile["route-calculation-board-preferences-departure"]}
          </label>
        </div>
        <div className="radio">
          <label>
            <input
              type="radio"
              value="arrival"
              checked={!departure}
              onChange={() => appStore.dispatch(actionHandleRepresentsChanged("arrival"))}
            />
            {languageFile["route-calculation-board-preferences-arrival"]}
          </label>
        </div>
      </div>
      <div className="pickers">
        <DateCalendar
          lang={language}
          systemUS={isSystemUS(language)}
          style={pickerStyle}
          todayTxt={languageFile["calendar-today-word"]}
          image={"/assets/images/calendar.svg"}
          arrow={"/assets/images/arrow-calendar.svg"}
          getSelectedDate={date => {
            appStore.dispatch(actionHandleSelectedDateChanged(date, moment(date).isSame(moment(), "day")))
          }}
          setDate={selectedDate && selectedDate.format("YYYYMMDD")}
        />
        <div className="time-picker-group">
          <img
            onClick={() => document.querySelector(".time-picker").click()}
            src={"/assets/images/route-calculation-time.svg"}
            alt={"picto heure"}
          />
          <TimePicker
            style={{ position: "relative" }}
            className="time-picker"
            popupClassName="time-picker-popup"
            defaultValue={moment()}
            placement={"bottomLeft"}
            use12Hours={language === "en"}
            showSecond={false}
            minuteStep={5}
            allowEmpty={false}
            inputReadOnly
            value={selectedTime}
            onChange={moment => {
              appStore.dispatch(actionHandleSelectedTimeChanged(moment))
            }}
            onClose={() => document.querySelector(".rc-time-picker-input").blur()}
          />
        </div>
      </div>
    </>
  )

  const prefs = (
    <>
      <div className="route-calculation-modes">
        {modesChoice.map(mode => (
          <div
            key={mode.id}
            className={"route-calculation-mode" + (modes[mode.id] ? " active" : "")}
            onClick={() => appStore.dispatch(actionHandleModesChanged(mode))}
          >
            <img src={"/assets/images/modes/" + mode.id + ".svg"} alt={mode.id} />
            {mode.name}
          </div>
        ))}
      </div>
    </>
  )

  return (
    <div className="prefs">
      <div className="buttons">
        <div
          className="collapse date"
          onClick={() => {
            appStore.dispatch(actionSetRouteCalcDatesPanel(datesPanel))
            resize(isMobile)
          }}
        >
          {departure ? languageFile["departure"] : languageFile["arrival"]}{" "}
          <span>
            {calculateItineraryNow
              ? languageFile["route-calculation-departure-now"]
              : selectedDate.format(isSystemUS(language) ? "MM/DD/YYYY" : "DD/MM/YYYY") +
                " " +
                languageFile["route-calculation-departure-date-hours"] +
                " " +
                moment(selectedTime).format(isSystemUS(language) ? "hh:mm a" : "HH:mm")}
          </span>
          <div className={"menu-item-arrow" + (datesPanel ? " active" : "")} />
        </div>
        {isMobile && <Collapse isOpened={datesPanel}>{datesPanel && dates}</Collapse>}
        <div
          className="collapse"
          onClick={() => {
            appStore.dispatch(actionSetRouteCalcModesPanel(modesPanel))
            resize(isMobile)
          }}
        >
          {languageFile["route-calculation-pref"]}
          <div className={"menu-item-arrow" + (modesPanel ? " active" : "")} />
        </div>
        {isMobile && <Collapse isOpened={modesPanel}>{modesPanel && prefs}</Collapse>}
      </div>

      {!isMobile && (
        <>
          <Collapse isOpened={datesPanel}>{datesPanel && dates}</Collapse>
          <Collapse isOpened={modesPanel}>{modesPanel && prefs}</Collapse>
        </>
      )}
    </div>
  )
}

export const buildTabsPanel = state => {
  const { component, languageFile, map, variant } = state.app
  const { tab } = component.state
  const { moduleData } = component.props

  if (!moduleData) {
    if (tab === 0) {
      appStore.dispatch(actionBuildTransportPlaces(component.state.pois))
      appStore.dispatch(actionBuildPublicPlaces([]))
    } else if (tab === 1) {
      appStore.dispatch(actionBuildTransportPlaces([]))
      appStore.dispatch(actionBuildPublicPlaces(component.state.places))
    }
  }
  resize(map.props.isMobile)

  return (
    <>
      <Tabs
        selectedIndex={component.state.tab || 0}
        onSelect={index => onTabSelected(component, index)}
        selectedTabClassName="active"
        selectedTabPanelClassName="active scroll"
      >
        <TabList className="tab-list">
          <Tab className="tab">
            {languageFile["transport-tab"]}
            <br />
            <span>{languageFile["time-around-tab"]}</span>
          </Tab>
          <Tab className="tab">
            {isJDApp(REACT_APP_TYPE, variant) ? languageFile["places-jd-tab"] : languageFile["places-tab"]}
            <br />
            <span>{languageFile["time-around-tab"]}</span>
          </Tab>
        </TabList>
        <TabPanel className="tab-panel">{renderLinesGroup(component)}</TabPanel>
        <TabPanel className="tab-panel">
          {appStore.dispatch(actionBuildPlacesByCatInList(component.state.places))}
        </TabPanel>
      </Tabs>
    </>
  )
}

const renderPlacesList = (state, places) => {
  const { pathname, search } = history.location
  const { token, language, languageFile, component } = state.app
  const {
    openedCollapse,
    placeClicked,
    favoritePlace,
    thematicPlaces,
    currentStationDepartureIndex,
    currentStationArrivalIndex
  } = state.board
  const { pois, displayInformations } = component.state

  return places
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(place => (
      <div
        key={place.id}
        className="place"
        onClick={e => {
          e.stopPropagation()

          if (favoritePlace && favoritePlace.id !== place.id) {
            appStore.dispatch(actionSetFavoritePlace(null))
          }

          if (pathname.includes("around")) {
            if (place.cat_id === "poi_type:stations") {
              clickOnPlaceInList(place, token, pois, thematicPlaces)
            } else {
              openedCollapse !== place.cat_id && appStore.dispatch(actionSetOpenedCollapse(place.cat_id))
              appStore.dispatch(actionOpenMarker(place))
            }
          } else {
            if (place.coord) {
              history.push({
                pathname,
                search: !search.includes(place.id) ? "?place=" + place.id : ""
              })
            } else {
              component.setState({ displayInformations: place.id })
            }
          }
        }}
        onMouseEnter={() => appStore.dispatch(actionOverMarker(place))}
        onMouseLeave={() => setTimeout(() => appStore.dispatch(actionOutMarker(place)))}
      >
        &bull;
        <div className="place-content">
          <span
            className={
              "place-content-name" + (placeClicked && place && (placeClicked.id === place.id ? " selected" : ""))
            }
          >
            {place.name}
          </span>

          {displayInformations === place.id && (
            <span className="informations-display" dangerouslySetInnerHTML={{ __html: place.informations }} />
          )}
          {placeClicked && placeClicked.error && (
            <div className={placeClicked.error.severity}>
              <img src="/assets/images/error.svg" alt={languageFile["severity-error"]} />
              {placeClicked.error.message}
            </div>
          )}

          {placeClicked && !placeClicked.error && placeClicked.id === place.id && (
            <div className="place-infos">
              {/* stand informations */}
              {placeClicked.stand ? (
                <>
                  {placeClicked.cat_id === "poi_type:amenity:bicycle_rental" && (
                    <div>
                      {placeClicked.stand.status === "open" ? (
                        <div className="bss">
                          <span className="bikes">
                            {placeClicked.stand.available_bikes}
                            <img src="/assets/images/modes/bss.svg" alt={languageFile["bss-bikes-available"]} />
                          </span>
                          <span className="seats">
                            {placeClicked.stand.available_places}
                            <img src="/assets/images/bss-seat.svg" alt={languageFile["available-places"]} />
                          </span>
                        </div>
                      ) : (
                        <div className="place-infos">{languageFile["bss-closed"]}</div>
                      )}
                    </div>
                  )}
                  {placeClicked.cat_id === "poi_type:amenity:park_ride" && (
                    <div>
                      <span className="parcs">
                        {placeClicked.stand.total_places} {languageFile["total-places"]}&nbsp;
                        {placeClicked.stand.occupied_PRM
                          ? "- " + placeClicked.stand.occupied_PRM + " " + languageFile["disabled-spaces"]
                          : ""}
                      </span>
                    </div>
                  )}
                  {placeClicked.cat_id === "poi_type:amenity:parking" && (
                    <div className="parking-seats-informations">
                      {placeClicked.stand.available && placeClicked.stand.available !== 0 ? (
                        <span className="realtime-seats">
                          {placeClicked.stand.available} {languageFile["available-places"]}&nbsp;
                          <Tippy
                            theme={"latitude"}
                            touch={["hold", 500]}
                            placement={"right"}
                            boundary="window"
                            content={languageFile["realtime-gif-title"]}
                          >
                            <img src="/assets/images/realtime.gif" alt={languageFile["realtime-gif-alt"]} />
                          </Tippy>
                        </span>
                      ) : placeClicked.stand.available === 0 ? (
                        <span className="realtime-seats">{languageFile["no-available-places"]}</span>
                      ) : (
                        <span className="realtime-seats">{languageFile["no-informations-for-available-places"]}</span>
                      )}
                      {place.capacity && <div className="parcs">
                        <div className="parcs-title">{languageFile["total-places"]}</div>&nbsp;<span>{place.capacity}</span>
                        {placeClicked.stand.occupied_PRM
                          ? "- " + placeClicked.stand.occupied_PRM + " " + languageFile["disabled-spaces"]
                          : ""}
                      </div>}
                    </div>
                  )}
                </>
              ) : (
                (placeClicked.cat_id === "poi_type:amenity:park_ride" ||
                  placeClicked.cat_id === "poi_type:amenity:parking" ||
                  placeClicked.cat_id === "poi_type:amenity:bicycle_rental") && (
                  <div className="loading">
                      <img src="/assets/images/loading.gif" width={20} alt={languageFile.back} />
                    </div>
                )
              )}

              {/* stations informations */}
              {placeClicked.cat_id === "poi_type:stations" && (
                <div>
                  <div className="station-schedules">
                    <div className="station-schedules-header">
                      <div>{languageFile["sncf-stations-departure"]}</div>
                    </div>
                    <div
                      className={
                        "station-schedules-content" +
                        (place.stand && place.stand.departures.length ? " not-empty" : "")
                      }
                    >
                      {place.stand && place.stand.departures.length
                        ? place.stand.departures
                            .filter(
                              (_, index) =>
                                index > currentStationDepartureIndex * 3 - 1 &&
                                index <= currentStationDepartureIndex * 3 + 2
                            )
                            .map((departure, index) => (
                              <div key={index} className="station-schedules-entry">
                                <div>
                                  {departure.realtime ? (
                                    <span>
                                      {departure.time} <del>{departure.basetime}</del>
                                    </span>
                                  ) : (
                                    departure.basetime
                                  )}
                                </div>
                                <div className="station-schedules-entryDirection">
                                  {departure.direction}
                                  <div className="station-schedules-entry-mode">{departure.mode}</div>
                                </div>
                              </div>
                            ))
                        : languageFile["no-stations-departures"]}
                    </div>
                    {place.stand && place.stand.departures.length > 3 && (
                      <div className="station-pagination">
                        <div
                          className="station-pagination-previous"
                          onClick={e => {
                            e.stopPropagation()
                            currentStationDepartureIndex > 0 &&
                              appStore.dispatch(actionDecrementStationIndex("departure"))
                          }}
                        >
                          <img src="/assets/images/v.svg" alt={languageFile["collapse-arrow"]} />
                        </div>
                        <div
                          className="station-pagination-next"
                          onClick={e => {
                            e.stopPropagation()
                            currentStationDepartureIndex < 6 &&
                              appStore.dispatch(actionIncrementStationIndex("departure"))
                          }}
                        >
                          <img src="/assets/images/v.svg" alt={languageFile["collapse-arrow"]} />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="station-schedules">
                    <div className="station-schedules-header">
                      <div>{languageFile["sncf-stations-arrival"]}</div>
                    </div>
                    <div
                      className={
                        "station-schedules-content" + (place.stand && place.stand.arrivals.length ? " not-empty" : "")
                      }
                    >
                      {place.stand && place.stand.arrivals.length
                        ? place.stand.arrivals
                            .filter(
                              (_, index) =>
                                index > currentStationArrivalIndex * 3 - 1 &&
                                index <= currentStationArrivalIndex * 3 + 2
                            )
                            .map((arrival, index) => (
                              <div key={index} className="station-schedules-entry">
                                <div>
                                  {arrival.realtime ? (
                                    <span>
                                      {arrival.time} <del>{arrival.basetime}</del>
                                    </span>
                                  ) : (
                                    arrival.basetime
                                  )}
                                </div>
                                <div className="station-schedules-entryDirection">
                                  {arrival.direction}
                                  <br />
                                  <span className="station-schedules-entry-mode">{arrival.mode}</span>
                                </div>
                              </div>
                            ))
                        : languageFile["no-stations-arrivals"]}
                    </div>
                    {place.stand && place.stand.arrivals.length > 3 && (
                      <div className="station-pagination">
                        <div
                          className="station-pagination-previous"
                          onClick={e => {
                            e.stopPropagation()
                            currentStationArrivalIndex > 0 &&
                              appStore.dispatch(actionDecrementStationIndex("arrival"))
                          }}
                        >
                          <img src="/assets/images/v.svg" alt={languageFile["collapse-arrow"]} />
                        </div>
                        <div
                          className="station-pagination-next"
                          onClick={e => {
                            e.stopPropagation()
                            currentStationArrivalIndex < 6 &&
                              appStore.dispatch(actionIncrementStationIndex("arrival"))
                          }}
                        >
                          <img src="/assets/images/v.svg" alt={languageFile["collapse-arrow"]} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* bicycle_parking informations */}
              {placeClicked.cat_id === "poi_type:amenity:bicycle_parking" && (
                <div>
                  <span className="parcs">
                    {place.capacity} {languageFile["total-places"]}
                  </span>
                </div>
              )}

              {/* display opening hours */}
              {placeClicked.opening_hours &&
                humanReadableOpeningHours(placeClicked.opening_hours, language, languageFile)}

              {/* display address */}
              {placeClicked.address && (
                <div className="address">
                  <div className="address-title">{languageFile["address-title"]}</div>
                  <div className="address-details">
                    {placeClicked.address}
                    <br />
                    {placeClicked.cp} {placeClicked.city}
                  </div>
                </div>
              )}

              {/* display email */}
              {placeClicked.email && (
                <div className="mail">
                  <div className="mail-title">{languageFile["mail-title"]}</div>
                  <div className="mail-details">
                    <a href={`mailto:${placeClicked.email}`} onClick={e => {
                      e.stopPropagation()
                    }}>{placeClicked.email}</a>
                  </div>
                </div>
              )}

              {/* display phone number */}
              {placeClicked.phone && (
                <div className="phone">
                  <div className="phone-title">{languageFile["phone-title"]}</div>
                  <div className="phone-details">
                  <a href={`tel:${placeClicked.phone}`} onClick={e => {
                      e.stopPropagation()
                    }}>{placeClicked.phone}</a>
                  </div>
                </div>
              )}

              {/* display pmr */}
              {placeClicked.wheelchair && (
                <div className="wheelchair">
                  <div className="wheelchair-title">{languageFile["wheelchair-title"]}</div>&nbsp;
                  <span>
                    {placeClicked.wheelchair === "yes" ? languageFile["wheelchair-yes"] : placeClicked.wheelchair === "no" ? languageFile["wheelchair-no"] : languageFile["wheelchair-limited"]}
                  </span>
                </div>
              )}

              {/* dispaly informations */}
              {placeClicked.info && <span className="info">{placeClicked.info}</span>}
            </div>
          )}
        </div>
      </div>
    ))
}

/**
 * Check if the given data is in the user's favorites
 * @param data
 * @param token
 * @returns {Promise<null>}
 */
export const isFavorite = async (data, token) => {
  if (!token) {
    return null
  }

  try {
    const response = await axios({
      url: "/api/favorites",
      params: {
        token
      }
    })

    const favorites = response.data && response.data.data

    if (data.stop_area) {
      return favorites.find(
        favorite =>
          favorite.code[0].value === data.stop_area && favorite.field_stop_point_route[0].value === data.route_id
      )
    } else {
      return favorites.find(favorite => favorite.code[0].value === data.id)
    }
  } catch (e) {
    console.warn(e)
  }
}

/**
 * Update favorite state.
 * Delete or add favorite into Niji's database
 * @param data
 * @param token
 * @returns {Promise<void>}
 */
export const updateFavorite = async (data, token) => {
  // Try if we have a token, if not, display a message
  if (!token) {
    return "No token"
  } else {
    const favorite = await isFavorite(data, token)
    let type = null

    if (data.id.includes("line:")) {
      type = "line"
    } else if (data.id.includes("poi:")) {
      type = "poi" // DO NOT CHANGE THIS TO 'place' ! It's a type used by Niji in their backend
    } else if (data.id.includes("stop_")) {
      type = "stopover"
    } else {
      type = "address"
    }

    // Fav, remove it
    if (favorite) {
      try {
        await axios({
          method: "delete",
          url: "/api/favorites",
          params: {
            token,
            favorite_internal_id: favorite.id[0].value
          }
        })

        // Remove the fav in the store
        switch (type) {
          case "line":
            appStore.dispatch(actionSetFavoriteLine(null))
            break
          case "poi":
            appStore.dispatch(actionSetFavoritePlace(null))
            break
          case "stopover":
            appStore.dispatch(actionSetFavoriteStop(null))
            break
          case "address":
            appStore.dispatch(actionSetFavoriteAddress(null))
            break
          default:
            break
        }
      } catch (e) {
        console.warn(e)
      }
    } else {
      try {
        const params = {
          token,
          favorite_type: type,
          favorite_name: data.name,
          favorite_navitia_id: data.id
        }

        // Handle the special param for stop_point
        if (type === "stopover") {
          params.favorite_navitia_id = data.stop_area
          params.favorite_stop_point_route = data.route_id
        }

        await axios({
          method: "post",
          url: "/api/favorites",
          params
        })

        // Add the fav in the store
        switch (type) {
          case "line":
            appStore.dispatch(actionSetFavoriteLine(data))
            // Google Tag Manager
            REACT_APP_GTM &&
              updateDataLayer({
                event: "map-addBookmark",
                favoriteType: "Ligne",
                favoriteItem: data.code
              })
            break
          case "poi":
            appStore.dispatch(actionSetFavoritePlace(data))
            break
          case "stopover":
            appStore.dispatch(actionSetFavoriteStop(data))
            // Google Tag Manager
            REACT_APP_GTM &&
              updateDataLayer({
                event: "map-addBookmark",
                favoriteType: "Arrêt",
                favoriteItem: data.name
              })
            break
          case "address":
            appStore.dispatch(actionSetFavoriteAddress(data))
            break
          default:
            break
        }
      } catch (e) {
        const error = e.response && e.response.data ? e.response.data.id : e
        console.warn(error)

        // Too many favorites
        if (e.response.status === 417) {
          appStore.dispatch(actionSetTooManyFavorites(type))
        }
      }
    }
  }
}
