import L from 'leaflet'
import { appStore } from '../store'
import React from 'react'
import { actionBuildTransportPlaces, actionBuildMarker, actionOpenMarker } from '../actions/withRedux'
import {
  actionGoToValid,
  actionInputEndItemsChange,
  actionInputEndValueChange,
  actionInputItemsChange,
  actionInputStartItemsChange,
  actionInputStartValueChange,
  actionInputValueChange,
  actionResetStationIndex,
  actionSetFavoritePlace,
  actionSetGeolocationError,
  actionSetPlaceClicked,
  actionSetOpenedCollapse
} from '../actions/board'
import {
  actionSetLineSelected, actionSetReduxMarkers, actionSetPublicPlaces, actionSetTransportPlaces
} from '../actions/map'
import axios from 'axios'
import history from '../history'
import { batch } from '../actions/app'
import { initModal, toggleModal } from '../actions/modal'
import { isFavorite } from './board'
import moment from 'moment'
import { updateDataLayer } from '../tracking'
import BikeInterface from '../interfaces/BikeInterface'
import exportedStyles from '../scss/app.scss'

const { REACT_APP_TYPE, REACT_APP_GTM, REACT_APP_AUTOCOMPLETE_GEOLOCATION, REACT_APP_VARIANTS, REACT_APP_NETWORK_RESTRICT, REACT_APP_POLES, REACT_APP_AREAS_ZOOM_LEVEL, REACT_APP_SHOW_STOP_BLOCKING_DISRUPTIONS } = process.env

export const addPolesExchanges = (areas, markers, zoom, onlyLine = false) => {
  const polesToDisplay = JSON.parse(REACT_APP_POLES)

  if (polesToDisplay && polesToDisplay.ids) {
    const poles = onlyLine
      ? areas.reduce((accumulator, currentArea) => {
        const area = currentArea.props.area.id
        const stop = markers.find(m => m.props.stop.stop_area === area)
        if (stop && polesToDisplay.ids.includes(area)) {
          accumulator.push(currentArea)
        }

        return accumulator
      }, [])
      : areas.filter(area => polesToDisplay.ids.includes(area.props.area.id))
    
    if (zoom > polesToDisplay.zoom) {
      poles.map(pole => {
        return markers.push(appStore.dispatch(actionBuildMarker({...pole.props.area, id: pole.props.area.id + '-pole-exchange'}, {
          icon: L.icon({
            iconUrl: '/assets/images/stops/pole.svg',
            iconSize: REACT_APP_AREAS_ZOOM_LEVEL > zoom ? [40, 40] : [52, 52],
            iconAnchor: REACT_APP_AREAS_ZOOM_LEVEL > zoom ? [20, 30] : [26, 39]
          }),
          area: {...pole.props.area},
          zIndexOffset: 10
        })))
      })
    }
  }
}

/**
 * Build Leaflet icon size / anchor for a given category
 * @param category
 * @returns {{iconAnchor: number[], iconSize: number[]}}
 */
export const buildPlaceIconSize = category => {
  let w, h

  switch (category) {
    case 'poi_type:amenity:bicycle_rental':
      w = h = 15
      break

    case 'poi_type:stations':
      h = 28
      w = 40
      break

    default:
      w = h = REACT_APP_TYPE === 'sncf-ter' ? 12.5 : 26
      break
  }

  return {
    iconSize: [w, h],
    iconAnchor: [w / 2, h / 2]
  }
}

export const displayDisruptedMarkersOnMap = (disruptions, reduxMarkers, currentLine, stop) => {
  const now = moment()

  // Handle stop_point & section disruptions on map
  const impactedObjects = []
  if (reduxMarkers.length > 0 && impactedObjects.length === 0) {
    for (const disruption of disruptions) {
      // Push only impacted_objects that are between begin & end dates today
      if (now.isBetween(moment(disruption.begin), moment(disruption.end))) {
        for (const object of disruption.impacted_objects) {
          if (["section", "stop_point", "stop_area"].includes(object.type)) {
            if ((object.line && object.line === currentLine.id) || !object.line) {
              object.severity = disruption.severity
              impactedObjects.push(object)
            }
          }
        }
      }
    }

    // Loop through each impacted object and display it on map
    const markers = []
    const impactedMarkers = []

    for (const impacted of impactedObjects) {
      switch (impacted.type) {
        case "section":
          const route = currentLine.routes.find(r => r.direction_id === currentLine.direction_id)
          const from = reduxMarkers.find(marker => {
            if (impacted.from.includes("stop_area")) {
              return marker.props.stop.stop_area === impacted.from && impacted.routes.find(r => r.id === route.route_id)
            } else {
              return marker.props.stop.id === impacted.from && impacted.routes.find(r => r.id === route.route_id)
            }
          })
          const to = reduxMarkers.find(marker => {
            if (impacted.from.includes("stop_area")) {
              return marker.props.stop.stop_area === impacted.to && impacted.routes.find(r => r.id === route.route_id)
            } else {
              return marker.props.stop.id === impacted.to && impacted.routes.find(r => r.id === route.route_id)
            }
          })

          if (from && to) {
            // Pass the severity
            from.props.stop.severity = to.props.stop.severity = impacted.severity
            
            // Add all markers between the "from" and the "to"
            impactedMarkers.push(...reduxMarkers.slice(reduxMarkers.indexOf(from), reduxMarkers.indexOf(to) + 1))
          }
          
          break
        case "stop_area":
        case "stop_point":
          const stop = reduxMarkers.find(marker => marker.props.stop[impacted.type === "stop_area" ? "stop_area" : "id"] === impacted.id)

          if (stop) {
            stop.props.stop.severity = impacted.severity
            impactedMarkers.push(stop)
          }
          break
        default:
          console.warn(impacted.type + " is not handled yet")
      }

      for (const impactedMarker of impactedMarkers) {
        // Populate with retrieved markers
        impactedMarker && !markers.find(m => m.props.stop.id === impactedMarker.props.stop.id) && markers.push(
          appStore.dispatch(
            actionBuildMarker(impactedMarker.props.stop, {
              key: currentLine.code + "_" + impactedMarker.props.stop.index + "_disrupted",
              icon: new L.DivIcon({
                className: `circle-icon-marker ${impactedMarker.props.stop.terminus ? " stop-terminus" : ""}`,
                iconSize: impactedMarker.props.stop.terminus ? [10, 10] : [8, 8],
                iconAnchor: new L.Point(4, 4),
                html: `<div><span style="border: 2px solid #${currentLine.color}; background: ${impactedMarker.props.stop.severity === "blocking" ? 'red' : 'orange'};" /><div class=${impactedMarker.props.stop.severity === "blocking" ? REACT_APP_SHOW_STOP_BLOCKING_DISRUPTIONS : ''} style="border-color: #${currentLine.color}"></div></div>`
              }),
              stop: impactedMarker.props.stop,
              zIndexOffset: 250
            })
          )
        )
      }
    }

    // If we have open a stop, search if it's in impacted list to open with disruption's error
    if (stop) {
      const disrupted = impactedMarkers.find(m => m.props.stop.id === stop)
      // Dispatch the open marker action
      disrupted && setTimeout(() => appStore.dispatch(actionOpenMarker(disrupted.props.stop)))
    }

    appStore.dispatch(actionSetReduxMarkers(reduxMarkers.concat(markers)))
  }
}

/**
 * 
 * @param envVar 
 */
export const envVarToBool = envVar => {
  if (envVar) {
    return envVar === 'true'
  } else {
    return false
  }
}

/**
 * Flatten the given object
 * @param object
 * @returns Array
 */
export const flattenObject = object => {
  const acc = []

  for (const item of Object.keys(object)) {
    const items = Array.isArray(object[item]) ? object[item] : Object.keys(
      object[item]).reduce((acc, subItem) => {
      acc.push(...object[item][subItem])
      return acc
    }, [])

    acc.push(...items)
  }

  return acc
}

/**
 *
 * @param data
 * @param reduxMarkers
 * @returns {*}
 */
export const getRef = (data, reduxMarkers) => {
  // If we have the ref on the data (like in stop_areas or stop_points
  // without line selected) grab it, else, retrieve it from the
  // reduxMarkers from the map
  const marker = data.ref ? data : reduxMarkers.find(
    m => {
      if (m.props.area) {
        if (data.id.includes('stop_area')) {
          return m.props.area.id === data.id
        } else {
          return m.props.area.id === data.stop_area
        }
      } else {
        if (data.id.includes('stop_area')) {
          return m.props.stop.stop_area === data.id
        } else {
          return m.props.stop.id === data.id
        }
      }
    })

  if (!marker) {
    return
  }

  return (marker.props ? (marker.props.area || marker.props.stop) : marker).ref
}

/**
 * Retrieve all line data for the given one
 * @param lines
 * @param line
 * @returns Object
 */
export const getLine = (lines, line) => {
  return {
    ...lines.find(({ id, code, network }) => line.id ? id === line.id : (code === line.code && network === line.network)),
    ...line
  }
}

/**
 * Convert URL search params to object
 * @param url
 */
export const getURLSearchParams = url => {
  const search = new URLSearchParams(url.search)
  const params = {}

  for (const entry of search.entries()) {
    params[entry.shift()] = decodeURIComponent(entry.shift())
  }

  return params
}

export const humanReadableOpeningHours = (opening, language, languageFile) => {
  const openingTable = opening.split(";")

  return <div className='opening-hours'>
    <div className='opening-hours-title'>{languageFile['open-hour-title']}</div>
    <div className='opening-hours-list'>{openingTable.map(rule => {
      switch (rule) {
        case "24/7":
          return <span key={rule}>{languageFile['open-hour-24/7']}</span>
        default:
          return <span key={rule}>{replaceAllDays(rule, language, languageFile)}</span>
      }
    })}</div>
  </div>
}

const replaceAllDays = (string, language, languageFile) => {
  const days = {
    'Mo': languageFile['open-hour-mo'],
    'Tu': languageFile['open-hour-tu'],
    'We': languageFile['open-hour-we'],
    'Th': languageFile['open-hour-th'],
    'Fr': languageFile['open-hour-fr'],
    'Sa': languageFile['open-hour-sa'],
    'Su': languageFile['open-hour-su'],
    'PH': languageFile['open-hour-ph'],
    'SH': languageFile['open-hour-sh']
  }

  let stringReplace = string
  // remove first blank char
  if (stringReplace.charAt(0) === ' ') {
    stringReplace = stringReplace.substr(1)
  }

  // replace days
  for (const day of Object.keys(days)) {
    stringReplace = stringReplace.replace(day, days[day])
  }

  // add colon after day
  if (!string.includes('SH') && !string.includes('PH')) {
    stringReplace = stringReplace.replace(' ', language === 'en' ? ': ' : ' : ')
  }

  // replace - between days
  if (stringReplace.split(": ")[0].includes('-')) {
    stringReplace = stringReplace.replace('-', ` ${languageFile['open-hour-days-separator']} `)
  }

  // add space between hours
  stringReplace = stringReplace.replace(/-/g, ' - ')

  return stringReplace
}

/**
 * Try if we are on the JD app
 * @param type 
 * @param variant 
 */
export const isJDApp = (type, variant) => type === 'tcl' && variant === '/jd'

export const isVariant = () => {
  const { pathname } = history.location

  let variants = JSON.parse(REACT_APP_VARIANTS)

  const variant = variants.find(v => {
    if (pathname.includes(v)) {
      return v
    }
  
    return null
  })

  return variant
}

/**
 * Test if we are on a thematics module
 */
export const isThematics = () => {
  const pathname = history.location.pathname
  return (
    pathname.includes("/pt-vente") ||
    pathname.includes("/e-tecely") ||
    pathname.includes("/gab") ||
    pathname.includes("/p+r") ||
    pathname.includes("/agence") ||
    pathname.includes("/pt-service") ||
    pathname.includes("/park-ride") ||
    pathname.includes("/bike") ||
    pathname.includes("/autosharing")
  )
}

/**
 *  Detect if journey is on an other day
 * @param {*} journey 
 * @param {*} languageFile 
 * @param {Boolean} roadmap Message is displayed on roadmap
 */
export const itineraryOnOtherDay = (journey, languageFile, roadmap = false) => {
  if (moment(journey.requested_date_time.split("T")[0]).diff(
    moment(journey.departure_date_time.split("T")[0]),
    "days"
  ) < 0) {
    return <div className={"journey-warning itinerary" + (roadmap ? ' in-roadmap' : '')}>
    <div className="icon" />
    {languageFile['route-calculation-departure-next-day']} {moment(journey.departure_date_time).format('D MMMM')}
  </div>
  }
}

// returns the most important groups
export const mostImportantGroup = (groups, modes) => {
  for (const mode of modes) {
    if (Object.keys(groups).includes(mode.name)) {
      return mode.name
    }
  }

  return null
}

/**
 * Add resize event and return a function to remove it
 * @returns {function(): void}
 */
export const addResizeEvent = isMobile => {
  const resizeListener = () => {
    resize(isMobile)
  }

  window.addEventListener('resize', resizeListener)
  return () => window.removeEventListener('resize', resizeListener)
}

/**
 * Resize the fckin panel
 */
export const resize = (isMobile, div) => {
  const { boardMarginTop } = exportedStyles
  let height = (window.innerHeight - (isMobile ? parseInt(boardMarginTop) : 25) - 20)
  const toBeResized = div || document.querySelector('.scroll')

  if (!toBeResized) {
    return
  }

  // Divs height to remove
  const appHeader = document.querySelector('.app-header')
  const header = document.querySelector('.board-header')
  const line = document.querySelector('.active-line .line-header')
  const routeCalculation = document.querySelector('.go-to-route')
  const stop = document.querySelector('.timetable-stop')
  const error = document.querySelector('.error')
  const form = document.querySelector('.form')
  const roadmap = document.querySelector('.roadmap')
  const journeys = document.querySelector('.journeys')
  const journeyWarning = document.querySelector('.journey-warning')
  const stops = document.querySelector('.stops')
  const tab = document.querySelector('.tab')
  const groupSelected = document.querySelector('.group.selected')
  const place = document.querySelector('[class*=laceSelected]') // NOT A TYPO : placeSelected OR hikingPlaceSelected
  const print = document.querySelector('.printLink')
  const calendar = document.querySelector('.click-outside')
  const timetableButtons = document.querySelector('.timetableHead')
  const timetableHour = document.querySelector('.timetableBodyHoursNoPrint')

  // For each div, substract it's height from the window's one
  for (const div of [
    appHeader,
    header,
    line,
    routeCalculation,
    stop,
    error,
    form,
    roadmap,
    journeys,
    journeyWarning,
    stops,
    tab,
    groupSelected,
    place,
    print,
    calendar,
    timetableButtons,
    timetableHour]) {
    if (!div) {
      continue
    }

    const path = window.location.pathname

    if (path.includes('route-calculation') && div.classList.contains('form')) {
      height -= 10
      continue
    } else if (div.classList.contains('roadmap')) {
      height -= 40
      continue
    } else if (div.classList.contains('journeys')) {
      height -= 15
      continue
    } else if (div.classList.contains('stops')) {
      height -= 30
      continue
    } else if (div.classList.contains('go-to-route') && div.classList.contains('around')) {
      height -= 15
      continue
    } else if (div.classList.contains('timetableHead')) {
      height -= 25
    } 
    
    height -= div.offsetHeight
  }

  toBeResized.style.maxHeight = height + 'px'
}

/**
 * Sort an array by a porperty and alphabetic order
 * @param array
 * @param property
 */
export const sortAlphabetic = (array, property) => {
  array.sort((a, b) => a[property].localeCompare(b[property]))
}

/**
 * Update the position of a popup, to be right near the marker icon
 * @param leafletElement
 */
export const updatePopupPosition = leafletElement => {
  // Retrieve the popup element & update its position
  const icon = leafletElement.getElement()

  // TODO Why there is a posibility to get icon null ???
  if (!icon) {
    return
  }

  const popup = leafletElement.getPopup()
  const element = popup.getElement()
  popup.options.offset = new L.Point(
    element.offsetWidth / 2 + icon.offsetWidth / 2 + 17,
    element.offsetHeight - ((leafletElement.options.stop && REACT_APP_TYPE === 'tcl') || leafletElement.options.area ? 18 : 2.5)) // TCL Spec : push the popup to the top if it's a stop_point (because of the icon shape)
  popup.update()
}

/**
 * Limit api call
 * @param func
 * @param wait
 * @param immediate
 * @returns {Function}
 */
export const debounce = (func, wait, immediate) => {
  let timeout

  return function () {
    const later = () => {
      timeout = null

      if (!immediate) {
        func.apply(this, arguments)
      }
    }

    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)

    if (callNow) {
      func.apply(this, arguments)
    }
  }
}

/**
 * Launch the request debounced
 */
const debounceRequest = debounce((inputValue, type, state) => {
  const { pathname } = history.location
  const { languageFile, token, component, variant, placesRef } = state.app
  const { lines } = component.props

  let fileForThematics = null
  if (component.props && component.props.thematicPlaces) {
    fileForThematics = component.props.moduleData.file
  }

  if (inputValue.length === 0) {
    initInputAddresses(token, languageFile, component, variant)
  } else {
    const params = {
      params: {
        type,
        query: inputValue,
        file: fileForThematics
      }
    }
    if (isJDApp(REACT_APP_TYPE, variant)) {
      params.params['isJDApp'] = true
    }
    axios.get('/api/autocomplete',
      params).then(result => {
      const geolocInput = [
        {
          id: 'geoloc',
          name: languageFile['autocomplete-geoloc'],
          geolocation: true
        }
      ]

      // TODO Finish it for modules
      let autocompleteResults = result.data
      if (isJDApp(REACT_APP_TYPE, variant)) {
        const resultForJD = []
        const validPlacesKeys = placesRef.find(p => p.name === 'jd-places')

        for (const r of result.data) {
          if (r.embedded_type === 'poi') {
            if (validPlacesKeys.places && validPlacesKeys.places.includes(r.poi.poi_type.id)) {
              resultForJD.push(r)
            }
          } else if (r.embedded_type === 'stop_area' || r.id.includes('stop_area:')) {
            const stopArea = state.app.areas.find(a => a.id === r.id)
            if (stopArea.lines.filter(l => l.id.includes('line:tcl:J')).length > 0) {
              resultForJD.push(r)
            }
          } else if (r.id.includes('line:')) {
            if (r.id.includes('line:tcl:J')) {
              resultForJD.push(r)
            }
          } else {
            resultForJD.push(r)
          }
        }

        autocompleteResults = resultForJD
      } else if (REACT_APP_NETWORK_RESTRICT && variant) {
        const resultForNetwork = []

        for (const r of result.data) {
          if (r.embedded_type === 'stop_area' || r.id.includes('stop_area:')) {
            const stopArea = state.app.areas.find(a => a.id === r.id)

            if (stopArea) {
              resultForNetwork.push(r)
            }
          } else if (r.id.includes('line:')) {
            if (lines.find(l => l.id === r.id)) {
              resultForNetwork.push(r)
            }
          } else {
            resultForNetwork.push(r)
          }
        }

        autocompleteResults = resultForNetwork
      } else {
        const resultWithoutJD = []

        for (const r of result.data) {
          if (r.embedded_type === 'stop_area' || r.id.includes('stop_area:')) {
            const stopArea = state.app.areas.find(a => a.id === r.id)
            if (stopArea && stopArea.lines.filter(l => l.id.includes('line:tcl:J')).length !== stopArea.lines.length) {
              resultWithoutJD.push(r)
            }
          } else if (r.id.includes('line:')) {
            if (!r.id.includes('line:tcl:J')) {
              resultWithoutJD.push(r)
            }
          } else {
            resultWithoutJD.push(r)
          }
        }

        autocompleteResults = resultWithoutJD
      }

      let resultToDispatch = envVarToBool(REACT_APP_AUTOCOMPLETE_GEOLOCATION) ? geolocInput.concat(autocompleteResults) : autocompleteResults

      if (pathname.includes('/places-interest') || pathname.includes('/lines') || state.board.thematicPlaces ||
        pathname.includes('/towns')) {
        resultToDispatch = resultToDispatch.filter(a => a.id !== 'geoloc')
      }

      if (!pathname.includes('/route-calculation')) {
        appStore.dispatch(actionInputItemsChange(resultToDispatch))
      } else {
        if (type === 'inputStart') {
          appStore.dispatch(actionInputStartItemsChange(resultToDispatch))
        } else {
          appStore.dispatch(actionInputEndItemsChange(resultToDispatch))
        }
      }
    }).catch(e => {
      const error = e.response && e.response.data ? e.response.data.id : e
      console.warn(error)
    })
  }
}, 500)

/**
 * Display the disruptions datetime well formatted
 * @param {String} begin Navitia datetime of the disruption's beginning
 * @param {String} end Navitia datatime of the disruption's end
 */
export const disruptionsDatetime = (begin, end, language) => {
  const today = moment().format('YYYYMMDD')
  const beginDate = begin.split('T').shift()
  const endDate = end.split('T').shift()

  const beginMomentDate = moment(begin).format(isSystemUS(language) ? 'MM/DD/YYYY' : 'DD/MM/YYYY')
  const endMomentDate = moment(end).format(isSystemUS(language) ? 'MM/DD/YYYY' : 'DD/MM/YYYY')
  const beginMomentHours = moment(begin).format(isSystemUS(language) ? 'hh:mm a' : 'HH:mm')
  const endMomentHours = moment(end).format(isSystemUS(language) ? 'hh:mm a' : 'HH:mm')

  if (beginDate === today && endDate === today) {
    switch (language) {
      case 'en':
        return "Today from " + beginMomentHours + " to " + endMomentHours
      default:
        return "Aujourd'hui de " + beginMomentHours + " à " + endMomentHours 
    }
  } else if (beginDate === today) {
    switch (language) {
      case 'en':
        return "Today at " + beginMomentHours + " to " + endMomentDate + " at " + endMomentHours
      default:
        return "Aujourd'hui à " + beginMomentHours + " au " + endMomentDate + " à " + endMomentHours
    }
  } else if (endDate === today) {
    switch (language) {
      case 'en':
        return "From " + beginMomentDate + " at " + beginMomentHours + " until today at " + endMomentHours
      default:
        return "Du " + beginMomentDate + " à " + beginMomentHours + " jusqu'à aujourd'hui à " + endMomentHours
    }    
  } else if (beginDate === endDate) {
    switch (language) {
      case 'en':
        return beginMomentDate + " from " + beginMomentHours + " to " + endMomentHours
      default:
        return "Le " + beginMomentDate + " de " + beginMomentHours + " à " + endMomentHours
    }
  } else {
    switch (language) {
      case 'en':
        return "From " + beginMomentDate + " at " + beginMomentHours + " to " + endMomentDate + " at " + endMomentHours
      default:
        return "Du " + beginMomentDate + " à " + beginMomentHours + " au " + endMomentDate + " à " + endMomentHours
    }
  }
}

/**
 * Init input for autocomplete with geoloc object and history
 */
export const initInputAddresses = async (token, languageFile, component, variant, lines = null) => {
  const { pathname } = history.location
  const params = getURLSearchParams(history.location)
  const geolocInput = [
    {
      id: 'geoloc',
      name: languageFile['autocomplete-geoloc'],
      geolocation: true
    }
  ]

  const historyRecovered = storageAvailable('localStorage')
    ? isJDApp(REACT_APP_TYPE, variant)
      ? (JSON.parse(window.localStorage.getItem(`history_${REACT_APP_TYPE}_jd`)) || [])
      : (JSON.parse(window.localStorage.getItem(`history_${REACT_APP_TYPE}`)) || [])
    : []

  const historyStored = []

  for (const historicItem of historyRecovered) {
    const type = historicItem.type
    switch (type) {
      case 'line':
      case 'poi':
      case 'stop_area':
        const file = type === 'line' ? 'lines' : type === 'poi' ? 'places' : 'areas'
        const findItem = component.props[file].find(i => i.id === historicItem.item_id)
        if (findItem) {
          historyStored.push(historicItem)
        }
        break
      default:
        historyStored.push(historicItem)
    }
  }
  
  const favoritesNotOrder = []
  token && await axios({
    url: '/api/favorites',
    params: {
      token
    }
  }).then(result => {
    for (const favorite of result.data.data) {
      const id = favorite.code[0].value
      const type = id.includes('line:') ? 'line' : id.includes('poi:') ? 'poi' : id.includes('stop')
        ? 'stop_area'
        : 'address'
      const name = favorite.name[0].value
      const favoriteToAdd = {
        id: 'favorite-' + id,
        type: type,
        name: name,
        item_id: id,
        code: (type === 'line' && lines) ? lines.find(l => l.id === id).code : null,
        favorite: true
      }

      if (type === 'line') {
        if (isJDApp(REACT_APP_TYPE, variant) && id.includes('line:tcl:J')) {
          favoritesNotOrder.push(favoriteToAdd)
        } else if (!isJDApp(REACT_APP_TYPE, variant) && !id.includes('line:tcl:J')) {
          favoritesNotOrder.push(favoriteToAdd)
        }
      } else if (type === 'stop_area') {
        // TODO STOPS FAVORTIES JD
        favoritesNotOrder.push(favoriteToAdd)
      } else {
        favoritesNotOrder.push(favoriteToAdd)
      }
    }
  })
    .catch(e => {
      console.warn('can\'t get favorites')
    })

  const favorites = []
  favorites.push(...favoritesNotOrder.filter(f => f.type === 'address'))
  favorites.push(...favoritesNotOrder.filter(f => f.type.includes('stop')))
  favorites.push(...favoritesNotOrder.filter(f => f.type === 'line'))
  favorites.push(...favoritesNotOrder.filter(f => f.type === 'poi'))

  const init = []
  if (pathname.includes('/lines')) {
    init.push(...historyStored.filter(h => (REACT_APP_TYPE !== 'sncf-ter' && h.type === 'stop_area') || h.type === 'line'))
    init.push(...favorites.filter(f => f.type === 'stop_area' || f.type === 'line'))
  } else if (pathname.includes('/around')) {
    envVarToBool(REACT_APP_AUTOCOMPLETE_GEOLOCATION) && init.push(...geolocInput)  
    init.push(...historyStored)
    init.push(...favorites)
  } else if (pathname.includes('/route-calculation')) {
    envVarToBool(REACT_APP_AUTOCOMPLETE_GEOLOCATION) && init.push(...geolocInput)
    init.push(...historyStored.filter(h => h.type !== 'line'))
    init.push(...favorites.filter(f => f.type !== 'line'))
  } else if (pathname.includes('/station')) {
    init.push(...historyStored.filter(h => h.type === 'stop_area'))
  }

  if (!pathname.includes('/route-calculation')) {
    appStore.dispatch(actionInputItemsChange(init))
  } else {
    if (!params.from) {
      appStore.dispatch(actionInputStartItemsChange(init))
    }
    if (!params.to) {
      appStore.dispatch(actionInputEndItemsChange(init))
    }
  }
}

export const focusInput = (e, inputProps, state) => {
  const input = e.target

  // Remove previous geolocation errors
  appStore.dispatch(actionSetGeolocationError(null))

  if (state.app.isMobile) {
    input.blur()
    appStore.dispatch(batch(initModal(inputProps), toggleModal()))
  }
}

/**
 * While user using an autocomplete input :
 * remove the pin // TODO
 * display chars in input
 * launch debounce function
 * @param event
 * @param type
 * @param state
 */
export const onChangeAutocompleteInput = (event, type, state) => {
  const inputValue = event.target.value
  const { pathname } = history.location

  appStore.dispatch(actionGoToValid(false))
  // TODO remove marker around/start/end

  if (pathname.includes('/route-calculation')) {
    const params = getURLSearchParams(history.location)

    if (type === 'inputStart') {
      if (inputValue.length === 0 && params.from) {
        if (params.to) {
          history.push({
            pathname,
            search: '?to=' + params.to
          })
        } else {
          history.push({
            pathname
          })
        }
      } else {
        appStore.dispatch(actionInputStartValueChange({name: inputValue}))
      }
    } else {
      if (inputValue.length === 0 && params.to) {
        if (params.from) {
          history.push({
            pathname,
            search: '?from=' + params.from
          })
        } else {
          history.push({
            pathname
          })
        }
      } else {
        appStore.dispatch(actionInputEndValueChange({name: inputValue}))
      }
    }
  } else {
    appStore.dispatch(actionInputValueChange(inputValue))
  }
  debounceRequest(inputValue, type, state)
}

/**
 * Return position geolocated
 * @param options
 * @returns {Promise<any>}
 */
const getCurrentPosition = (options = {}) => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  })
}

/**
 * Get the item selected by the user and adapt input about it
 * @param valueSelected
 * @param itemSelected
 * @param type
 * @param state
 * @param isModal
 */
export const onSelectAutocompleteValue = async (valueSelected, itemSelected, type, state, isModal) => {
  const { component, lines, stops, areas, touchscreenSelected, variant } = state.app
  const { pathname } = history.location
  const params = getURLSearchParams(history.location)

  // Add selected item in history
  if (!itemSelected.geolocation && !itemSelected.history && !itemSelected.favorite &&
    storageAvailable('localStorage')) {
    addHistoricItem(itemSelected, variant)
  }

  if (pathname.includes('/lines')) {
    if (itemSelected.id.includes('stop_area')) {
      component.onStopSelected(
        areas.find(a => a.id === itemSelected.item_id || a.id === itemSelected.id))
      appStore.dispatch(actionGoToValid(true))
    } else if (itemSelected.id.includes('stop_point')) {
      itemSelected.item_id = stops.filter(s => s.id === itemSelected.item_id)[0].stop_area
      component.onStopSelected(
        areas.find(a => a.id === itemSelected.item_id))
      appStore.dispatch(actionGoToValid(true))
    } else {
      component.onLineSelected(
        lines.find(l => l.id === itemSelected.item_id || l.id === itemSelected.id))
    }
  } else if (pathname.includes('/around')) {
    let searchAround = '?from='
    if (itemSelected.geolocation) {
      if (touchscreenSelected) {
        searchAround += touchscreenSelected.coords
      } else {
        try {
          const position = await getCurrentPosition({
            timeout: 3000,
            enableHighAccuracy: true
          })
  
          const { longitude, latitude } = position.coords
          searchAround += longitude + ';' + latitude
        } catch (e) {
          // Toggle the modal if we are on mobile
          isModal && appStore.dispatch(toggleModal())
          // Remove the focus of the current active element
          document.activeElement.blur()
          // Store the current geolocation error
          appStore.dispatch(actionSetGeolocationError(e))
          throw e
        }
      }
    } else if (itemSelected.history || itemSelected.favorite) {
      searchAround = !itemSelected.item_id.includes('line:') ? searchAround + itemSelected.item_id : '?line=' +
        itemSelected.item_id
    } else {
      searchAround = !itemSelected.id.includes('line:') ? searchAround + itemSelected.id : '?line=' + itemSelected.id
    }
    if (!searchAround.includes('line:') || !searchAround.includes('admin:fr')) {
      appStore.dispatch(actionGoToValid(true))
    }
    history.push({ pathname, search: searchAround })
  } else if (pathname.includes('/route-calculation')) {
    let searchRouteCalc = ''
    if (itemSelected.geolocation) {
      if (touchscreenSelected) {
        searchRouteCalc += touchscreenSelected.coords
      } else {
        try {
          const position = await getCurrentPosition({
            timeout: 3000,
            enableHighAccuracy: true
          })
  
          const { longitude, latitude } = position.coords
          searchRouteCalc = longitude + ';' + latitude
        } catch (e) {
          // Toggle the modal if we are on mobile
          isModal && appStore.dispatch(toggleModal())
          // Remove the focus of the current active element
          document.activeElement.blur()
          // Store the current geolocation error
          appStore.dispatch(actionSetGeolocationError(e))
          throw e
        }
      }
    } else if (itemSelected.history || itemSelected.favorite) {
      // TODO need around to get a stop_point
      searchRouteCalc = itemSelected.item_id
    } else {
      searchRouteCalc = itemSelected.id
    }

    if (type === 'inputStart') {
      if (!params.to) {
        history.push({ pathname, search: '?from=' + searchRouteCalc })
      } else {
        history.push({ pathname, search: '?from=' + searchRouteCalc + '&to=' + params.to })
      }
    } else if (type === 'inputEnd') {
      if (!params.from) {
        history.push({ pathname, search: '?to=' + searchRouteCalc })
      } else {
        history.push({ pathname, search: '?from=' + params.from + '&to=' + searchRouteCalc })
      }
    }
  } else if (state.board.thematicPlaces) {
    // TODO REVIEW
    history.push({ pathname, search: '?place=' + itemSelected.id.replace('history-', '') })
  }

  if (type === 'inputStart') {
    appStore.dispatch(actionInputStartValueChange({name: valueSelected}))
  } else if (type === 'inputEnd') {
    appStore.dispatch(actionInputEndValueChange({name: valueSelected}))
  } else {
    appStore.dispatch(actionInputValueChange(valueSelected))
  }

  // Toggle the modal if we are on mobile
  isModal && appStore.dispatch(toggleModal())
}

/**
 * add item in historic
 * @param item
 */
export const addHistoricItem = (item, variant) => {
  const historyStored = isJDApp(REACT_APP_TYPE, variant)
    ? (JSON.parse(window.localStorage.getItem(`history_${REACT_APP_TYPE}_jd`)) || [])
    : (JSON.parse(window.localStorage.getItem(`history_${REACT_APP_TYPE}`)) || [])
  historyStored.reverse()

  if (historyStored.filter(p => p.item_id === item.id).length === 0) {
    if (item.id.includes('poi:')) {
      historyStored.push({
        id: 'history-' + item.id,
        type: 'poi',
        name: item.name,
        item_id: item.id,
        history: true
      })
    } else if (item.id.includes('line:')) {
      historyStored.push({
        id: 'history-' + item.id,
        type: 'line',
        name: item.name,
        mode: item.mode,
        color: item.color,
        item_id: item.id,
        code: item.code,
        history: true
      })
    } else if (item.id.includes('stop_area:')) {
      historyStored.push({
        id: 'history-' + item.id,
        type: 'stop_area',
        name: item.name,
        item_id: item.id,
        history: true
      })
    } else if (item.address) {
      historyStored.push({
        id: 'history-' + item.id,
        type: 'address',
        name: item.name,
        item_id: item.id,
        history: true
      })
    } else if (item.id.includes('admin:')) {
      historyStored.push({
        id: 'history-' + item.id,
        type: 'administrative_region',
        name: item.name,
        item_id: item.id,
        coord: item.administrative_region.coord,
        history: true
      })
    }
  }

  historyStored.reverse()
  if (historyStored.length > 3) {
    historyStored.length = 3
  }

  if (isJDApp(REACT_APP_TYPE, variant)) {
    window.localStorage.setItem(`history_${REACT_APP_TYPE}_jd`, JSON.stringify(historyStored))
  } else {
    window.localStorage.setItem(`history_${REACT_APP_TYPE}`, JSON.stringify(historyStored))
  }
}

export const goToRouteCalculation = item => {
  appStore.dispatch(actionSetTransportPlaces([]))
  appStore.dispatch(actionSetPublicPlaces([]))

  setTimeout(() => {
    const url = `/route-calculation?to=${
      item.cat_id === "poi_type:stations"
        ? "sncf_" + item.id
        : item instanceof BikeInterface
        ? item.coord.lon + ";" + item.coord.lat
        : item.id
        ? item.id
        : item.address
        ? item.address.lon + ";" + item.address.lat
        : item
    }`
    history.push(url)
  })
}

/**
 * Remove duplicates entries of an Array by a specifiq property
 * @param array
 * @param property
 * @returns Array
 */
export const unique = (array, property) => array.filter(
  (e, i) => array.findIndex(a => a[property] === e[property]) === i
)

/**
 * Get data from poi selection in list
 * @param place
 * @param token
 * @returns {Promise<void>}
 */
export const clickOnPlaceInList = async (place, token, pois = null, thematic = null) => {
  const needRequest = [
    'poi_type:amenity:bicycle_rental',
    'poi_type:amenity:bicycle_parking',
    'poi_type:amenity:parking',
    'poi_type:stations'
  ]

  appStore.dispatch(actionResetStationIndex())
  appStore.dispatch(actionSetPlaceClicked(null))

  if (place && needRequest.includes(place.cat_id)) {
    if (place.cat_id !== 'poi_type:stations') {
      const type = place.cat_id.includes('bicycle_rental') ? 'bss' : place.cat_id.includes('bicycle_parking') ? 'bike_parking' : 'parking'
      axios.get(
        `/api/availability?type=${type}&id=${place.id}`)
        .then(result => {
          place.stand = result.data
        })
        .catch(e => {
          place.stand = {}
          const error = e.response && e.response.data ? e.response.data.id : e
          console.warn(error)
        }).finally(() => {
          appStore.dispatch(actionSetPlaceClicked(place))
          appStore.dispatch(actionBuildTransportPlaces(pois))
        })
    } else {
      axios.get(
        `/api/stations?id=${place.id}`)
        .then(result => {
          place.stand = result.data
          appStore.dispatch(actionSetPlaceClicked(place))
        })
        .catch(e => {
          place.stand = {}
          const error = e.response && e.response.data ? e.response.data.id : e
          console.warn(error)
        })
    }
  } else {
    // TODO Avoid duplicate with the else case
    appStore.dispatch(actionSetPlaceClicked(place))
  }

  // Check if the place is in Niji's fav
  isFavorite(place, token).then(response => {
    appStore.dispatch(actionSetFavoritePlace(response))
  })

  // Scrollto element
  setTimeout(() => {
    const scroll = document.querySelector('.scroll')
    const placeInfos = document.querySelector('.place-infos')

    // Avoid crash if there is no scroll element
    scroll && scroll.scrollTo(0, 0)

    if (placeInfos) {
      const scrollRect = scroll.getBoundingClientRect()
      const placeRect = placeInfos.getBoundingClientRect()
      scroll.scrollTo(0, placeRect.top - scrollRect.top - 25)
    }
  }, 150)

  if (thematic) {
    appStore.dispatch(actionGoToValid(true))
    appStore.dispatch(actionInputValueChange(place.name))
  }
}

/**
 * Create a google coord
 * @param lat
 * @param lng
 */
export const createCoords = (lat, lng) => {
  return [lat, lng]
}

/**
 * Return if value is real coordinates
 * @param coord
 * @returns {boolean}
 */
export const isCoords = coord => {
  const lon = coord.split(';')[0]
  const lat = coord.split(';')[1]

  return !!(!isNaN(lon) && isBetween(lon, -180, 180) && !isNaN(lat) && isBetween(lon, -90, 90))
}

/**
 * Try if a place should't be clusterised
 * @param place
 * @returns {boolean}
 */
export const isNotToClusterised = place => {
  return (place.cat_id === 'poi_type:amenity:bicycle_rental' || place.cat_id === 'poi_type:stations') || // No cluster for VELO'V OR stations
    (history.location.pathname.includes('/p+r') &&
      (place.cat_id === 'poi_type:amenity:parking' || place.cat_id === 'poi_type:amenity:bicycle_parking')) // No cluster for p+r in thematics
}

export const isNotPlacesTabAround = places => {
  return history.location.pathname.includes('/around') && places
}

/**
 *
 * @param object
 * @param type
 * @returns {Promise<*>}
 */
// TODO cf version leaflet for route-calculation
export const getCoordsFromUrlObject = async (object, type = null, component) => {
  const { languageFile } = component.props
  const { pathname } = history.location
  if (object.includes('stop_') || object.includes('poi:')) {
    const name = object.includes('stop_area') ? 'areas' : object.includes('stop_point') ? 'stops' : object.includes('sncf_stop_area') ? 'stations' : 'places'
    let findObj = component.props[name].find(i => i.id === object)

    if (!findObj && object.includes('stop_area')) {
      findObj = component.props['stations'].find(i => i.id === object)
    }

    if (findObj) {
      if (pathname.includes('/route-calculation')) {
        if (type === 'inputStart') {
          appStore.dispatch(actionInputStartValueChange(findObj))
        } else if (type === 'inputEnd') {
          appStore.dispatch(actionInputEndValueChange(findObj))
        }
        component.createMarker(createCoords(findObj.coord.lat, findObj.coord.lon), type)
      } else {
        appStore.dispatch(actionGoToValid(true))
        appStore.dispatch(actionInputValueChange(findObj.name))
        return createCoords(findObj.coord.lat, findObj.coord.lon)
      }
    } else {
      console.warn(`${object} not found`)
    }
  } else if (object.includes('admin:')) {
    const adminRegion = component.props.adminRegions.find(admin => admin.id === object)

    if (adminRegion && pathname.includes('/route-calculation')) {
      if (type === 'inputStart') {
        appStore.dispatch(actionInputStartValueChange(adminRegion))
      } else if (type === 'inputEnd') {
        appStore.dispatch(actionInputEndValueChange(adminRegion))
      }
      component.createMarker(createCoords(adminRegion.coord.lat, adminRegion.coord.lon), type)
    } else {
      console.warn(`${object} not found`)
    }
  } else {
    if (isCoords(object)) {
      const lat = object.split(';')[1]
      const lng = object.split(';')[0]
      let apiResponse = null
      await axios.get('/api/geocoding', {
        params: {
          lat: lat,
          lng: lng
        }
      }).then(response => {
        apiResponse = response
      }).catch(e => {
        console.warn('Error : ', e.response && e.response.data && e.response.data.id)
        component.setState({
          error: e.response && e.response.data.id === 'no-places'
            ? languageFile['around-error-no-places']
            : languageFile['around-error-unknow']
        })
      }).finally(() => {
        if (pathname.includes('/route-calculation')) {
          if (type === 'inputStart') {
            appStore.dispatch(actionInputStartValueChange(apiResponse ? apiResponse.data[0] : {name: ''}))
          } else if (type === 'inputEnd') {
            appStore.dispatch(actionInputEndValueChange(apiResponse ? apiResponse.data[0] : {name: ''}))
          }
          component.createMarker(createCoords(apiResponse ? apiResponse.data[0].address.coord.lat : lat, apiResponse ? apiResponse.data[0].address.coord.lon : lng),
            type)
        } else {
          appStore.dispatch(actionGoToValid(true))
          appStore.dispatch(actionInputValueChange(apiResponse ? apiResponse.data[0].name : ''))
        }
      })
      return createCoords(lat, lng)
    } else {
      appStore.dispatch(actionGoToValid(false))
      appStore.dispatch(actionInputValueChange(''))
    }
  }
}

export const tagOnShare = (network, itineraries = false) => {
  REACT_APP_GTM && updateDataLayer({
    event: itineraries ? 'map-itinerariesShare' : 'map-itineraryShare',
    socialNetwork: network
  })
}

/**
 * Define if we need to use us system
 * @param language
 * @returns {boolean}
 */
export const isSystemUS = (language) => {
  return ['en'].includes(language)
}

export const navitiaDateToHoursMin = (date, language) => {
  const time = moment(date.split('T').pop(), 'HHmmss')

  return isSystemUS(language) ? time.format('h:mm a') : time.format('HH:mm')
}

export const onTabSelected = (component, index) => {
    const { isMobile, openedCollapse, linesModes } = component.props

    if (!index && index !== 0) {
      index = component.state.tab
    }

    // Retrieve the most important group displayed and select it if it's not already done
    const group = mostImportantGroup(component.state.groups, linesModes)
    index === 0 && openedCollapse !== group && appStore.dispatch(actionSetOpenedCollapse(group))

    component.setState({ tab: index }, () => {
      appStore.dispatch(actionSetLineSelected(null))

      resize(isMobile)
    })
}

export const storageAvailable = (type) => {
  try {
    const storage = window[type]
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return false
  }
}

// --------------------------- PRIVATE --------------------------- //

/**
 * check if x is between min and max
 * @param x
 * @param min
 * @param max
 * @returns {boolean}
 */
const isBetween = (x, min, max) => x >= min && x <= max
