export const actionBuildTerritoryOutline = geojson => ({
  type: 'BUILD_TERRITORY_OUTLINE',
  geojson
})

export const actionBuildEntranceMap = geojson => ({
  type: 'BUILD_ENTRANCE_MAP',
  geojson
})

export const actionSetReduxMarkers = markers => ({
  type: 'SET_REDUX_MARKERS',
  markers
})

export const actionSetHeavyLines = lines => ({
  type: 'SET_HEAVY_LINES',
  lines
})

export const actionMarkerClick = marker => ({
  type: 'ON_MARKER_CLICK',
  marker
})

export const actionSetLineSelected = line => ({
  type: 'SET_LINE_SELECTED',
  line
})

export const actionSetLineSelectedPath = path => ({
  type: 'SET_LINE_SELECTED_PATH',
  path
})

export const actionSetTransportPlaces = places => ({
  type: 'SET_TRANSPORT_PLACES',
  places
})

export const actionSetJDSchools = schoolsJD => ({
  type: 'SET_JD_SCHOOLS',
  schoolsJD
})

export const actionSetPublicPlaces = places => ({
  type: 'SET_PUBLIC_PLACES',
  places
})

export const actionSetMapPlaces = places => ({
  type: 'SET_MAP_PLACES',
  places
})

export const actionSetCluster = cluster => ({
  type: 'SET_CLUSTER',
  cluster
})

export const actionSetEntranceMapMarkers = markers => ({
  type: 'SET_ENTRANCE_MAP_MARKERS',
  markers
})

export const actionHandleLegendState = isLegendOpen => ({
  type: 'HANDLE_LEGEND_STATE',
  isLegendOpen
})

export const actionSetBikePaths = paths => ({
  type: 'SET_BIKE_PATHS',
  paths
})

export const actionSetMapBikes = bikes => ({
  type: 'SET_MAP_BIKES',
  bikes
})

export const actionSetEntrancePopup = popup => ({
  type: 'SET_ENTRANCE_POPUP',
  popup
})