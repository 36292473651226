import { buildAutocomplete, buildPlacesByCatInList, buildRouteCalcPreferences, buildTabsPanel } from '../services/board'
import { resize } from '../services/tools'
import { actionGoToValid, actionSetTooManyFavorites } from '../actions/board'

export default () => {
  let timer = null

  return ({ dispatch, getState }) => next => action => {
    switch (action.type) {
      case 'BUILD_AUTOCOMPLETE':
        return buildAutocomplete(getState(), action.inputProps, action.isModal)

      case 'ON_INPUT_VALUE_CHANGE':
        if (action.value.length === 0) {
          dispatch(actionGoToValid(false))
        }
        break

      case 'BUILD_PLACES_BY_CAT_IN_LIST':
        setTimeout(() => resize(getState().app.isMobile))
        return buildPlacesByCatInList(getState(), action.places)

      case 'TOO_MANY_FAVORITES':
        if (action.favorite_type) {
          // Clear previous timer
          clearTimeout(timer)
          timer = setTimeout(() => dispatch(actionSetTooManyFavorites(null)), 4000)
        }
        break


      case 'BUILD_ROUTE_CALC_PREFERENCES':
        return buildRouteCalcPreferences(getState())

      case 'BUILD_TABS_PANEL':
        return buildTabsPanel(getState())

      default:
        break
    }

    return next(action)
  }
}
