export default (state = {
  reduxMarkers: [],
  isLegendOpen: false
}, action) => {
  switch (action.type) {
    case 'SET_AREAS':
      return {
        ...state,
        reactAreas: action.areas
      }

    case 'SET_STOPS':
      return {
        ...state,
        reactStops: action.stops
      }

    case 'BUILD_TERRITORY_OUTLINE':
      return {
        ...state,
        territoryOutline: action.geojson
      }

    case 'BUILD_ENTRANCE_MAP':
      return {
        ...state,
        entranceMap: action.geojson
      }

    case 'SET_REDUX_MARKERS':
      return {
        ...state,
        reduxMarkers: action.markers
      }

    case 'SET_HEAVY_LINES':
      return {
        ...state,
        heavyLines: action.lines
      }

    case 'ON_MARKER_CLICK':
      return {
        ...state,
        openedMarker: action.marker
      }

    case 'SET_LINE_SELECTED':
      return {
        ...state,
        lineSelected: action.line
      }

    case 'SET_LINE_SELECTED_PATH':
      return {
        ...state,
        selectedLine: action.path
      }

    case 'SET_TRANSPORT_PLACES':
      return {
        ...state,
        transportPlaces: action.places
      }

    case 'SET_JD_SCHOOLS':
      return {
        ...state,
        schoolsJD: action.schoolsJD
      }

    case 'SET_MAP_PLACES':
      return {
        ...state,
        mapPlaces: action.places
      }

    case 'SET_PUBLIC_PLACES':
      return {
        ...state,
        places: action.places
      }

    case 'SET_CLUSTER':
      return {
        ...state,
        cluster: action.cluster
      }

    case 'SET_ENTRANCE_MAP_MARKERS':
      return {
        ...state,
        entranceMapMarkers: action.markers
      }

    case 'HANDLE_LEGEND_STATE':
      return {
        ...state,
        isLegendOpen: !action.isLegendOpen
      }

    case 'SET_BIKE_PATHS':
      return {
        ...state,
        bikePaths: action.paths
      }
    
    case 'SET_MAP_BIKES':
      return {
        ...state,
        bikes: action.bikes
      }

    case 'SET_ENTRANCE_POPUP':
      return {
        ...state,
        entrancePopup: action.popup
      }
      
    default:
      return state
  }
}
