/**
 * Comparator to use on a sort Array function
 * Ex : array.sort(compareValues('key', 'desc'))
 * 
 * @param {String} key props to sort by
 * @param {String} order 'asc' or 'desc'
 */
export function compareValues(key, order = 'asc') {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0
    }

    const varA = (typeof a[key] === 'string')
      ? a[key].toLowerCase()
      : a[key]
    const varB = (typeof b[key] === 'string')
      ? b[key].toLowerCase()
      : b[key]

    let comparison = 0
    if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }

    return (
      (order === 'desc') ? (comparison * -1) : comparison
    )
  }
}

/**
 * Split lines in groups of modes
 * 
 * @param {Array} lines lines to group
 * @param {Array} types modes
 * @param {String} target property in line used to group
 */
export function groupLinesByMode(lines, modes, target = 'mode') {
  const groups = {}

  for (const mode of modes) {
    let linesInMode = lines.filter(l => Array.isArray(mode.modes)
      ? mode.modes.includes(l[target])
      : mode.modes === l[target])

    // We only keep groups with lines
    if (linesInMode.length > 0) {
      groups[mode.name] = linesInMode
    }
  }

  return groups
}

/**
 * Return current Board bounding client rect
 */
export function getBoardBoundingClientRect() {
  return document.querySelector('.board').getBoundingClientRect()
}