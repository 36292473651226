import TagManager from 'react-gtm-module'

const { REACT_APP_GTM } = process.env

export const initGTM = () => {
  const tagManagerArgs = {
    gtmId: REACT_APP_GTM
  }

  if (REACT_APP_GTM) {
    TagManager.initialize(tagManagerArgs)
  }
}

export const updateDataLayer = dataLayer => {
  const tagManagerArgs = {
    dataLayer
  }

  TagManager.dataLayer(tagManagerArgs)
}
