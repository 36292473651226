import axios from 'axios'
import Carousel from 'nuka-carousel'
import React from 'react'
import L from 'leaflet'
import { isJDApp, isSystemUS, resize } from '../../services/tools'

const { REACT_APP_TYPE, REACT_APP_NIGHT_LINES } = process.env

/**
 * Add content into Timetable
 * @param component
 * @returns {Array}
 */
export const addTimetableContent = (component, print) => {
  const { timetableData } = component.state
  const { language, languageFile } = component.props

  if (timetableDataIsEmpty(timetableData)) { // then returns a simple message instead of timetable
    const slides = []
    slides.push(<div key='no-schedules'>{languageFile['stop-timetable-no-schedules'] + ' ' + component.state.timetableStop}</div>)
    return slides
  }

  const possibleperiods = [
    'morning',
    'afternoon',
    'evening'
  ]

  let periods = [
    'morning',
    'afternoon',
    'evening'
  ]

  let hours = {
    morning: [
      4, 5, 6, 7, 8, 9, 10, 11
    ],
    afternoon: [
      12, 13, 14, 15, 16, 17, 18, 19
    ],
    evening: [
      20, 21, 22, 23, 0, 1, 2, 3
    ]
  }

  // Roadmap starts at 4am and ends at 3am        used to compare : 0am=24 1am=25 2am=26 3am=27
  let start = 99
  let end = 0

  const nightLines = JSON.parse(REACT_APP_NIGHT_LINES)
  const isNightline = nightLines.lines.includes(component.state.currentLine.id)
  for (const period of periods) {
    for (let time of timetableData[period]) {
      let timeHour = parseInt(time.date_time.substring(9, 11))

      if (timeHour < 4 && !isNightline) { // For exemple, a nightline can work from 1am to 4am. The start must be 1am for this line.
        timeHour += 24
      }

      if (timeHour < start) {
        start = timeHour
      }

      if (timeHour > end) {
        end = timeHour
      }
    }
  }

  const slides = []
  if (isNightline) { // redifines hours (hours will be something like [1,2,3,4])
    periods = ['morning']
    hours = {
      morning: []
    }

    for (let i = start; i <= end; i++) {
      if (i > 23) {
        hours.morning.push(i - 24)
      } else {
        hours.morning.push(i)
      }
    }
  }

  let otherdirections = [] // array of secondary destinations name
  for (const period of periods) {
    const table = []
    const tableHead = []

    for (const hour of hours[period]) {
      const colonne = []
      const newhour = (hour < 4 && !isNightline) ? hour + 24 : hour
      let printHidden = false // bool : column needs to be hidden in print ?

      if (newhour < start || newhour > end) {
        printHidden = true
      }

      let timetableNameColumnHour = 'timetableColumn' + (printHidden ? ' printHide' : '')
      if (component.props.map.props.isMobile) {
        timetableNameColumnHour += ' mobile'
      }

      const hourToDisplay = isSystemUS(language) ? (hour % 12 || 12) + (hour >= 12 ? 'pm' : 'am') : hour + 'h'
      tableHead.push(<div key={hour} className={timetableNameColumnHour}><div className='timetableHour'> {hourToDisplay}</div></div>)
      if (isNightline) { // needs to check for all periods (a nightline can have both morning and evening passages on the same slide/period)
        for (const possibleperiod of possibleperiods) {
          if (timetableData[possibleperiod]) {
            for (let timetableDataContent of timetableData[possibleperiod]) {
              processTimetableDataContent(timetableDataContent, hour, otherdirections, colonne) // push hour into column, update otherdirections
            }
          }
        }
      } else {
        const hourWithZero = hour<10 ? '0'+hour : hour.toString()
        const timetableDataContenOfThisHour = timetableData[period].filter(timetableDataItem => timetableDataItem.date_time.substring(9,11) === hourWithZero)  
        for(var i= 0; i < timetableDataContenOfThisHour.length; i++) {
          processTimetableDataContent(timetableDataContenOfThisHour[i], hour, otherdirections, colonne)
        }
      }

      let timetableNameColumn = 'timetableColumn' + (printHidden ? ' printHide' : '')
      table.push(<div className={timetableNameColumn} key={'colonne' + hour}>{colonne}</div>)
    }

    const otherDirections = getDestinations(component.state.timetableData, languageFile)
    slides.push(<div key={period} className='timetableSlide'>
      <div className={print ? 'timetableBody timetableBodyHours' : 'timetableBody timetableBodyHours timetableBodyHoursNoPrint'}>{tableHead}</div>
      <div className={print ? '' : 'scroll'}>
        <div className='timetableBody timetableBodyMinutes'>{table}</div>
        <div className={otherDirections ? 'otherDirections' : ''}>{otherDirections}</div>
      </div>
    </div>)
  }

  return slides
}

/**
 * Create a google coord
 * @param lat
 * @param lng
 */
export const createCoords = (lat, lng) => {
  return [lat, lng]
}

/**
 * Create a timetable
 * @param component
 * @returns {*}
 */
export const createTimetable = (component, print) => {
  const { timetableError, slideIndex } = component.state
  const { language, languageFile } = component.props

  if (timetableError) {
    if (timetableError.data.id === 'date_out_of_bounds') {
      return languageFile['timetable-not-show-at-date']
    } else {
      return languageFile['timetable-not-show']
    }
  }
  const nightLines = JSON.parse(REACT_APP_NIGHT_LINES)
  let multipleSlides = !((nightLines.lines.includes(component.state.currentLine.id)) || timetableDataIsEmpty(component.state.timetableData))

  // Ugly slider initial height fix
  setTimeout(() => {
    const list = document.querySelector('.slider-list')
    const currentSlide = list && list.children[slideIndex]

    if (currentSlide) {
      list.style.height = currentSlide.offsetHeight + 'px'
    }
  })

  const slide1 = isSystemUS(language) ? '< 4am - 11am' : '< 04h - 11h'
  const slide3 = isSystemUS(language) ? '8pm - 3am >' : '20h - 03h >'
  const slide12 = isSystemUS(language) ? '< 4am - 7pm' : '< 04h - 19h'
  const slide23 = isSystemUS(language) ? '12pm - 03am >' : '12h - 03h >'

  return <>
    {multipleSlides && (slideIndex === 1 ? <div className='timetableHead' style={{ justifyContent: 'space-between' }}>
      <span onClick={() => (!component.state.changingSlide) && component.setState({ changingSlide: true, slideIndex: slideIndex - 1 })}>{slide1}</span>
      <span onClick={() => (!component.state.changingSlide) && component.setState({ changingSlide: true, slideIndex: slideIndex + 1 })}>{slide3}</span>
    </div> : slideIndex === 0 ? <div className='timetableHead' style={{ justifyContent: 'flex-end' }}>
      <span onClick={() => (!component.state.changingSlide) && component.setState({ changingSlide: true, slideIndex: slideIndex + 1 })}>{slide23}</span>
    </div> : <div className='timetableHead' style={{ justifyContent: 'flex-start' }}>
      <span onClick={() => (!component.state.changingSlide) && component.setState({ changingSlide: true, slideIndex: slideIndex - 1 })}>{slide12}</span>
    </div>)}
    <Carousel
      slideIndex={slideIndex}
      heightMode={'current'}
      afterSlide={(slideIndex) => {
        component.setState({ changingSlide: false, slideIndex }, () => { resize(component.props.map.props.isMobile, Array.from(document.querySelectorAll('.scroll'))[slideIndex]) })
      }}
      renderCenterLeftControls={null}
      renderCenterRightControls={null}>
      {addTimetableContent(component, print)}
    </Carousel>
  </>
}

/**
 *
 * @returns {Promise<string|coords|{lat, lon}|Coordinates|string>}
 */
export const geolocInput = async () => {
  try {
    const position = await getCurrentPosition({
      timeout: 3000,
      enableHighAccuracy: true
    })

    const { longitude, latitude } = position.coords
    return substringCoords({
      lon: longitude,
      lat: latitude
    })
  } catch (e) {
    throw e
  }
}

/**
 * Return lines in a selected town
 * @param town
 * @returns {Promise<void>}
 */
export const getLinesInTown = async (component, town) => {
  const { linesModes, variant, placesRef } = component.props

  const response = await axios.get('/api/data-in-town?insee=' + town.insee).catch((e) => {
    const error = e.response && e.response.data ? e.response.data.id : e
    console.warn(error)
  })
  const lines = response.data.shift()
  // Retrieve transport pois
  const pois = lines.pop()

  const groups = groupLinesByMode(unique(lines, 'id'), linesModes, variant, 'mode') // Make the array unique by the lines ID and order

  const dataPlaces = response.data.shift()
  
  // If JD only keep key in places ref for them
  if (isJDApp(REACT_APP_TYPE, variant)) {
    const validKeys = placesRef.find(p => p.name === 'jd-places')
    Object.keys(dataPlaces).map((key) => {
      (validKeys.places && validKeys.places.includes(dataPlaces[key][0].cat_id)) || delete dataPlaces[key]
      return true
    })
  }
  const places = Object.keys(dataPlaces)
    .sort(function (a, b) {
      return a.localeCompare(b)
    })
    .reduce(function (sorted, key) {
      sorted[key] = dataPlaces[key]
      return sorted
    }, {})

  component.setState({
    town,
    groups,
    pois: isJDApp(REACT_APP_TYPE, variant) ? [] : pois,
    places,
    inputAroundValue: town.name,
    inputAroundGoToRoute: false
  })
}

// returns closer stopId of a point from a StopsList
export const getCloserStopIdFromStopsList = (pin, stopsList) => {
  // TODO place params for CCVE
  let currentStop = null

  for (const stop of stopsList) {
    const position = new L.LatLng(stop.coord.lat, stop.coord.lon)
    stop.distance = getDistanceBetweenMarkers(pin, position)

    if (!currentStop || stop.distance < currentStop.distance) {
      currentStop = stop
    }
  }

  return currentStop.id
}

/**
 * Return position geolocated
 * @param options
 * @returns {Promise<any>}
 */
export const getCurrentPosition = (options = {}) => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  })
}

export const getDestinations = (timetableData, languageFile) => { // returns secondary destinations from Data     if html = true, returns html
  let otherdirections = []
  let htmlArray = []
  let first = true

  if (timetableData) { // Check if morning, afternoon and evening are all empty
    for (const period in timetableData) {
      if (timetableData.hasOwnProperty(period) && timetableData[period]) {
        for (let timetableDataContent of timetableData[period]) {
          const direction = timetableDataContent.direction
          if (direction) { // If it is an other direction than the main one (without town name)
            if (otherdirections.indexOf(direction) === -1) {
              otherdirections.push(direction) // if this direction is not in otherdirections, add it
              first && htmlArray.push(<div key={direction}>{languageFile['timetable-other-destination']} : </div>)
              if (!first) {
                htmlArray[0] = <div className={'otherdirectionsContent'}>{languageFile['timetable-others-destinations']} : </div>
              }
              first = false
              htmlArray.push(<div key={direction + '_' + (Object.keys(otherdirections).length + 96)} className={'otherdirectionsContent'}><span
                className='otherdirectionsContentLetter'>{String.fromCharCode(
                  Object.keys(otherdirections).length + 96)}</span> {' : ' + direction} </div>)
            }
          }
        }
      }
    }
  }

  return htmlArray.length === 0 ? null : htmlArray
}

/**
 * Calcul distance between two points
 * @returns {number}
 * @param position
 * @param marker
 */
export const getDistanceBetweenMarkers = (position, marker) => {
  const R = 6371
  const distLat = ((position.lat - marker.lat) * Math.PI) / 180
  const distLon = ((position.lng - marker.lng) * Math.PI) / 180
  const a =
    Math.sin(distLat / 2) ** 2 +
    Math.cos((marker.lat * Math.PI) / 180) *
    Math.cos((position.lat * Math.PI) / 180) *
    Math.sin(distLon / 2) *
    Math.sin(distLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return Math.round(R * c * 1000)
}

export const getColorGroup = (modes, group) => {
  const hex = modes.find(m => m.name === group).color || null

  if (hex) {
    return { color: hex }
  } else {
    return null
  }
}

/**
 * Group lines by mode, then sort them by property
 * @param lines
 * @param property
 */
export const groupLinesByMode = (lines, linesModes, variant) => {
  const groups = {}

  for (const mode of linesModes) {
    let linesInMode = lines.filter(l => mode.modes.includes(l.mode)).sort((a, b) => {
      return a.position - b.position
    })
    // We only keep groups with lines
    if (linesInMode.length > 0) {
      groups[mode.name] = linesInMode
    }
  }

  if (!isJDApp(REACT_APP_TYPE, variant)) {
    delete groups['navitia-modes-jd']
  } else {
    for (const group in groups) {
      group.includes('navitia-modes-jd') || delete groups[group]
    }
  }

  return groups
}

/**
 * Return if value is real coordinates
 * @param coord
 * @returns {boolean}
 */
export const isCoords = coord => {
  const lon = coord.split(';')[0]
  const lat = coord.split(';')[1]

  return !!(!isNaN(lon) && isBetween(lon, -180, 180) && !isNaN(lat) && isBetween(lon, -90, 90))
}

export const processTimetableDataContent = (timetableDataContent, hour, otherdirections, colonne) => {
  const time = timetableDataContent.date_time
  const timeHour = time.substring(9, 11)
  const timeMinutes = time.substring(11, 13)
  const timetablesMinutes = [<span key={timeMinutes} className='timetableMinutesWithoutLetter'>{timeMinutes}</span>]

  if (parseInt(timeHour) === hour) {
    const direction = timetableDataContent.direction

    if (direction) {
      let index = otherdirections.indexOf(direction)

      if (index === -1) { // first appearence of this direction : adding it to the tab
        index = Object.keys(otherdirections).length
        otherdirections[index] = direction
      } else {
        index = otherdirections.indexOf(direction)
      }

      timetablesMinutes.push(<span key={timeMinutes + '_' + String.fromCharCode(index + 97)} className='timetableMinutesLetter'>{' ' +
      String.fromCharCode(index + 97)}</span>)
    }

    colonne.push(<span key={time + direction} className='timetableMinutes'>{timetablesMinutes}</span>)
  }
}

/**
 * Sort an array by a property
 * @param array
 * @param property
 * @returns Array
 */
export const sortBy = (array, property) => array.sort(
  (a, b) => +(a[property] > b[property]) || +(a[property] === b[property]) - 1)

/**
 * Sort an array by a porperty and alphabetic order
 * @param array
 * @param property
 */
export const sortAlphabetic = (array, property) => array.sort((a, b) => a[property].localeCompare(b[property]))

export const sortAlphanumericWithStartLetter = (array, property, letter) => {
  // Sort S lines
  let start = -1
  let end = -1

  // Find the start and end of S lines
  for (const line of array) {
    if (line.code.startsWith(letter) && start === -1) {
      start = array.indexOf(line)
    } else if (line.code.startsWith(letter)) {
      end = array.indexOf(line)
    }
  }

  const sLines = array.splice(start, end + 1) // +1 cause "end" slice is exclude
  const reA = /[^a-zA-Z]/g
  const reN = /[^0-9]/g

  // Sort alphanumeric
  sLines.sort((a, b) => {
    const aA = a[property].replace(reA, '')
    const bA = b[property].replace(reA, '')

    if (aA === bA) {
      const aN = parseInt(a[property].replace(reN, ''), 10)
      const bN = parseInt(b[property].replace(reN, ''), 10)
      return aN === bN ? 0 : aN > bN ? 1 : -1
    } else {
      return aA > bA ? 1 : -1
    }
  })

  // Put S lines again in our lines array
  array.splice(start, 0, ...sLines)
}

/**
 * Return coord with personalize length for url
 * @param latlng
 * @returns {string}
 */
export const substringCoords = (latlng) => {
  if (!(latlng instanceof L.LatLng)) {
    latlng = new L.LatLng(latlng.lat, latlng.lon)
  }

  return Number(latlng.lng).toFixed(4) + ';' + Number(latlng.lat).toFixed(4)
}

export const timetableDataIsEmpty = (timetableData) => {
  let timetableEmpty = true
  if (timetableData) { // Check if morning, afternoon and evening are all empty
    for (const period in timetableData) {
      if (timetableData.hasOwnProperty(period) && timetableData[period]) {
        if (timetableData[period].length > 0) {
          timetableEmpty = false
        }
      }
    }
  }
  return timetableEmpty
}

/**
 * Remove duplicates entries of an Array by a specifiq property
 * @param array
 * @param property
 * @returns Array
 */
export const unique = (array, property) => array.filter(
  (e, i) => array.findIndex(a => a[property] === e[property]) === i)

/**
 * Create an object from the url
 * @param url
 */
export const updateURLState = url => {
  const dataUrl = {}

  for (let data of url.search.substr(1).split('&')) {
    const value = data.split('=')
    dataUrl[value.shift()] = decodeURIComponent(value.shift())
  }

  return dataUrl
}

// --------------------------- PRIVATE --------------------------- //

/**
 * check if x is between min and max
 * @param x
 * @param min
 * @param max
 * @returns {boolean}
 */
const isBetween = (x, min, max) => x >= min && x <= max
