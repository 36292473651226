import axios from 'axios'
import { luminance } from 'luminance-js'
import moment from 'moment'
import 'moment/locale/fr'
import React from 'react'
import history from '../../history'
import { UnmountClosed as Collapse } from 'react-collapse'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { Circle, Marker, Polygon, Polyline, Popup, Tooltip } from 'react-leaflet'
import ReactTooltip from 'react-tooltip'
import L from 'leaflet'
import Moment from 'react-moment'
import { DateCalendar } from 'react-picker-date-and-time'
import PolylineDecorator from './PolylineDecorator'
import Tippy from '@tippy.js/react';
import {
  createTimetable,
  getColorGroup,
  groupLinesByMode,
  sortAlphabetic, sortAlphanumericWithStartLetter,
  sortBy,
  substringCoords,
  timetableDataIsEmpty,
  unique
} from './tools'
import { appStore } from '../../store'
import {
  actionBuildPlacesByCatInList, actionOnLineSelected,
  actionOpenMarker,
  actionOutMarker,
  actionOverMarker,
  actionBuildPublicPlaces,
  actionBuildTransportPlaces
} from '../../actions/withRedux'
import {
  addHistoricItem,
  getURLSearchParams,
  isJDApp, isSystemUS,
  navitiaDateToHoursMin,
  disruptionsDatetime,
  resize,
  onTabSelected,
  envVarToBool,
  storageAvailable
} from '../../services/tools'
import { actionSetLineInformation, actionSetOpenedCollapse, actionSetTooManyFavorites, actionSetPlaceClicked, actionSetDisruptionInLine } from '../../actions/board'
import {
  updateFavorite
} from '../../services/board'
import { updateDataLayer } from '../../tracking'
import domtoimage from 'dom-to-image-lc'
import jsPDF from 'jspdf'
import { saveAs } from 'file-saver'
import { detect } from 'detect-browser'
import colors from '../../scss/app.scss'

const { REACT_APP_TYPE, REACT_APP_GTM, REACT_APP_LINES_MAIN_TYPE, REACT_APP_LINES_TYPE_EXCEPTIONS, REACT_APP_MODES_LINES_STYLE, REACT_APP_TIMETABLES, REACT_APP_NEXT_SCHEDULES_RESULTS, REACT_APP_FAVORITE, REACT_APP_NIGHT_LINES, REACT_APP_SHOW_PMR, REACT_APP_DISRUPTION_COLLAPSED, REACT_APP_NETWORK_RESTRICT, REACT_APP_SHOW_ADDITIONAL_STOP_TOOL, REACT_APP_DONT_DISPLAY_STOP_AT_LINE_SELECTION, REACT_APP_DISRUPTION, REACT_APP_SHOW_STOP_BLOCKING_DISRUPTIONS } = process.env

moment.relativeTimeThreshold('m', 59)
moment.updateLocale('fr', {
  relativeTime: {
    mm: '%d min',
    m: '1 min',
    ss: '1 min',
    s: '1 min'
  }
})

const Timetable = props => {
  const component = props.component
  const { languageFile } = component.props
  const { loadingTimetable } = component.state

  return (
    <div className="timetable">
      {loadingTimetable ? (
        <img src="/assets/images/loading.gif" width={30} alt={languageFile.loading} />
      ) : (
          createTimetable(component, false)
        )}
    </div>
  );
}

/**
 * Retrieve all transport information around a position
 * @param component
 * @param position
 * @area stop_area id
 */
export const around = async (component, position, area = null) => {
  const { map, openedMarker, touchscreenSelected, linesModes, variant, placesRef, radius, languageFile } = component.props
  const { pathname } = history.location
  const params = getURLSearchParams(history.location)
  let refCircle

  try {
    const response = await axios({
      url: '/api/around',
      params: {
        lat: position[0],
        lon: position[1],
        radius: +radius,
        area: area
      }
    })

    let pinIcon = {
      iconUrl: '/assets/images/pin.svg',
      iconSize: [50, 50],
      iconAnchor: [25, 25]
    }

    const testBorne = touchscreenSelected ? [...position].reverse().join(';') : null
    if (testBorne && touchscreenSelected.coords === testBorne) {
      pinIcon = {
        iconUrl: '/assets/images/you_are_here.svg',
        iconSize: [70, 70],
        iconAnchor: [35, 70]
      }
    }

    // Pin marker
    const pin = <Marker
      draggable
      onDragstart={component.removePinCircle}
      onDragend={event => {
        history.push({ pathname, search: '?from=' + substringCoords(event.target.getLatLng()) })
      }}
      options={{ zIndex: 999 }}
      icon={L.icon(pinIcon)}
      position={position}
    />

    const circle = <Circle
      center={position}
      ref={r => (refCircle = r)}
      radius={+radius}
      fillColor='rgba(0, 0, 0)'
      fillOpacity='0.10'
      color='transparent'
    />

    // Retrieve lines
    const lines = response.data.shift()
    // Retrieve transport pois
    const pois = lines.pop()
    const groups = groupLinesByMode(unique(lines, 'id'), linesModes, variant, 'mode') // Make the array unique by the lines ID and order them by group

    const dataPlaces = response.data.shift()
    if (isJDApp(REACT_APP_TYPE, variant)) {
      const validKeys = placesRef.find(p => p.name === 'jd-places')
      Object.keys(dataPlaces).map((key) => {
        (validKeys.places && validKeys.places.includes(dataPlaces[key][0].cat_id)) || delete dataPlaces[key]
        return true
      })
    }

    const places = Object.keys(dataPlaces)
      .reduce(function (item, key) {
        item[key] = dataPlaces[key]
        return item
      }, {})

    // Google Tag Manager
    // TODO Optimize
    if (Object.keys(params).length === 1) {
      const type = params.from.includes('stop_area:') ? 'Arrêt' : params.from.includes('poi:') ? 'Lieu' : 'Adresse'
      REACT_APP_GTM && updateDataLayer({
        event: 'map-searchMap',
        searchType: type,
        searchTerm: component.props.inputValue,
        jd: isJDApp(REACT_APP_TYPE, variant)
      })
    }

    component.setState({
      groups,
      pois: isJDApp(REACT_APP_TYPE, variant) ? [] : pois,
      places
    }, () => {
      const markers = map.state.markers
      const path = window.location.pathname

      const state = {
        pin: path.includes('around') ? pin : null,
        circle,
        markers: [...markers],
        selectedInfobox: null,
        infoboxs: []
      }

      map.setState({ ...state }, async () => {
        refCircle && (!openedMarker || !params.line) && !params.stop && fitBounds(map, null, -1, refCircle.leafletElement.getBounds())
        resize(map.props.isMobile)

        // Scroll to the top of the content if we are on mobile
        if (map.props.isMobile) {
          setTimeout(() => {
            const element = document.querySelector('.board')

            element && (element.parentNode.scrollTop = element.offsetTop - element.parentNode.offsetTop)
          }, 250)
        }
      })
    })
  } catch (e) {
    console.warn('Error : ', e.response && e.response.data && e.response.data.id)
    component.setState({
      error: e.response && e.response.data.id === 'no-places'
        ? languageFile['around-error-no-places']
        : languageFile['around-error-unknow']
    })
  }
}

export const displayLineDecorator = (polyline, section, pmr) => {
  const lineLink = section.links.find(link => link.type === "line")

  let lineStyle = REACT_APP_LINES_MAIN_TYPE
  if (JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS).length && lineLink) {
    const exceptions = JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS)
    const foundExceptedLine = exceptions.find(e => e.lines.includes(lineLink.id))
    
    if (foundExceptedLine) {
      lineStyle = foundExceptedLine.type
    }
  }

  return <PolylineDecorator key={section.id + Math.random() + '_decorator'} pmr={pmr} section={section} path={polyline.props.positions} lineStyle={lineStyle} />
}

/**
 * Display polylines for a line
 * @param line
 * @param map
 * @param args
 */
export const displayLinePath = async (line, map, ...args) => {
  const { pathname, search } = history.location
  const [isSection, main, index, component] = args
  const infos = isSection ? line.display_informations : null
  // TODO waiting sections
  if (isSection && line.type === 'waiting') {
    return
  }

  try {
    if (!isSection) {
      await axios.get(`/api/file?folder=routes&ext=geojson&name=${line.code}_${line.network}_${line.direction_id}`)
        .then(result => {
          line.geojson = result.data
        }).catch((e) => {
          const error = e.response && e.response.data ? e.response.data.id : e
          console.warn(error)
          line.geojson = null
        })
    }

    // Save others polylines not from the line selected
    let oldPolylines = pathname.includes('lines') ? map.state.polylines.filter(
      oldPolyline => line.code !== oldPolyline.key.split('_').shift()) : []
    const newPolylines = []

    const lineOptions = {
      color: '#' + (infos ? infos.color : line.color),
      opacity: 1,
      weight: 6,
      zIndex: 7,
      main
    }

    // Street network or transfer, black dashed
    if (isSection && (line.type === 'street_network' || line.type === 'transfer' || line.type === 'crow_fly')) {
      lineOptions.color = '#333'
      lineOptions.opacity = 1
      lineOptions.weight = 4
      lineOptions.dashArray = '5 12'
    }

    if (isSection && !main) {
      lineOptions.color = colors.secondaryjourney
      lineOptions.opacity = 0.6
      lineOptions.zIndex = 5
      lineOptions.weight = 4
    }

    let polyline
    if (line.geojson) {
      if (!isSection) {
        let index = 0
        for (const feature of line.geojson.features) {
          let zoom = feature.properties.zoom
          const length = feature.geometry.coordinates.length
          const path = length === 1 ? getCoordinates(feature).pop() : getCoordinates(feature)

          // Push line
          polyline = <Polyline
            key={(isSection ? line.id : line.code) + (infos ? '_' + infos.code : '') + '_' + index}
            positions={path}
            zoom={zoom}
            {...lineOptions}
          />
          newPolylines.push(polyline)

          index++
        }
      } else {
        const path = getCoordinates(line.geojson).pop()

        // Push line
        polyline = <Polyline
          ref={poly => !main && poly && poly.leafletElement.bringToBack()}
          key={line.id + (infos ? '_' + infos.code : '')}
          positions={path}
          {...lineOptions}
          onMouseOver={() => { component.displayJourneys(component.state.journeys, map, index, true) }}
          onClick={() => {
            !search.includes('journey') && history.push({ pathname, search: search + '&journey=' + index })
          }}
        />

        newPolylines.push(polyline)
      }
    }

    if (isSection) {
      return newPolylines
    }

    // Concat selected lines with old lines
    oldPolylines = oldPolylines.concat(newPolylines)

    return oldPolylines
  } catch (error) {
    throw error
  }
}

/**
 * Display places in toDisplay array in base map
 * @param component
 * @param toDisplay
 */
export const displayBaseMapPlaces = (component, toDisplay) => {
  const { map } = component.props
  const markers = []

  axios.get('/api/file?name=places').then(result => {
    for (const place of result.data) {
      if (toDisplay.includes(place.cat_id.split('poi_type:')[0])) {
        const code = place.code.split('_')[0]
        const marker = {
          id: place.id,
          coord: place.coord,
          name: place.name,
          code
        }
        const markerRendered = renderMarker(component, marker, {
          icon: L.icon({
            iconUrl: '/assets/images/places/' + code + '.svg',
            iconSize: [50, 50],
            iconAnchor: [25, 18]
          }),
          marker,
          zIndex: 30
        })
        markers.push(markerRendered)
      }
    }

    map.setState({ markersMap: markers })
  }).catch((e) => {
    const error = e.response && e.response.data ? e.response.data.id : e
    console.warn(error)
  })
}

/**
 * Fit the bound of map with given objects (Polylines or Markers)
 * @param map
 * @param objects
 * @param pad // extra padding on each side
 * @param bounds
 */
export const fitBounds = (map, objects, pad = 0, bounds = L.latLngBounds()) => {
  if (objects) {
    for (const object of objects) {
      if (!object.props) {
        bounds.extend(object)
      } else if (object.props.data) { // GeoJSON
          // ! TODO need to be tested on all case posssible
          if (object.props.data.features.length > 1) {
            for (const feature of object.props.data.features) {
              if (feature.geometry.type === 'LineString') {
                feature.geometry.coordinates.map(c => bounds.extend([c[1], c[0]]))
              } else if (feature.geometry.type === 'MultiLineString') {
                for (const coords of feature.geometry.coordinates) {
                  bounds.extend(coords.map(c => [c[1], c[0]]))
                }
              }
            }
          } else {
            const trace = object.props.data.features[0].geometry
            if (trace.type === 'LineString') {
              trace.coordinates.map(c => bounds.extend([c[1], c[0]]))
            } else if (trace.type === 'MultiLineString') {
              for (const coords of trace.coordinates) {
                bounds.extend(coords.map(c => [c[1], c[0]]))
              }
            }
          }
      } else if (object.props.positions) { // Polylines or Polygon
        bounds.extend(object.props.positions)
      } else if (object.props.position) { // Marker
        bounds.extend(object.props.position)
      } else if (object.props.center) { // Circle
        bounds.extend(object.props.center)
      } else {
        console.warn('You shall not pass')
      }
    }
  }

  // ! TODO Change for not large
  let topLeft = L.point(460, pad < 0 ? 0 : 50)
  let bottomRight = L.point(0, pad < 0 ? 0 : 50)

  if (map.props.isMobile) {
    const popup = document.querySelector('.leaflet-popup')
    topLeft = L.point(popup ? -popup.offsetWidth : 0, popup ? -popup.offsetHeight / 2 : 0)
    bottomRight = L.point(0, document.querySelector('#app').scrollTop)
  }

  if (pad > 0) {
    bounds.extend(bounds.pad(pad))
  }

  // Fit the map component with the calculated bounds
  if (bounds.isValid()) {
    map.setState({
      bounds,
      boundsOptions: {
        paddingTopLeft: topLeft,
        paddingBottomRight: bottomRight,
        animate: false
      }
    })
  }
}

/**
 *
 * @param feature
 * @returns {Array}
 */
export const getCoordinates = feature => {
  const paths = []

  let type = feature.type

  if (!type || type.includes('Feature')) {
    feature = feature.geometry
    type = feature.type
  }

  switch (type) {
    case 'LineString':
      const linePath = []
      for (const coord of feature.coordinates) {
        linePath.push([coord[1], coord[0]])
      }
      paths.push(linePath)
      break

    case 'MultiLineString':
      for (const line of feature.coordinates) {
        const linePath = []
        for (const coord of line) {
          linePath.push([coord[1], coord[0]])
        }
        paths.push(linePath)
      }
      break

    default:
      console.warn('Geometry type not found')
  }

  return paths
}

/**
 * Retrieve a line object from it's id
 * @returns Object
 * @param component
 * @param object
 */
export const getLine = (component, object) => {
  const { lines } = component.props

  return {
    ...lines.find(line => line.id === object.id),
    ...object
  }
}

export const goRouteCalculation = (component, object) => {
  const { map } = component.props

  history.push(`/route-calculation?to=${object}`)
  map.setState({
    markers: [],
    markersPlaces: [],
    infoboxs: []
  })
}

/**
 * Retrieve a stop object from it's id
 * @returns Object
 * @param component
 * @param object
 * @param line
 */
export const getStop = (component, object, line) => {
  const { areas, stops } = component.props

  let res = object.id
  if (object.id.includes('stop_area')) {
    res = areas.find(a => a.id === object.id.replace('-pole-exchange', '')).lines.find(l => l.id === line.id && l.direction_id === line.direction_id).stop_id
  }

  // TODO if id is switch with stop_point instead of stop_area, look at here :D
  return {
    ...object,
    ...stops.find(s => s.id === res)
  }
}

/**
 * Invert coord
 * @param array
 * @returns {*}
 */
export const invertCoords = array => {
  return array.geometry.coordinates[0].map(coord => [coord[1], coord[0]])
}

/**
 * While user using an autocomplete input :
 * remove the pin
 * display chars in input
 * launch debounce function
 * @param e
 * @param component
 * @param input
 * @param variant
 */
// export const onChangeAutocompleteInput = (e, component, input, variant = null) => {
//   const inputValue = e.target.value
//   const map = component.props.map
//
//   component.setState({
//     [input + 'Value']: inputValue
//   }, () => {
//     if (input !== 'inputPlaces' && input !== 'inputTowns') {
//       hidePinWhileAutocomplete(component, input, map)
//     }
//
//     debounceRequest(inputValue, component, input, variant)
//   })
// }

/**
 * Select the item selected by user in autocomplete and put pin on it
 * @param dataInputSelect
 * @param component
 * @param input
 */
// export const onSelectAutocompleteInput = (dataInputSelect, component, input) => {
//   // let coord = {}
//
//   try {
//     // const type = dataInputSelect.embedded_type
//     // coord.latLng = new L.LatLng(dataInputSelect[type].coord.lat, dataInputSelect[type].coord.lon)
//     component.setState({
//       [input + 'Value']: dataInputSelect.label || dataInputSelect.name,
//       [input + 'Data']: component.state[input + 'Data'].filter(
//         address => address.id === 'geoloc' || address.id === 'favorite')
//     })
//   } catch (e) {
//     console.warn(e.message)
//     component.setState({
//       [input + 'Value']: '',
//       [input + 'Data']: []
//     })
//   }
// }

/**
 * Remove the current line from a given component
 * @param component
 * @param line
 * @param lineList
 */
export const removeLine = (component, line = null, lineList = false) => {
  const { map } = component.props
  const { pathname, search } = history.location
  const params = getURLSearchParams(history.location)

  map.setState({
    terminus: false,
    infoboxsTerminus: [],
    polylineDecorators: []
  })

  if (!line) {
    map.setState({
      selectedInfobox: null,
      infoboxs: [],
      polylines: []
    })

    component.setState({
      currentLine: null
    })
  } else {
    if (pathname !== '/lines') {
      history.push({ pathname, search: search.replace('&line=' + params.line, '') })
    } else {
      const { currentLine, selectedLines } = component.state

      if (params.current === line.id + '_' + line.direction_id) {
        if (params.selected) {
          const newSelectedLines = selectedLines.filter(selectLine => selectLine.id !== line.id)
          const newCurrent = newSelectedLines[0]

          const current = newCurrent.id + '_' + newCurrent.direction_id
          let selection = ''
          for (const stateLine of newSelectedLines) {
            if (stateLine.id !== newCurrent.id || lineList) {
              selection += stateLine.id + '_' + stateLine.direction_id + ','
            }
          }

          if (lineList) {
            history.push({ pathname, search: '?selected=' + selection })
          } else {
            history.push({ pathname, search: '?current=' + current + '&selected=' + selection })
          }
        } else {
          history.push({ pathname })
        }
      } else {
        let current = null

        if (currentLine) {
          current = currentLine.id + '_' + currentLine.direction_id
        }

        let selection = current ? '&selected=' : '?selected='
        const linesSelectedToKeep = selectedLines.filter(selectLine => selectLine.id !== line.id)

        if ((linesSelectedToKeep.length === 1 && current) ||
          linesSelectedToKeep.length === 0) {
          selection = ''
        } else {
          for (const lineToKeep of linesSelectedToKeep) {
            if (current) {
              if (lineToKeep.id !== currentLine.id) {
                selection += lineToKeep.id + '_' + lineToKeep.direction_id + ','
              }
            } else {
              selection += lineToKeep.id + '_' + lineToKeep.direction_id + ','
            }
          }
        }

        if (!lineList && current) {
          history.push({ pathname, search: '?current=' + current + selection })
        } else {
          history.push({ pathname, search: selection })
        }
      }
    }
  }
}

/**
 * Remove map state event
 * @param map
 */
export const removeMapEvents = map => {
  if (!map) {
    return
  }

  map.setState({ events: {} })
}

/**
 * Render an infobox on a specific marker
 * If onLineSelected, the infobox will stay open onClick
 * @param component   used in board
 * @param stop
 * @param selected
 */
// TODO bug fitbounds
export const renderInfobox = (component, stop, selected) => {
  const { languageFile } = component.props

  // Get lines by stop_area
  const area = stop.id.includes('stop_area') ? stop : getStop(component, { id: stop.id })

  // Display tooltip
  if (selected) {
    stop.ref && (stop.ref.clicked = true)

    /* if (component.state.markerRef && component.state.markerRef !== stop.ref) {
      component.state.markerRef.leafletElement.setTooltipContent(component.state.selectedMarker.name)
    } */

    component.setState({
      selectedMarker: stop
    })
  }

  return <div className='infobox no-arrow'>
    <div className='infobox-title'>
      <div
        style={{
          display: 'flex',
          alignItems: 'center'
        }}>
        {stop.name}
      </div>
      <>
        {REACT_APP_SHOW_PMR !== 'none' && stop.pmr ? REACT_APP_SHOW_PMR === 'pmr' && <div className='is-pmr'/> : REACT_APP_SHOW_PMR === 'no-pmr' && <div className='is-no-pmr'/>}
        {REACT_APP_SHOW_ADDITIONAL_STOP_TOOL && JSON.parse(REACT_APP_SHOW_ADDITIONAL_STOP_TOOL).map(tool => {
          if (stop[tool]) {
            return <div key={`${stop.id}_${tool}`} className={`is-${tool}`}/>
          } else {
            return false
          }
        })}
        {selected && !stop.id.includes('TAD') && <Tippy theme={'latitude'} touch={['hold', 500]} placement={'right'} boundary="window" content={languageFile['title-go-to-route-calculation']}>
            <span className='tool-route-calculation toolSmall' onClick={e => {
              e.stopPropagation()
              const coord = stop.coord.lon + ';' + stop.coord.lat
              stop.id.includes('stop') ? goRouteCalculation(component, stop.id) : goRouteCalculation(component, coord)
            }}>
              <img src='/assets/images/menu/route-calculation.svg' alt={languageFile['route-calculation-board-title']} />
            </span>
          </Tippy>}
        </>
    </div>
    {area &&
      <div className='infobox-content'>
      {stop.stand
        ? <div className='infobox-content'>
            <div className='place'>
              <div>{stop.address.label}</div>
              <div className='bss'>
                <span className='bikes'>
                  {stop.stand.available_bikes}
                  <img src='/assets/images/modes/bss.svg' alt={languageFile['bss-bikes-available']} />
                </span>
                <span className='seats'>
                  {stop.stand.available_places}
                  <img src='/assets/images/bss-seat.svg' alt={languageFile['available-places']} />
                </span>
              </div>
            </div>
          </div>
        : stop.poi_type && stop.poi_type.id === 'poi_type:amenity:parking'
          ? <div className='infobox-content'>
              <div className='place'>
                <div>{stop.address.label}</div>
              </div>
            </div>
          : renderLinesLabels(component, area.lines, 'infobox', stop)}
      </div>}
  </div>
}

/**
 * Render a line on a given coponent
 * @param component
 * @param line
 */
export const renderLine = (component, line) => {
  !line && (line = component.state.currentLine)
  const { pathname, search } = history.location
  const params = getURLSearchParams(history.location)
  const { stopsList, timetableNotes } = component.state
  const { disruptionsInLine, map, favoriteLine, favoriteStop, token, tooManyFavorites, language, languageFile, touchscreenSelected, linesModes, variant, openedCollapse, reduxMarkers } = component.props
  const { isMobile } = map.props
  const browser = detect()

  if (line.cat === 'TAD-markers-only') {
    sortBy(stopsList, 'name')
  }

  const pickerStyle = {
    calendar: {
      top: map && !map.props.isMobile ? '30px' : '',
      bottom: map && map.props.isMobile ? '28%' : '',
      left: map && map.props.isMobile ? '0' : '',
      right: map && map.props.isMobile ? '0' : '',
      boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.15)'
    },
    colon: {
      padding: '0 5px 0 0 !important'
    },
    control: {
      boxShadow: 'none',
      cursor: 'pointer'
    },
    first: '#005e86',
    menu: {
      marginLeft: -5,
      position: 'fixed',
      bottom: map && map.props.isMobile ? '25%' : '',
      top: ''
    },
    weekDays: {
      padding: '5px 0'
    },
    monthSelected: {
      fontWeight: 600
    },
    calendarButtonStyle: {
      fontSize: '.875em'
    },
    inputsHours: {
      fontSize: '1em'
    },
    today: {
      background: '#f4f4f4',
      color: '#333',
      fontWeight: '500'
    }
  }

  pickerStyle.first = colors.primarycolor
  pickerStyle.calendarButtonStyle = {
    ...pickerStyle.calendarButtonStyle,
    margin: 10,
    border: '2px solid rgba(0, 0, 0, 0.08)',
    borderRadius: 5,
    marginRight: 10
  }
  pickerStyle.calendar = {
    ...pickerStyle.calendar,
    padding: 15,
    background: '#f1f5f5'
  }
  pickerStyle.week = {
    ...pickerStyle.week,
    background: '#fff'
  }

  const regex = /(http[s]?:\/\/[^/\s]+)/g
  const matches = document.referrer.match(regex)
  const origin = matches && document.referrer.match(regex).shift()

  // Color terminus tooltip
  // TODO find a better way :-)
  document.querySelectorAll('.tooltip-leaflet-terminus').forEach(div => {
    div.style.backgroundColor = '#' + line.color
    div.style.borderColor = '#' + line.color
    div.style.color = luminance(line.color) > 0.5 ? '#333' : '#fff'
  })
  const lineMode = linesModes.find(mode => mode.modes.includes(line.mode))

  let styleLine = (REACT_APP_TYPE === 'sncf-ter' && variant === '/normandie') ? 'modeWithDirection' : REACT_APP_LINES_MAIN_TYPE
  if (JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS).length) {
    const exceptions = JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS)
    const foundExceptedLine = exceptions.find(e => e.lines.includes(line.id))
    
    if (foundExceptedLine) {
      styleLine = foundExceptedLine.type
    }
  }

  // Filter all disruptions and get only the one impacted by this route
  const disruptionsInLineForThatRoute = []
  const route = line.routes.find(r => r.direction_id === line.direction_id)
  const impacted_objects = []

  // Do we have disruptions ?
  if (disruptionsInLine) {
    for (const disruption of disruptionsInLine) {
      for (const impacted of disruption.impacted_objects) {
        // If the disruption has already been stored, skip it
        if (disruptionsInLineForThatRoute.indexOf(disruption) === -1) {
          if (impacted.routes && impacted.routes.find(r => r.id === route.route_id)) { // Section disruptions
            disruptionsInLineForThatRoute.push(disruption)
            impacted_objects.push(...disruption.impacted_objects.filter(impacted => impacted.line === line.id))
          } else if (impacted.id) { // Stop disrutpions
            if (stopsList.find(s => s[impacted.type === "stop_area" ? 'stop_area' : 'id'] === impacted.id)) {
              disruptionsInLineForThatRoute.push(disruption)
              impacted_objects.push(impacted)
            }
          } else if (impacted.type === "line") { // Line disruptions
            disruptionsInLineForThatRoute.push(disruption)
          }
        }
      }
    }
  }

  // Retrieve disruptions in line sorted by begin date
  const disruptions = disruptionsInLineForThatRoute ? sortBy(disruptionsInLineForThatRoute, "begin").map(disruption => (
    <div key={disruption.id} className={disruption.severity}>
      <div className='disruptionSeverity white'>
        <div className='icon' />
        {disruption.severity === 'blocking' ? 'Perturbation majeure' : disruption.severity === 'delays' ? 'Perturbation' : 'Information'}
      </div>
      <div className='disruption'>
        {disruption.title && <div className='disruptionTitle'>{disruption.title}</div>}
        {/* {disruption.reason && <div className='disruptionReason'><strong>Motif : </strong>{disruption.reason}</div>} */}
        {disruption.message ? <div className='disruptionContent' dangerouslySetInnerHTML={{ __html: disruption.message }} /> : <div className='disruptionContent empty'>Aucun motif renseigné</div>}
        <div className="disruption-dates">
          {disruptionsDatetime(disruption.begin, disruption.end, language)}
          {disruption.periods.length > 1 && (
            <>
              <div className="disruption-dates-more" onClick={() => {
                const disruptions = Array.from(disruptionsInLine)
                const current = disruptions.find(d => d.id === disruption.id)
                current.opened = !current.opened
                
                appStore.dispatch(actionSetDisruptionInLine(disruptions))
              }}>
                {languageFile['display-disruptions-more-dates']}
              </div>
              <Collapse isOpened={!!disruption.opened}>
                <div className="collapsed">
                  {Array.from(disruption.periods).splice(1).map(period => <div key={period.begin}>{disruptionsDatetime(period.begin, period.end, language)}</div>)}
                </div>
              </Collapse>
            </>
          )}
        </div>
      </div>
    </div>
  )) : []

  return <div className='elevation'>
    <div className='active-line'>
      <div className='line-header' key={line.id}>
      {{
        modeWithDirection: (
          <>
            <div className='line mode with-direction' style={{
              background: '#' + line.color,
              color: luminance(line.color) > 0.5 ? '#333' : '#fff'
            }}>
              {lineMode.name}
            </div>
            <span className='direction'>{line.name}</span>
          </>
        ),
        codeWithDirection: (
          <>
            <div className='line code with-direction' style={{
              background: '#' + line.color,
              color: luminance(line.color) > 0.5 ? '#333' : '#fff'
            }}>
              {line.code}
            </div>
            <span className='direction'>{line.name}</span>
          </>
        ),
        image: (
          <>
            <div className='line' style={{ padding: '10px' }}>
              <img src={'/assets/images/lines/' + line.code + '.svg'} alt={line.code} />
            </div>
            <span className='direction'>
              {languageFile['line-direction']} :<br />
              <strong>{line.routes.find(r => r.direction_id === line.direction_id).name}</strong>
            </span>
          </>
        ),
        color: (
          <>
            <div className='line'>
              <span className={'line-code'} style={{
                  background: '#' + line.color,
                  color: luminance(line.color) > 0.5 ? '#333' : '#fff'
                }}>
                {line.code}
              </span>
            </div>
            <span className='direction'>
              {languageFile['line-direction']} :<br />
              <strong>{line.routes.find(r => r.direction_id === line.direction_id).name}</strong>
            </span>
          </>
        )
      }[styleLine]}
        <div className='tools'>
          <>
            {line.routes.length > 1 &&
              <Tippy theme={'latitude'} touch={['hold', 500]} placement={'right'} boundary="window" content={languageFile['title-swap']}>
                <span className='tool-swap' onClick={() => {
                  const { areas, stops } = component.props
                  let { openedMarker } = component.props

                  line.direction_id = line.direction_id === 'f' ? 'b' : 'f'

                  if (openedMarker) {
                    const area = areas.find(area => area.id === openedMarker.stop_area)
                    const found = area.lines.find(itemLine => itemLine.id === line.id && ((itemLine.stop_id !== openedMarker.id) || (line.direction_id === itemLine.direction_id)))

                    if (!found) {
                      appStore.dispatch(actionSetLineInformation(`${languageFile['stop-timetable-direction-not-deserved']} (<strong>${openedMarker.name}</strong>)`))
                      appStore.dispatch(actionOnLineSelected(line))
                      return
                    }
                    const stop = stops.find(item => item.id === found.stop_id)
                    appStore.dispatch(actionOnLineSelected(line, envVarToBool(REACT_APP_DONT_DISPLAY_STOP_AT_LINE_SELECTION) ? [] : stop))
                  } else {
                    appStore.dispatch(actionOnLineSelected(line))
                  }}
                }/>
              </Tippy>}
            {!component.state.timetable && !touchscreenSelected && (REACT_APP_TIMETABLES === 'api' || REACT_APP_TIMETABLES === 'local') && <Tippy theme={'latitude'} touch={['hold', 500]} placement={'right'} boundary="window" content={languageFile['title-download-timetable']}><span className={'tool-timetable' + (isJDApp(REACT_APP_TYPE, variant) ? ' jd' : '')} onClick={() => collapseTimetableOptions(component)} /></Tippy>}
          </>
          {!isJDApp(REACT_APP_TYPE, variant) && envVarToBool(REACT_APP_FAVORITE) && !touchscreenSelected && <span className={'tool-favorite' + (favoriteLine ? ' active' : '')} data-tip data-for='login' onClick={() => {
            if (token) {
              updateFavorite(line, token)
            }
          }}>
            <ReactTooltip id='login' disable={token !== undefined} isCapture place={isMobile ? 'left' : 'right'} type='light' effect='solid' event='click' globalEventOff='click' clickable>
              {languageFile['tcl-not-connected']}<br />
              <a className='button login' href={origin + '/se-connecter-mon-tcl'} target='_parent'>{languageFile['log-in']}</a>
            </ReactTooltip>
          </span>}
        </div>
        <div className={'timetableOptions'}>
          <Collapse isOpened={!!component.state.timetableOptions}>
            {component.state.timetableLineData
              ? (component.state.timetableLineData.length > 0
                ? <>
                  {component.state.timetableLineData.map(timetable => <div key={timetable.id} className='timetableOptionsItem' target='_blank'
                    onClick={() => {
                      // Google Tag Manager
                      REACT_APP_GTM && REACT_APP_GTM && updateDataLayer({
                        event: 'map-downloadTimetable',
                        line: component.state.currentLine.code,
                        jd: isJDApp(REACT_APP_TYPE, variant),
                        variant: variant ? variant.substr(1) : null
                      })

                      if (REACT_APP_TIMETABLES === 'local') {
                        window.open('/' + timetable.file)
                      } else if (REACT_APP_TIMETABLES === 'api') {
                        window.open(timetable.file)
                      }
                    }}>
                    {timetable.name}
                  </div>)}
                </>
                : <div className='timetableOptionsItem'>{languageFile['no-timetable']}</div>)
              : !component.state.directDownload && <div className='loading'>
                <img src='/assets/images/loading.gif' width={15} alt={languageFile.loading} />
              </div>}
          </Collapse>
        </div>
      </div>
      {line.tad && line.tad.booking && <>
        <div className='line-tad-header'>{languageFile['tad-booking']}</div>
        <div className='line-tad-informations'>  
          {line.tad.booking.phone && <a className='line-tad-phone' href={'tel:' + line.tad.booking.phone}><img src='/assets/images/phone.svg' alt={languageFile['tad-phone-booking']}/>{line.tad.booking.phone}</a>}
          {line.tad.booking.website && <a className='line-tad-website' href={line.tad.booking.website.url} target='_blank' rel='noopener noreferrer'><img src='/assets/images/website.svg' alt={languageFile['tad-web-booking']}/>{line.tad.booking.website.name}</a>}
        </div>
      </>}
      {line.errorPath && <div className='error'>
        <img src='/assets/images/error.svg' alt={languageFile['severity-error']} />
        {languageFile['error-cant-open-line-geojson']} {line.code}
      </div>}
      {component.state.timetable ? <>
        <div className='timetable-stop'>
          <span>{languageFile['stop']} : <strong>{component.state.timetableStop}</strong></span>
          {!timetableDataIsEmpty(component.state.timetableData) && !touchscreenSelected && <Tippy theme={'latitude'} touch={['hold', 500]} placement={'right'} boundary="window" content={languageFile['title-print']}>
            <div className='print' onClick={() => {
              component.setState({
                printing: true
              })

              const timetable = document.querySelector('.slider-list')
              const scale = 2
              const currentLine = component.state.currentLine
              const date = params.date && params.date.substring(0, 4) + params.date.substring(4, 6) + params.date.substring(6, 8)
              const dateFormat = isSystemUS(language) ? 'MM/DD/YYYY' : 'DD/MM/YYYY'

              // Hide the otherDirections on both 2nd & 3rd slide
              Array.from(timetable.querySelectorAll('.otherDirections')).map((item, index) => index > 0 && (item.style.display = 'none'))

              domtoimage.toPng(timetable, {
                scale,
                style: {
                  transform: 'none'
                }
              }).then(async dataurl => {
                // Create PDF
                const jspdf = new jsPDF({ // eslint-disable-line
                  orientation: 'portrait',
                  unit: 'mm',
                  format: 'A4',
                  compress: true
                })

                const pdfWidth = jspdf.internal.pageSize.width - 20

                // Add all needed text
                jspdf.setTextColor(100)
                jspdf.setFontSize(11)
                jspdf.setFontType('bold')
                jspdf.text(languageFile['timetable-print-date'] + ' ' + moment(date).format(dateFormat), jspdf.internal.pageSize.width / 2, 10, 'center')

                const lineImg = new Image()
                const lineSelected = document.querySelector('.line')
                await domtoimage.toPng(lineSelected, {
                  scale: 1,
                  style: {
                    transform: 'none'
                  }
                }).then(lineSelectedImg => {
                  lineImg.src = lineSelectedImg
                })

                lineImg.onload = () => {
                  // TODO multi page print
                  jspdf.addImage(lineImg, 'PNG', 10, 18.5, lineImg.width / 5, lineImg.height / 5)

                  jspdf.setFontSize(9)
                  if (currentLine.routes.length > 1) {
                    jspdf.text(currentLine.routes.find(r => r.direction_id !== currentLine.direction_id).name, lineImg.width / 5 + 13, currentLine.routes.length > 1 ? 22 : 24.5)
                    jspdf.text(currentLine.routes && currentLine.routes.find(r => r.direction_id === currentLine.direction_id).name, lineImg.width / 5 + 13, 26.5)
                  } else {
                    jspdf.text(currentLine.routes[currentLine.direction_id === 'f' && currentLine.routes.length > 1 ? 1 : 0].name, lineImg.width / 5 + 13, currentLine.routes.length > 1 ? 22 : 24.5)
                  }

                  jspdf.setFontType('normal')
                  jspdf.text(languageFile['stop'] + ' : ' + component.state.timetableStop, 10, 37)
                  jspdf.text(languageFile['line-direction'] + ' : ' + currentLine.routes.find(r => r.direction_id === currentLine.direction_id).name, 10, 42)
                  if (timetableNotes) {
                    const splitNotes = jspdf.splitTextToSize(languageFile['timetables-notes'] + ' : ' + timetableNotes, pdfWidth)
                    jspdf.text(10, 47, splitNotes)
                  }

                  const img = new Image()
                  img.src = dataurl
                  img.onload = () => {
                    let width = img.width
                    let height = img.height

                    if (width > pdfWidth) {
                      width = pdfWidth
                      height = height / (img.width / pdfWidth)
                    }

                    // TODO multi page print
                    jspdf.addImage(dataurl, 'PNG', 10, timetableNotes ? 55 : 50, width, height)

                    const browser = detect()

                    if (!isMobile && browser.name !== 'edge') {
                      jspdf.output('dataurlnewwindow')
                    }

                    saveAs(jspdf.output('blob'), 'grille-horaire.pdf')

                    component.setState({
                      printing: false
                    })
                  }
                }
              }).catch(error => {
                console.error('oops, something went wrong!', error)

                component.setState({
                  printing: false
                })
              })

              // Show the otherDirections on both 2nd & 3rd slide
              setTimeout(() => Array.from(timetable.querySelectorAll('.otherDirections')).map((item, index) => index > 0 && (item.style.display = 'block')))
            }} />
          </Tippy>}
        </div>
        {timetableNotes && <>
            <div className="informations odt" key="timetables-notes">
              {timetableNotes}
            </div>
          </>}
        <>
          <DateCalendar
            lang={language}
            systemUS={isSystemUS(language)}
            style={pickerStyle}
            todayTxt={languageFile['calendar-today-word']}
            image={'/assets/images/calendar.svg'}
            arrow={'/assets/images/arrow-calendar.svg'}
            setDate={params.date}
            getSelectedDate={getSelectedDate => {
              const date = getSelectedDate.format('YYYYMMDD')

              if (!params.date || params.date !== date) {
                history.push({ pathname, search: search.split('&date=')[0] + '&date=' + date })
              }
            }}
          />
          <Timetable component={component} />
        </>
      </> : <div className={'stops scroll' + (line.tad && !line.tad.thermo ? ' tad-no-thermo' : '')}>
        {component.props.lineInformation && <div className='delays'>
          <div className='disruption' dangerouslySetInnerHTML={{ __html: component.props.lineInformation }} />
        </div>}
        {REACT_APP_DISRUPTION_COLLAPSED && disruptions.length > 0 ? <div className="disruptions">
          <div className="disruptions-head" onClick={() => {
            appStore.dispatch(actionSetOpenedCollapse("disruptions-line"))
          }}>
            <span>{languageFile['display-disruptions']}</span>
            <div className='arrow'>
              <img className={openedCollapse !== "disruptions-line" ? 'closed' : ''} src='/assets/images/v.svg' alt={languageFile['collapse-arrow']} />
            </div>
          </div>
          <Collapse isOpened={openedCollapse === "disruptions-line"}>
            {openedCollapse === "disruptions-line" && disruptions}
          </Collapse>
        </div> : disruptions.length > 0 && disruptions}
        {tooManyFavorites ? <div className={'notification error' + (browser.os === 'iOS' ? ' ios' : '')} onClick={() => appStore.dispatch(actionSetTooManyFavorites(false))}>{languageFile['too-many-favorites']}</div> : ''}
        {stopsList.map((stop, index) => <div key={stop.index} className={'stop' + (stop.opened && touchscreenSelected ? ' selected' : '')}>
          {line.cat !== 'TAD-markers-only' && <>
            {(!line.tad || (line.tad && line.tad.thermo)) && <div className={'border' + (index === 0 ? ' first-border' : '')} style={{ borderLeftColor: '#' + line.color }} />}
            {
              envVarToBool(REACT_APP_DISRUPTION) && reduxMarkers && reduxMarkers.find(m => m.key === line.code + "_" + stop.index + "_disrupted") ? 
                <div style={{ border: '2px solid #' + line.color, background: stop.severity === "blocking" ? 'red' : 'orange' }} className={'point' + (stop.terminus ? ' stop-terminus' : '')}>
                  <div className={stop.severity === "blocking" ? REACT_APP_SHOW_STOP_BLOCKING_DISRUPTIONS : ''} style={{borderColor: "#" + line.color}}></div>
                </div> :
                <div style={{ border: '2px solid #' + line.color }} className={'point' + (stop.terminus ? ' stop-terminus' : '')} />
            }
          </>}
          <div className={'stop-name' + (stop.opened ? ' selected' + ((!isJDApp(REACT_APP_TYPE, variant) && envVarToBool(REACT_APP_FAVORITE) && !touchscreenSelected) ? ' hasFavorite' : '') : '') + (line.tad && !line.tad.thermo ? ' tad-no-thermo' : '')} onClick={() => {
            if (stop.opened) {
              history.push({ pathname, search: search.split('&stop=')[0] })
            } else {
              history.push({ pathname, search: search.split('&stop=')[0] + '&stop=' + stop.id })
            }
          }} onMouseEnter={() => {
            // onMarkerMouseOver(component, stop)
            appStore.dispatch(actionOverMarker(stop))
          }} onMouseLeave={() => {
            // onMarkerMouseOut(component, stop)
            setTimeout(() => appStore.dispatch(actionOutMarker(stop)))
          }}>
            <div className={stop.opened ? 'selectedStop' : ''}>
              <div className='stop-and-tools'>
                {stop.opened ? <span>{stop.name}</span> : stop.name}
                {REACT_APP_SHOW_PMR !== 'none' && stop.pmr ? REACT_APP_SHOW_PMR === 'pmr' && <div className='is-pmr'/> : REACT_APP_SHOW_PMR === 'no-pmr' && <div className='is-no-pmr'/>}
                {REACT_APP_SHOW_ADDITIONAL_STOP_TOOL && JSON.parse(REACT_APP_SHOW_ADDITIONAL_STOP_TOOL).map(tool => {
                  if (stop[tool] && (stop[tool] === true || (stop[tool].length && stop[tool].includes(`${line.code}_${line.network}`)))) {
                    return <div key={`${stop.id}_${tool}`} className={`is-${tool}`}/>
                  } else {
                    return false
                  }
                })}
              </div>
              {stop.opened && !isJDApp(REACT_APP_TYPE, variant) && REACT_APP_NEXT_SCHEDULES_RESULTS>0 && <div className='stopActions'>
                {envVarToBool(REACT_APP_FAVORITE) && !touchscreenSelected
                  ? <span className={'tool-favorite' + (favoriteStop && (Array.isArray(favoriteStop.id) ? favoriteStop.code[0].value : favoriteStop.stop_area) === stop.stop_area ? ' active' : '')} data-tip data-for='login' onClick={(e) => {
                      e.stopPropagation()
                      // Add route id in the stop to give the correct route id in Niji favorite
                      const route = line.routes.find(r => r.direction_id === line.direction_id).route_id
                      stop.route_id = route

                      if (token) {
                        updateFavorite(stop, token)
                      }
                    }}>
                      <ReactTooltip id='login' disable={token !== undefined} isCapture place={isMobile ? 'left' : 'right'} type='light' effect='solid' event='click' globalEventOff='click' clickable>
                    {languageFile['tcl-not-connected']}<br />
                    <a className='button login' href={origin + '/se-connecter-mon-tcl'} target='_parent'>{languageFile['log-in']}</a>
                  </ReactTooltip>
                    </span>
                  : <span className='no-tool-favorite'/>
                }
              </div>}
            </div>
            {!isJDApp(REACT_APP_TYPE, variant) && REACT_APP_NEXT_SCHEDULES_RESULTS>0 && (!line.tad || (line.tad && line.tad.schedules)) && <Collapse isOpened={!!stop.opened} hasNestedCollapse springConfig={{ stiffness: 800, damping: 50 }}>
              {stop.severity && stop.severity === "blocking"
                  ? <div className={"severity schedules blocking"}>
                      <div className="disruptionSeverity">
                        <div className="icon" />
                      </div>
                      {languageFile["severity-blocking-stop"]} {line.code}
                    </div>
                  : stop.opened ? !stop.rer ? <div className='selectedContent'>
                {stop.schedules ? <div key={stop.index + '_schedules'} className='schedules'>
                  {stop.schedules.length > 0 ? stop.schedules.map((schedule, index) => (
                    <div key={'schedule-' + index} className='schedule'>
                      {moment().diff(schedule.time, "hours") < 1 && moment(schedule.time).fromNow(true).includes('min') ? <>
                        <Moment interval={0} locale='fr' fromNow ago style={{ whiteSpace: 'nowrap' }}>
                          {schedule.time}
                        </Moment>
                        {schedule.realtime && <Tippy theme={'latitude'} touch={['hold', 500]} placement={'right'} boundary="window" content={languageFile['realtime-gif-title']}>
                            <img src='/assets/images/realtime.gif' alt={languageFile['realtime-gif-alt']} />
                          </Tippy>}</> : navitiaDateToHoursMin(schedule.time, language)}
                    </div>
                  )) : <div>{languageFile['no-schedules']}</div>}
                </div> : <img src='/assets/images/loading.gif' width={30} alt={languageFile.loading} />}
                <div className='seeTimetable' onClick={e => {
                  e.stopPropagation()
                  const date = moment().format('YYYYMMDD')
                  history.push({ pathname, search: search + '&date=' + date })
                }}>
                  <img src='/assets/images/timetable.svg' alt={languageFile['timetable-button']} />
                  <div dangerouslySetInnerHTML={{ __html: languageFile['timetable-button'] }} />
                </div>
              </div> : <div style={{ paddingLeft: 10 }}>Pour afficher les horaires, se référer au calcul
              d'itinéraire</div> : <div />}
            </Collapse>}
          </div>
        </div>)}
      </div>}
    </div>
  </div>
}

/**
 * Render lines passing by an area
 * @param component
 */
export const renderLinesByArea = (component) => {
  const { languageFile } = component.props

  return <div className='group'>
    <div className='group-name'>
      <div className='group-mode'>{component.state.linesList.length > 0 ? languageFile['lines-by-stoparea'] : languageFile['no-line-by-stoparea']}  {languageFile['lines-going-through-stoparea']} {component.state.linesListStop}</div>
    </div>
    <div className='group-offset-bottom'>{renderLinesLabels(component, component.state.linesList, 'line')} </div>
  </div>
}

/**
 * Display lines sorted by group (collapsable)
 * @param component
 * @returns {any[]}
 */
export const renderLinesGroup = component => {
  const { languageFile, openedCollapse, linesModes } = component.props
  const { groups, pois } = component.state

  if ((!groups || Object.keys(groups).length === 0) && (!pois || Object.keys(pois).length === 0)) {
    return <div className='empty'>{languageFile['no-lines-around']}</div>
  }

  const jsx = Object.keys(groups).map(group =>
    <div key={group} className='group' onClick={() => appStore.dispatch(actionSetOpenedCollapse(group))}>
      <div className='group-name'>
        {{
          modesTitles: (
            <div className='group-mode'>{languageFile[group]}</div>
          ),
          modesTitlesIconsAndText: (
            <>
              <img className={'group-mode-logo'} src={`/assets/images/${group}.svg`} alt={group} />
              <div className='mode'>
                <div className={group} style={getColorGroup(linesModes, group)}>{languageFile[group]}</div>
                <div className='text'>{languageFile['mode-' + group + '-text']}</div>
              </div>
            </>
          )
        }[REACT_APP_MODES_LINES_STYLE]}
        <div className='arrow-group'>
          <img className={group !== component.props.openedCollapse ? 'closed' : ''} src='/assets/images/v.svg'
            alt={languageFile['collapse-arrow']} />
        </div>
      </div>
      <Collapse className={group === openedCollapse ? 'group-offset-bottom' : ''}
        isOpened={group === openedCollapse} hasNestedCollapse springConfig={{ stiffness: 800, damping: 50 }}>
        {group === openedCollapse && renderLinesLabels(component, groups[group], group)}
      </Collapse>
    </div>
  )

  if (pois && Object.keys(pois).length > 0) {
    const pois = renderPlacesLabels(component)

    return [jsx, pois]
  } else {
    return jsx
  }
}

export const renderPlacesGroup = component => {
  const { languageFile, openedCollapse, variant } = component.props
  const { places } = component.state

  if (!places) {
    return
  }

  if (Object.keys(places).length === 0) {
    return <div className='empty'>{isJDApp(REACT_APP_TYPE, variant) ? languageFile['no-places-jd-around'] : languageFile['no-places-around']}</div>
  }

  return Object.keys(places).map((place, index) =>
    <div key={place} className='group' onClick={() => appStore.dispatch(actionSetOpenedCollapse(place))}>
      <div className='group-name'>
        <div className='group-header'>
          <img
            src={'/assets/images/places/' + places[place][0].code + '.svg'}
            alt={place} />
          <span>{languageFile[place]}</span>
        </div>
        <div className='arrow-group'>
          <img className={place !== openedCollapse ? 'closed' : ''} src='/assets/images/v.svg'
            alt={languageFile['collapse-arrow']} />
        </div>
      </div>
      <Collapse
        isOpened={place === openedCollapse} hasNestedCollapse springConfig={{ stiffness: 800, damping: 50 }}>
        {place === openedCollapse && places[place].map(item => (
          <div key={item.id} className='place' onMouseEnter={() => {
            appStore.dispatch(actionOverMarker(item))
          }} onMouseLeave={() => {
            appStore.dispatch(actionOutMarker(item))
          }} onClick={() => appStore.dispatch(actionOpenMarker(item, false))}>
            &bull;
            <div>{item.name}</div>
          </div>
        ))}
      </Collapse>
    </div>
  )
}

/**
 * Render labels for given lines
 * @param component
 * @param lines
 * @param key
 * @param marker
 */
export const renderLinesLabels = (component, lines, key, marker) => {
  const { linesModes, size, variant } = component.props
  const onLineSelected = component.onLineSelected

  // Avoid undefined lines...
  if (!lines) {
    return
  }

  let styleLine = (REACT_APP_TYPE === 'sncf-ter' && variant === '/normandie') ? 'modeWithDirection' : REACT_APP_LINES_MAIN_TYPE
  const div = data => <div key={key + Math.random()} className={(key === 'infobox' ? 'infobox-' : '') + 'lines ' + size + (styleLine.includes('WithDirection') ? " line-with-direction" : "")}>
    {data.map(line => {
      // Retrieve the global line
      line = getLine(component, line)

      if (!isJDApp(REACT_APP_TYPE, variant) && line.cat === 'junior direct') {
        return null
      } else if (isJDApp(REACT_APP_TYPE, variant) && line.cat !== 'junior direct') {
        return null
      }
      
      if (JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS).length) {
        const exceptions = JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS)
        const foundExceptedLine = exceptions.find(e => e.lines.includes(line.id))
        
        if (foundExceptedLine) {
          styleLine = foundExceptedLine.type
        }
      }

      switch (styleLine) {
        case 'modeWithDirection':    
          const lineMode = linesModes.find(mode => mode.modes.includes(line.mode))
          return <div className='attribute-line' key={line.id} onClick={e => {
            e.stopPropagation()
            // Add line to historic
            if (storageAvailable('localStorage')) {
              addHistoricItem(line, variant)
            }
            !history.location.pathname.includes('route-calculation') && onLineSelected(line, marker)
          }}>
            <div className='line mode' style={{ background: '#' + line.color, color: luminance('#' + line.color) > 0.5 ? '#333' : '#fff' }}>{lineMode.name}</div>
            <div className='name'>{line.name}</div>
          </div>
        case 'codeWithDirection':    
          return <div className='attribute-line' key={line.id} onClick={e => {
            e.stopPropagation()
            // Add line to historic
            if (storageAvailable('localStorage')) {
              addHistoricItem(line, variant)
            }
            !history.location.pathname.includes('route-calculation') && onLineSelected(line, marker)
          }}>
            <div className='line code' style={{ background: '#' + line.color, color: luminance('#' + line.color) > 0.5 ? '#333' : '#fff' }}>{line.code}</div>
            <div className='name'>{line.name}</div>
          </div>
        case 'image':
          return <div className='line' key={line.id} onClick={e => {
            e.stopPropagation()
            // Add line to historic
            if (storageAvailable('localStorage')) {
              addHistoricItem(line, variant)
            }
            !history.location.pathname.includes('route-calculation') && onLineSelected(line, marker)
          }}>
            <img src={'/assets/images/lines/' + line.code + '.svg'} alt={line.code} />
          </div>
        case 'color':
          return <div key={line.id} className='line' onClick={e => {
            e.stopPropagation()
            if (storageAvailable('localStorage')) {
              addHistoricItem(line, variant)
            }
            !history.location.pathname.includes('route-calculation') &&  onLineSelected(line, marker)
          }}>
            <div
              className='line-code'
              style={{
                background: '#' + line.color,
                color: luminance(line.color) > 0.5 ? '#333' : '#fff'
              }}>
              {line.code}
            </div>
          </div>
        default:
          return ''
      }
    })}
  </div>

  if (!lines) {
    return
  }

  // WTF Sorting ...
  if (REACT_APP_TYPE === 'tcl') {
    // Sort only the bus
    if (lines[0] && lines[0].cat === 'bus') {
      sortAlphabetic(lines, 'code')

      // Sort S lines
      sortAlphanumericWithStartLetter(lines, 'code', 'S')
    }
    
    // Re sort only "C" lines
    lines.sort((a, b) => {
      a = getLine(component, a)
      b = getLine(component, b)
  
      if (a.code && b.code) {
        const codeA = parseInt(a.code.replace('C', ''), 10)
        const codeB = parseInt(b.code.replace('C', ''), 10)
  
        return codeA - codeB
      }
  
      return false
    })
  }
  return div(lines)
}

export const renderPlacesLabels = component => {
  const { languageFile, openedCollapse, variant } = component.props
  const { pois } = component.state

  if (!pois) {
    return
  }

  if (Object.keys(pois).length === 0) {
    return <div className='empty'>{isJDApp(REACT_APP_TYPE, variant) ? languageFile['no-places-jd-around'] : languageFile['no-places-around']}</div>
  }

  return Object.keys(pois).map((poi, index) =>
    <div key={poi} className='group' onClick={() => {
      if (poi === openedCollapse && poi === 'poi_type:stations') {
        appStore.dispatch(actionSetPlaceClicked(null))
      }

      appStore.dispatch(actionSetOpenedCollapse(poi))
    }}>
      <div className='group-name'>
        <div className='group-mode'>
          {languageFile[poi]}
        </div>
        <div className='arrow-group'>
          <img className={poi !== openedCollapse ? 'closed' : ''} src='/assets/images/v.svg' alt={languageFile['collapse-arrow']} />
        </div>
      </div>
      <Collapse
        isOpened={poi === openedCollapse || !!Object.keys(pois[poi]).find(key => key === openedCollapse)}
        hasNestedCollapse springConfig={{ stiffness: 800, damping: 50 }}>
        {(poi === openedCollapse || !!Object.keys(pois[poi]).find(key => key === openedCollapse)) &&
          (Array.isArray(pois[poi])
            ? appStore.dispatch(actionBuildPlacesByCatInList({ [poi]: pois[poi] }))
            : appStore.dispatch(actionBuildPlacesByCatInList(pois[poi])))
        }
      </Collapse>
    </div>
  )
}

/**
 * Render a marker on the map
 * @param stop
 * @param options
 * @param component
 * @param place
 * @returns Marker
 */
export const renderMarker = (component, stop, options) => {
  return <Marker
    key={stop.id}
    ref={r => { stop.ref = r }}
    name={stop.name}
    position={[+stop.coord.lat, +stop.coord.lon]}
    icon={L.icon({
      iconUrl: '/assets/images/pin.svg',
      iconSize: [50, 50],
      iconAnchor: [25, 25]
    })}
    {...options}
  >
    {
      stop.terminus &&
      <Tooltip key={'terminus_' + stop.id} direction={'right'} className={'tooltip-leaflet-terminus'} opacity={1}
        permanent>{stop.name}</Tooltip>
    }
    <Popup className={'popup-leaflet'} closeButton={false} autoClose={false} autoPan={false}>{renderInfobox(component,
      stop, null)}</Popup>
  </Marker>
}

export const renderMarkerRouteCalculation = (key, component, position) => {
  const { map, language, moduleData } = component.props
  const { localizeMarkers, markersConfig } = moduleData

  return <Marker
    key={key}
    draggable={!component.state.loading}
    onDragStart={component.onBackToParams}
    onDragEnd={event => {
      const { pathname } = history.location
      const params = getURLSearchParams(history.location)
      const latlng = event.target.getLatLng()

      if (key === 'inputStart-pin') {
        if (map.state.inputStartPin && !map.state.inputEndPin) {
          history.push({ pathname, search: '?from=' + substringCoords(latlng) })
        } else if (map.state.inputStartPin && map.state.inputEndPin) {
          history.push({ pathname, search: '?from=' + substringCoords(latlng) + '&to=' + params.to })
        }
      } else {
        if (!map.state.inputStartPin && map.state.inputEndPin) {
          history.push({ pathname, search: '?to=' + substringCoords(latlng) })
        } else if (map.state.inputStartPin && map.state.inputEndPin) {
          history.push({ pathname, search: '?from=' + params.from + '&to=' + substringCoords(latlng) })
        }
      }
    }}
    zIndexOffset={999999}
    icon={L.icon({
      iconUrl: `/assets/images/route-calculation/${key === 'inputStart-pin' ? 'flag-start' : 'flag-end'}${localizeMarkers ? '-' + language : ''}.svg`,
      iconSize: markersConfig ? markersConfig.size : key === 'inputStart-pin' ? [35, 35] : [40, 40],
      iconAnchor: markersConfig ? markersConfig.anchor : key === 'inputStart-pin' ? [17.5, 17.5] : [20, 20]
    })}
    position={position}
  />
}

// TODO RENAME
export const renderPlaces = async (component, pois) => {
  const { map } = component.props
  const places = pois ? component.state.pois : component.state.places

  // TODO THROW ERROR
  if (!places) {
    console.warn('No places')
    return
  }

  const allPlaces = []

  for (const place of Object.keys(places)) {
    const items = Array.isArray(places[place]) ? places[place] : Object.keys(places[place]).reduce((acc, item) => {
      acc.push(...places[place][item])
      return acc
    }, [])
    allPlaces.push(...items)
  }

  const markers = []
  for (const place of allPlaces) {
    markers.push(
      renderMarker(component, place, {
        icon: L.icon({
          iconUrl: '/assets/images/places/' + place.code + '.svg',
          iconSize: [25, 25],
          iconAnchor: [12, 12]
        })
      })
    )
  }

  map.setState({
    clusters: null,
    status: null,
    markers
  }, () => resize(map.props.isMobile))
}

export const renderPolygon = (path, options, key, props) => {
  return <Polygon {...options} key={key} positions={path} {...props} />
}

export const renderPolyline = (path, options, key) => {
  return <Polyline key={key} positions={path} options={options} />
}

// Render town data
export const renderTown = (component) => {
  const { languageFile, variant } = component.props
  if (component.state.tab === 1) {  
    appStore.dispatch(actionBuildTransportPlaces([]))
    appStore.dispatch(actionBuildPublicPlaces(component.state.places))
  } else {
    appStore.dispatch(actionBuildTransportPlaces(component.state.pois))
    appStore.dispatch(actionBuildPublicPlaces([]))
  }
  return <Tabs
    selectedIndex={component.state.tab || 0}
    onSelect={index => onTabSelected(component, index)}
    selectedTabClassName='active'
    selectedTabPanelClassName='active scroll'>
    <TabList className='tab-list'>
      <Tab className='tab'>{languageFile['transport-tab']}<br /><span>{component.state.town.name.replace(/arrondissement/i,
        '')}</span></Tab>
      <Tab className='tab'>{isJDApp(REACT_APP_TYPE, variant) ? languageFile['places-jd-tab'] : languageFile['places-tab']}<br /><span>{component.state.town.name.replace(/arrondissement/i, '')}</span></Tab>
    </TabList>
    <TabPanel className='tab-panel'>
      {renderLinesGroup(component)}
    </TabPanel>
    <TabPanel className='tab-panel'>
      {appStore.dispatch(actionBuildPlacesByCatInList(component.state.places))}
    </TabPanel>
  </Tabs>
}

/**
 * Retrieve schedules for a given stop in a given component
 * @param component
 * @param stop
 * @param line
 * @param date
 * @param fromMarkerClick
 */
export const schedules = (component, stop, line, date = '') => {
  const { stopsList } = component.state

  const isTerminus = stop.terminus && stop.id === stopsList[stopsList.length - 1].id // TCL style

  for (const s of stopsList) {
    s.schedules = null
    s.opened = false
  }
  // Remove any current line information message
  appStore.dispatch(actionSetLineInformation(null))

  // TODO real timetable fix
  if (component.state.timetable) {
    return
  }

  // TODO DANS AROUND ARRET LE PLUS PROCHE PAS VISIBLE DU COUP...
  if (line.cat === 'tad' || line.cat === 'rer') {
    return
  }

  // Avoid load schedules for a nonsense data
  let markerContainsLine = false
  for (const data of stop.lines) {
    if (data.id === line.id) {
      markerContainsLine = true
      break
    }
  }

  if (!markerContainsLine) {
    return
  }

  for (const s of stopsList) {
    s.schedules = null
    s.opened = false

    if (stop.id.includes('stop_area')) {
      s.opened = s.stop_area === stop.id && !isTerminus
    } else {
      s.opened = s.id === stop.id && !isTerminus
    }
  }

  component.setState({
    // timetable: false,
    stopsList
  }, async () => {
    // Retrieve element and scroll to it
    const selected = stop.id.includes('stop_area')
      ? stopsList.filter(s => s.stop_area === stop.id)
      : stopsList.filter(s => s.stop_area === stop.stop_area && s.id === stop.id)

    if (selected.length > 0) {
      // Select the stop
      setTimeout(() => displayStopOnScrollToIt(component, selected[0]), 150)

      if (!isTerminus && (!line.tad || (line.tad && line.tad.schedules))) {
        const schedules = []
        const route = line.routes.find(r => r.direction_id === line.direction_id).route_id
        axios.get('/api/schedules', {
          params: {
            stop: selected[0].id,
            route,
            date,
            count: REACT_APP_NEXT_SCHEDULES_RESULTS
          }
        }).then(response => {
          for (const resp of response.data.slice(0, REACT_APP_NEXT_SCHEDULES_RESULTS)) {
            schedules.push({
              time: resp.date_time,
              realtime: resp.data_freshness === 'realtime'
            })
          }

          for (const select of selected) {
            select.schedules = schedules
          }
        }).catch(e => {
          for (const select of selected) {
            select.schedules = []
          }

          const error = e.response && e.response.data ? e.response.data.id : e
          console.warn(error)
        }).finally(() => {
          component.setState({ stopsList })
        })
      }
    }
  })
}

/**
 *
 * @param component
 * @param stop
 * @param line
 * @param selected
 */
const displayStopOnScrollToIt = (component, selected) => {
  const { stopsList } = component.state

  // The Elder Scrooooooooll
  let index = 0
  for (const s of stopsList) {
    if (s.id === selected.id) {
      break
    }

    index++
  }

  const element = document.querySelector(`.stops > :nth-child(${index + 1})`)
  const disruptionsElement = document.querySelector(".disruptions")

  // Scroll board to the selected element
  setTimeout(() => {
    element && (element.parentNode.scrollTop = element.offsetTop - (element.parentNode.offsetTop + (disruptionsElement ? 10 : 5)))
  })
}

/**
 * Display line paths and info
 * @param component
 * @param line
 * @param linesTab
 * @param selectedLines
 */
export const selectLine = (component, line) => {
  const { stops } = component.props

  if (!line.direction_id) {
    line.direction_id = "f"
  }
  const selectedRoute = line.routes.find(r => r.id[r.id.length - 1] === line.direction_id)

  if (!selectedRoute.stops) {
    return axios
      .get(
        `/api/file?folder=stops&name=${encodeURIComponent(line.code)}_${line.network}_${line.direction_id}~${
          component.props.hash
        }`
      )
      .then(async response => {
        // TODO Fix retrive lines at generate
        for (const stop of response.data) {
          const infos = stops.find(s => s.id === stop.id)
          stop.lines = infos.lines

          // Define line as PMR only if ONE stop is PMR
          if (stop.pmr) {
            line.pmr = true
          }
        }

        line.stops = response.data
        selectedRoute.stops = response.data
        return {
          currentLine: line,
          stopsList: response.data
        }
      })
      .catch(error => {
        console.warn(
          "Unable to find stops/" + line.code + "_" + line.network + "_" + line.direction_id + ".json",
          error
        )
      })
  } else {
    // Close all saved stops
    for (const stop of selectedRoute.stops) {
      stop.opened = false
    }

    line.stops = selectedRoute.stops

    return {
      currentLine: line,
      stopsList: selectedRoute.stops
    }
  }
}

/**
 * Update map state events
 * @param map
 * @param event
 * @param callback
 */
export const updateMapEvents = (map, event, callback) => {
  if (!map) {
    return
  }

  map.setState(state => ({
    events: {
      ...state.events,
      [event]: callback
    }
  }))
}

export const zoomOnTerritoryOutline = map => {
  const outline = map.props.territoryOutline

  const outlineToRender = []
  const bounds = []

  if (outline) {
    for (const feature of outline.features) {
      let featureCoords = feature.geometry.coordinates
      if (['Polygon', 'MultiLineString'].includes(feature.geometry.type)) {
        featureCoords = featureCoords[0]
      }
      
      for (const coords of featureCoords) {
        bounds.push([coords[1], coords[0]])
        outlineToRender.push(...coords)
      }
    }

    setTimeout(() => {
      fitBounds(map, bounds)
    })
  }
}

// --------------------------- PRIVATE --------------------------- //
const collapseTimetableOptions = (component) => {
  try {
    component.setState({
      timetableData: null,
      timetableOptions: !component.state.timetableOptions
    }, async () => {
      if (component.state.timetableOptions) {
        const element = document.querySelector('.timetableOptions')

        if (element) {
          element.style.top = document.querySelector('.active-line .line-header').offsetHeight + 'px'
        }
      }

      const variant = component.props.variant
      const timetables = []

      if (REACT_APP_TIMETABLES === 'local') {
        let timetablesFile = '/api/file?name=timetables'
        const network = variant.substr(1) || null

        if (REACT_APP_NETWORK_RESTRICT && variant) {
          timetablesFile = `/api/file?folder=timetables&name=timetables-${network}`
        }

        const response = await axios(timetablesFile)
        const timetable = response.data.find(data => data.ligne === component.state.currentLine.code)

        if (timetable && timetable.fiche_horaire) {
          timetable.fiche_horaire.split('~').map((fh, index) => {
            return timetables.push({
              id: index,
              name: fh,
              file: 'assets/timetables/' + fh + '.pdf',
              starting_validity_date: '',
              ending_validity_date: ''
            })
          })
        }
      } else if (REACT_APP_TIMETABLES === 'api') {
        const response = await axios('/api/timetable?line_id=' + component.state.currentLine.id)

        for (const timetable of response.data.data) {
          timetables.push({
            id: timetable.id[0].value,
            name: timetable.name[0].value,
            file: timetable.file_timetable[0].url,
            starting_validity_date: timetable.starting_validity_date[0].value,
            ending_validity_date: timetable.ending_validity_date[0].value
          })
        }
      }

      if (timetables.length === 1 && REACT_APP_TIMETABLES === 'local') {
        REACT_APP_GTM && updateDataLayer({
          event: 'map-downloadTimetable',
          line: component.state.currentLine.code,
          jd: isJDApp(REACT_APP_TYPE, variant),
          variant: variant ? variant.substr(1) : null
        })

        component.setState({
          directDownload: true
        }, () => {
          window.open('/' + timetables[0].file)
        })
      } else {
        component.setState({
          timetableLineData: timetables
        })
      }
    })
  } catch (e) {
    console.warn(e)
  }
}

/**
 * Limit api call
 * @param func
 * @param wait
 * @param immediate
 * @returns {Function}
 */
export const debounce = (func, wait, immediate) => {
  let timeout

  return function () {
    const later = () => {
      timeout = null

      if (!immediate) {
        func.apply(this, arguments)
      }
    }

    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)

    if (callNow) {
      func.apply(this, arguments)
    }
  }
}

/**
 * Display a timetable for a stop and a specific line
 * @param component
 * @param stop
 * @param line
 * @param date
 */
export const displayTimeTable = (component, stop, line, date) => {
  // 0: morning, 1: afternoon, 2: evening selected by defaut with the hour if today
  const isToday = moment().format('YYYYMMDD') === date
  const nowHours = Math.floor(new Date().getHours())
  let slideIndex = isToday ? nowHours >= 4 && nowHours <= 11 ? 0 : nowHours >= 12 && nowHours <= 19 ? 1 : 2 : 0

  // get stop id of line if we have a stop_area
  if (stop.id.includes('stop_area')) {
    stop.stop_id = stop.lines.find(l => l.id === line.id).stop_id
  }

  component.setState({
    timetable: true,
    loadingTimetable: true,
    timetableStop: stop.name
  }, async () => {
    const route = line.routes.find(r => r.direction_id === line.direction_id).route_id
    const params = {
      stop: stop.stop_id || stop.id,
      route,
      timetable: true
    }

    const nightLines = JSON.parse(REACT_APP_NIGHT_LINES)
    if (date) {
      if (nightLines.lines.includes(component.state.currentLine.id)) {
        params.date = `${date}T${nightLines.departure}0000`
        slideIndex = 0 // There is only one slide for the night lines
      } else {
        params.date = date + 'T040000'
      }
    }

    await axios({
      url: '/api/schedules',
      params
    }).then(response => {
      const timetableData = {
        morning: [],
        afternoon: [],
        evening: []
      }

      for (const time of response.data.times) {
        const departure = parseInt(time.date_time.substring(9, 11))

        if (departure >= 4 && departure <= 11) {
          timetableData.morning.push(time)
        } else if (departure > 11 && departure <= 19) {
          timetableData.afternoon.push(time)
        } else if (departure >= 20 || departure < 4) {
          timetableData.evening.push(time)
        }
      }

      const timetableNotes = response.data.notes

      if (timetableDataIsEmpty(timetableData)) { // fix crash
        slideIndex = 0
      }

      component.setState({
        slideIndex,
        timetableData,
        timetableNotes,
        loadingTimetable: false,
        timetableError: false
      }, () => {
        const divs = Array.from(document.querySelectorAll('.scroll'))
        resize(component.props.map.props.isMobile, divs[component.state.slideIndex])
      })
    }).catch(e => {
      console.warn('error', e.response.data.message)
      component.setState({
        loadingTimetable: false,
        timetableError: e.response
      })
    })
  })
}
