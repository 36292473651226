export default (state = {
    isNetwork: false,
    networkMapRef: null
}, action) => {
    switch (action.type) {
        case 'SET_NETWORK_MODE':
            return {
                ...state,
                isNetwork: action.isNetwork
            }

        case 'SET_NETWORK_MAP_REF':
            return {
                ...state,
                networkMapRef: action.mapRef
            }

        case 'SET_NETWORK_MAP_HEAVY_LINES':
            return {
                ...state,
                heavyLines: action.lines
            }

        case 'SET_NETWORK_SELECTED_LINE':
            return {
                ...state,
                line: action.line
            }

        case 'SET_NETWORK_SELECTED_TOWN':
            return {
                ...state,
                town: action.town
            }

        default:
            return state
    }
}
