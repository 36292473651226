export default (state = {
  isMobile: false,
  language: '/fr',
  modules: [],
  linesModes: [],
  lines: [],
  stops: [],
  areas: [],
  languageFile: {},
  modes: {},
  calculateItineraryNow: true,
  variant: '',
  touchscreenSelected: null,
  isLegendOpen: false
}, action) => {
  switch (action.type) {
    case 'INIT_APP':
      return {
        ...state,
        ...action.data
      }

    case 'UPDATE_MEDIA_QUERIES':
      return {
        ...state,
        isMobile: action.isMobile
      }

    case 'SET_MAP':
      return {
        ...state,
        map: action.map
      }

    case 'SET_COMPONENT':
      return {
        ...state,
        component: action.component
      }

    case 'SCROLL_END':
      return {
        ...state,
        top: action.top
      }

    case 'SET_USER_TOKEN':
      return {
        ...state,
        token: action.token
      }

    case 'SET_APP_LANGUAGE':
      return {
        ...state,
        language: action.language
      }

    case 'SET_APP_LOCKER':
      return {
        ...state,
        lock: action.lock === 'true'
      }

    case 'SET_APP_PANEL_SIZE':
      return {
        ...state,
        size: ['small', 'large'].includes(action.size) ? action.size : 'large'
      }

    case 'INIT_MODES':
      return {
        ...state,
        modes: action.modes
      }

    case 'HANDLE_MODES_CHANGED':
      const modes = {
        ...state.modes,
        [action.mode.id]: !state.modes[action.mode.id]
      }

      return {
        ...state,
        modes: modes
      }

    case 'INIT_DATE':
      return {
        ...state,
        selectedDate: action.date,
        selectedTime: action.date
      }

    case 'HANDLE_SELECTED_DATE_CHANGED':
      return {
        ...state,
        selectedDate: action.date,
        calculateItineraryNow: action.now
      }

    case 'HANDLE_SELECTED_TIME_CHANGED':
      return {
        ...state,
        selectedTime: action.date,
        calculateItineraryNow: false
      }

    case 'HANDLE_REPRESENTS_CHANGED':
      const modesRepresentChanged = {
        ...state.modes,
        represents: action.represent
      }
      return {
        ...state,
        modes: modesRepresentChanged
      }

    case 'SET_APP_HASH':
      return {
        ...state,
        hash: action.hash
      }

    case 'SET_NETWORK_MODE':
      return {
        ...state,
        isNetwork: action.isNetwork
      }

    default:
      return state
  }
}
