export default (state = {
  inputValue: '',
  inputStartValue: '',
  inputEndValue: '',
  inputStartObj: {
    empty: true,
    swap: false,
    name: ''
  },
  inputEndObj: {
    empty: true,
    swap: false,
    name: ''
  },
  inputItems: [],
  inputStartItems: [],
  inputEndItems: [],
  goToValid: false,
  currentStationDepartureIndex: 0,
  currentStationArrivalIndex: 0,
  datesPanel: false,
  modesPanel: false
}, action) => {
  switch (action.type) {
    case 'ON_INPUT_VALUE_CHANGE':
      return {
        ...state,
        inputValue: action.value
      }

    case 'ON_INPUTS_SWAP':
      return {
        ...state,
        inputStartObj: {
          swap: true,
          ...action.to
        },
        inputEndObj: {
          swap: true,
          ...action.from
        },
        inputStartValue: action.to.name,
        inputEndValue: action.from.name
      }

    case 'ON_START_INPUT_VALUE_CHANGE':
      const startObj = {
        empty: action.value.name.length <= 0,
        swap: false,
        ...action.value
      }
      return {
        ...state,
        inputStartObj: startObj,
        inputStartValue: action.value.name
      }

    case 'ON_END_INPUT_VALUE_CHANGE':
      const endObj = {
        empty: action.value.name.length <= 0,
        swap: false,
        ...action.value
      }
      return {
        ...state,
        inputEndObj: endObj,
        inputEndValue: action.value.name
      }

    case 'ON_INPUT_ITEMS_CHANGE':
      return {
        ...state,
        inputItems: action.items
      }

    case 'ON_START_INPUT_ITEMS_CHANGE':
      return {
        ...state,
        inputStartItems: action.items
      }

    case 'ON_END_INPUT_ITEMS_CHANGE':
      return {
        ...state,
        inputEndItems: action.items
      }

    case 'SET_PLACE_CLICKED':
      return {
        ...state,
        placeClicked: action.place
      }

    case 'SET_THEMATIC_PLACES':
      return {
        ...state,
        thematicPlaces: action.thematicPlaces
      }

    case 'SET_OPENED_COLLAPSE':
      return {
        ...state,
        openedCollapse: action.collapse === state.openedCollapse ? !state.openedCollapse : action.collapse
      }

    case 'SET_FAVORITE_LINE':
      return {
        ...state,
        favoriteLine: action.line
      }

    case 'SET_FAVORITE_STOP':
      return {
        ...state,
        favoriteStop: action.stop
      }

    case 'SET_FAVORITE_PLACE':
      return {
        ...state,
        favoritePlace: action.place
      }

    case 'SET_FAVORITE_ADDRESS':
      return {
        ...state,
        favoriteAddress: action.address
      }

    case 'SET_DISRUPTIONS':
      return {
        ...state,
        disruptions: action.disruptions
      }

    case 'SET_DISRUPTION_IN_LINE':
      return {
        ...state,
        disruptionsInLine: action.disruptionsInLine
      }

    case 'SET_GEOLOCATION_ERROR':
      if (!action.error) {
        return {
          ...state,
          geolocationError: null
        }
      }

      const geolocationError = {
        code: action.error.code
      }

      switch (action.error.code) {
        case action.error.PERMISSION_DENIED:
          geolocationError.message = 'Vous avez desactivé la géolocalisation.'
          break
        case action.error.POSITION_UNAVAILABLE:
          geolocationError.message = 'Impossible de récupérer votre position actuellement.'
          break
        case action.error.TIMEOUT:
          geolocationError.message = 'La demande de géolocalisation a expiré.'
          break
        case action.error.UNKNOWN_ERROR:
          geolocationError.message = 'Une erreur inconnue est survenue.'
          break
        default:
          geolocationError.message = 'Une erreur inconnue est survenue.'
          break
      }

      return {
        ...state,
        geolocationError
      }

    case 'SET_LINE_INFORMATION':
      return {
        ...state,
        lineInformation: action.info
      }

    case 'SET_GO_TO_ROUTE_CALC':
      return {
        ...state,
        goToValid: action.valid
      }

    case 'RESET_STATION_INDEX':
      return {
        ...state,
        currentStationDepartureIndex: 0,
        currentStationArrivalIndex: 0
      }

    case 'DECREMENT_STATION_INDEX':
      switch (action.direction) {
        case 'departure':
          return {
            ...state,
            currentStationDepartureIndex: state.currentStationDepartureIndex > 0 ? state.currentStationDepartureIndex - 1 : 0
          }

        case 'arrival':
          return {
            ...state,
            currentStationArrivalIndex: state.currentStationArrivalIndex > 0 ? state.currentStationArrivalIndex - 1 : 0
          }

        default:
          return state
      }

    case 'INCREMENT_STATION_INDEX':
      switch (action.direction) {
        case 'departure':
          return {
            ...state,
            currentStationDepartureIndex: state.currentStationDepartureIndex < 6 ? state.currentStationDepartureIndex + 1 : 6
          }

        case 'arrival':
          return {
            ...state,
            currentStationArrivalIndex: state.currentStationArrivalIndex < 6 ? state.currentStationArrivalIndex + 1 : 6
          }

        default:
          return state
      }

    case 'TOO_MANY_FAVORITES':
      return {
        ...state,
        tooManyFavorites: action.favorite_type
      }

    case 'SET_ROUTE_CALC_DATES_PANEL':
      return {
        ...state,
        datesPanel: !action.datesPanel,
        modesPanel: false
      }

    case 'SET_ROUTE_CALC_MODES_PANEL':
      return {
        ...state,
        datesPanel: false,
        modesPanel: !action.modesPanel
      }

    default:
      return state
  }
}
