import axios from 'axios'
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { actionSetNetwotkSelectedLine } from '../actions/network'
import { Line } from '../components/map/Line'
import { Stop } from '../components/map/Stop'
import { useRouter } from './useRouter'

export const useNetworkLineSelected = (draw = false) => {
  const router = useRouter()
  const { lines, hash } = useSelector(state => state.app)
  const [selected, setSelected] = useState(null)
  const { query } = router
  const dispatch = useDispatch()

  /**
   * Router effect
   */
  useEffect(() => {
    dispatch(actionSetNetwotkSelectedLine(null))

    /**
     * Draw a given line on the map
     * ! TODO : Refactor future & current display
     * ! Move the for loops inside Line & Stop components
     * @param {String} line Line code
     */
    const drawLine = async (line, from) => {
      const polylines = []
      const polylinesOffset = []
      const stops = []
      const stopsOffset = []

      const draw = async (line, from) => {
          // Only future on modified lines and new lines (type = 1 or type = 4)
        if (line.type === 1 || line.type === 2) {
          try {
            const future = await axios.get(`/api/file?folder=routes/future/lines&ext=geojson&name=${line.code}~${hash}`)
            const futureStops = await axios.get(`/api/file?folder=routes/future/stops&ext=geojson&name=${line.code}~${hash}`)

            // Try first if we have data in the loaded future geojson
            if (future.data && future.data.features) {
              polylines.push(<Line key={'line' + (line && from ? '-base' : '')} data={future.data} centerOnDraw style={{
                color: "#" + line.color,
                weight: 6,
                interactive: false,
              }} />)
            }

            if (futureStops.data && futureStops.data.features) {
              for (const [key, feature] of Object.entries(futureStops.data.features)) {
                stops.push(<Stop key={'stop-' + key + (line && from ? '-base' : '')} data={feature} line={line} />)
              }
            }
          } catch (e) {
            console.warn("no future ?")
          }
        }

        // No current on new lines (type = 4)
        if (line.type !== 1) {
          try {
            const current = await axios.get(`/api/file?folder=routes/current/lines&ext=geojson&name=${line.code}~${hash}`)
            const currentStops = await axios.get(`/api/file?folder=routes/current/stops&ext=geojson&name=${line.code}~${hash}`)
            const noFuture = polylines.length === 0

            if (current.data && current.data.features) {
              polylinesOffset.push(<Line key={'line-offset' + (line && from ? '-base' : '')} data={current.data} style={{
                color: noFuture && line.type !== 3 ? "#" + line.color : "#888",
                weight: noFuture && line.type !== 3 ? 6 : 4,
                interactive: false
              }} centerOnDraw={noFuture} />)
            }

            if (currentStops.data && currentStops.data.features) {
              for (const [key, feature] of Object.entries(currentStops.data.features)) {
                stopsOffset.push(<Stop key={'stop-offset-' + key + (line && from ? '-base' : '')} data={feature} line={line} current noFuture={noFuture} />)
              }
            }
          } catch (e) {
            console.warn("no geojson ?")
          }
        }
      }
      
      // If a from param exist, draw the old line too
      if (from) {
        const lineFrom = lines.find(l => String(l.id) === from)
        await draw(lineFrom)
      }

      await draw(line, from)

      // Dispatch the created polyline to display them on map
      dispatch(actionSetNetwotkSelectedLine([polylinesOffset, polylines, stopsOffset, stops]))
    }

    // Location switch
    if (query.line) {
      let line = lines.find(l => String(l.id) === query.line)

      // If line is replaced ...
      if (line.replaced_by) {
        line = lines.find(l => String(l.id) === String(line.replaced_by))
      }

      // Draw the line on map
      draw && drawLine(line, query.from)

      // Retrieve the corresponding line a set it as active
      setSelected(line)
    } else {
      setSelected(null)
      dispatch(actionSetNetwotkSelectedLine(null))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  return selected
}