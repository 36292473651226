import React, { Component } from 'react'
import {
  Map,
  TileLayer,
  Pane,
  ZoomControl,
  ScaleControl, 
  GeoJSON
} from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import { detect } from 'detect-browser'
import { appStore } from '../store'
import { actionSetMap } from '../actions/app'
import { actionSetCluster, actionHandleLegendState } from '../actions/map'
import { envVarToBool, getURLSearchParams, isJDApp, isVariant, isNotPlacesTabAround, isThematics } from '../services/tools'
import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import { renderMapPlaces } from '../services/map'
import history from '../history'
import L from 'leaflet'
import Control from 'react-leaflet-control'
import Tippy from '@tippy.js/react';

const { REACT_APP_TYPE, REACT_APP_HEADER, REACT_APP_START_POINT, REACT_APP_BASE_MAX_BOUNDS, REACT_APP_BASE_TILES_URL, REACT_APP_ZOOM_MIN, REACT_APP_ZOOM_MAX, REACT_APP_ZOOM, REACT_APP_LEGEND } = process.env
const browser = detect()

class Leaflet extends Component {
  mapReference = React.createRef()

  state = {
    bounds: null,
    boundsOptions: null,
    center: JSON.parse(REACT_APP_START_POINT).desktop,
    circle: null,
    clusters: null,
    events: {},
    infoboxs: [],
    infoboxsTerminus: [],
    markers: [],
    markersMap: [],
    markersPlaces: [],
    markersRER: [],
    pin: null,
    polygons: [],
    polylines: [],
    polylineDecorators: [],
    terminus: false,
    zoom: JSON.parse(REACT_APP_ZOOM).mobile
  }

  componentWillMount () {
    const { startOnMobile } = this.props
    startOnMobile
      ? this.setState({
        center: JSON.parse(REACT_APP_START_POINT).mobile,
        zoom: JSON.parse(REACT_APP_ZOOM).mobile
      }, () => {
        appStore.dispatch(actionSetMap(this))
      })
      : appStore.dispatch(actionSetMap(this))
  }

  componentDidMount () {
    // Custom the default attribution to add target blank
    this.mapReference.current.leafletElement.attributionControl.setPrefix('<a href="https://leafletjs.com" target="_blank" rel="noopener">Leaflet</a>')
  }

  render () {
    const { events, bounds, boundsOptions, center, polygons, polylines, polylineDecorators, circle, pin, markers, markersMap, markersPlaces, markersRER } = this.state
    const { territoryOutline, entranceMapMarkers, heavyLines, reduxMarkers, selectedLine, transportPlaces, schoolsJD, mapPlaces, places, isMobile, isLegendOpen, variant, bikePaths, bikes } = this.props
    
    const pathname = history.location.pathname
    const search = history.location.search
    const params = getURLSearchParams(history.location)

    // TODO zoom duplicate but in state it's not updated
    let zoomTmp = null
    if (this.mapReference.current) {
      zoomTmp = this.mapReference.current.leafletElement.getZoom()
    }

    let entranceItems = []
    if (entranceMapMarkers) {
      entranceItems = entranceMapMarkers.filter(marker => {
        if (
          !marker.props.zoom ||
          (marker.props.zoom.min && !marker.props.zoom.max && marker.props.zoom.min <= zoomTmp) ||
          (!marker.props.zoom.min && marker.props.zoom.max && marker.props.zoom.max >= zoomTmp) ||
          (marker.props.zoom.min <= zoomTmp && marker.props.zoom.max >= zoomTmp)
        ) {
          return marker
        }

        return false
      })
    }

    // Detect Safari or Edge 17 browsers to fallback tiles on PNG
    const ext = (browser.name === 'safari' || browser.os === 'iOS' || (browser.name === 'edge' && browser.version.startsWith('17.'))) ? 'png' : 'webp'

    return <Map ref={this.mapReference} className={'mapContainer' + (envVarToBool(REACT_APP_HEADER) ? ' with-header' : '')} bounds={bounds} maxBounds={JSON.parse(REACT_APP_BASE_MAX_BOUNDS)[isMobile ? 'mobile' : 'desktop']}
      boundsOptions={boundsOptions} zoomControl={false}
      center={center} minZoom={+REACT_APP_ZOOM_MIN} maxZoom={+REACT_APP_ZOOM_MAX} zoom={+JSON.parse(REACT_APP_ZOOM)[isMobile ? 'mobile' : 'desktop']} {...events}>
      <TileLayer
        attribution='&amp;copy <a href="https://latitude-cartagene.com" target="_blank" rel="noopener">Latitude-Cartagène</a> | &amp;copy les contributeurs d’<a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener">OpenStreetMap</a>'
        errorTileUrl={'/assets/images/blank.png'} // Grey tile if 404
        url={
            REACT_APP_BASE_TILES_URL === ""
              ? "https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png"
              : REACT_APP_BASE_TILES_URL + (isVariant() ? isVariant() : '')  + '/' + ext + '/{z}/{x}/{y}.' + ext
            }
      />
      {!isMobile && <ScaleControl position={'bottomright'} imperial={false} />}
      {!isMobile && <ZoomControl position={'bottomright'} />}

      {envVarToBool(REACT_APP_LEGEND) && 
        <Control 
          className={'leaflet-control-legend'}
          position={isMobile ? 'topright' : 'bottomright' }
          updateWhenIdle={true}
        >
          <Tippy theme={'latitude'} touch={['hold', 500]} placement={'left'} boundary="window" content={"Légende"}>
            <img src='/assets/images/legend-item.svg' height={30} width={30} alt='legend' onClick={() => appStore.dispatch(actionHandleLegendState(isLegendOpen))}/>
          </Tippy>
        </Control>
      }

      {territoryOutline && <GeoJSON data={territoryOutline} style={{
        color: '#58585a',
        opacity: REACT_APP_TYPE === 'sncf-ter' ? 0 : 0.8,
        fillOpacity: 0,
        weight: 1.5,
        interactive: false
      }} />}
      {!isJDApp(REACT_APP_TYPE, variant) && !pathname.includes('route-calculation') && !(search.includes('current')) && entranceItems}

      {!isJDApp(REACT_APP_TYPE, variant) && !pathname.includes('route-calculation') && !(search.includes('current')) && heavyLines}

      {bikePaths} {bikes}

      {polygons} {polylines} {polylineDecorators}
      {selectedLine}
      {circle} {pin}
      {markersMap}
      {markers} {markersPlaces} {markersRER}

      {isJDApp(REACT_APP_TYPE, variant) && schoolsJD}

      {transportPlaces && <Pane name='cluster-pane' style={isThematics() ? { zIndex: 600 } : { zIndex: 400 }}>
        <MarkerClusterGroup key='transport-places' ref={ref => ref && appStore.dispatch(actionSetCluster(ref.leafletElement))} removeOutsideVisibleBounds showCoverageOnHover={false}
          clusterPane='cluster-pane'
          iconCreateFunction={(cluster) => {
            return L.divIcon({ html: cluster.getChildCount(), className: 'cluster' })
          }}>
          {transportPlaces.filter(place => place.props.place.clusterized)}
        </MarkerClusterGroup>
      </Pane>}

      {transportPlaces && transportPlaces.filter(place => !place.props.place.clusterized)} {places}

      {(!transportPlaces || transportPlaces.length === 0) && !search.includes('current=') && !isNotPlacesTabAround(places) && !isJDApp(REACT_APP_TYPE, variant) && renderMapPlaces(this.mapReference, mapPlaces)}

      {isJDApp(REACT_APP_TYPE, variant) ? (params.current || params.line) ? reduxMarkers : (!zoomTmp || zoomTmp > 14) && reduxMarkers : reduxMarkers}
    </Map>
  }
}
// iconCreateFunction={(cluster) => { return L.divIcon({ html: cluster.getChildCount(), className: 'mycluster', iconSize: L.point(40, 40) }) }}

export default Leaflet;